const AnalyticsEvents = {
    NEW_USERNAME: 'new_user_name',
    BEGIN_ACTIVITY: 'begin_activity',
    PAUSE_ACTIVITY: 'pause_activity',
    RESUME_ACTIVITY: 'resume_activity',
    END_ACTIVITY: 'end_activity',
    RECV_INVITATION: 'receive_invitation',
    CREATE_GROUP: 'create_group',
    VISIT_GROUP: 'visit_group',
    VISIT_PERSONAL_HISTORY: 'visit_personal_history',
    GOAL_COMPLETED: 'goal_completed',
    GAME_STATS: 'game_stats',
};

export default AnalyticsEvents;
