import firebase from 'firebase/app';
import AnalyticsEvents from './AnalyticsEvents';
import AnalyticsSecrets from './AnalyticsSecrets';
import { getSetting } from '../utils/Settings';

const enabled = !!getSetting('dev.enableAnalytics');

var mixpanel = (() => {
    var instance = null;

    var init = () => {
        var mixpanel = require('mixpanel-browser');
        mixpanel.init(AnalyticsSecrets.PROD.MIXPANEL_TOKEN);
        instance = mixpanel;
        console.log(`[Mixpanel] initialized`);
    };

    return {
        shared: () => {
            if (instance == null) {
                init();
            }
            return instance;
        },
    };
})();

function updateUserIdentity(user) {
    const superProperties = {
        user_id: user.id,
        name: user.name,
    };
    console.log(`[updateUserIdentity] user`, user);
    console.log(`[updateUserIdentity] superProperties`, superProperties);
    // set super properties
    mixpanel.shared().register(superProperties);
}

function setUserProperties(properties) {
    // set user profile properties
    mixpanel.shared().people.set(properties);
}

function trackEvent(eventName, properties = {}) {
    console.log(`[TrackEvent] ${eventName}`, properties);
    // mixpanel tracking
    mixpanel.shared().track(eventName, properties);
    // firebase tracking
    firebase.analytics().logEvent(eventName, properties);
}

function trackEventToGroups(groupIds = [], eventName, properties = {}) {
    mixpanel.shared().track_with_groups(eventName, properties, { group_id: groupIds });
}

function addUserToGroup(groupId) {
    mixpanel.shared().add_group('group_id', groupId);
}

function setGroupProperties(groupId, properties = {}) {
    const group = mixpanel.shared().get_group('group_id', groupId);
    group.set(properties);
}

function setIsDev(isDev) {
    const superProperties = {
        is_dev: isDev,
    };
    console.log(`[setIsDev] is_dev`, isDev);
    // set super properties
    mixpanel.shared().register(superProperties);
}

// side effect: mixpanel library will be loaded
const distinctId = mixpanel.shared().get_distinct_id();

export default function analyticsManager() {
    // Ref: https://help.mixpanel.com/hc/en-us/articles/115004497803-Identity-Management-Best-Practices
    return {
        trackNewUserName: (user) => {
            if (enabled) {
                console.log(`[trackNewUserName] user`, user);
                updateUserIdentity(user);
                setUserProperties({ user_id: user.id, name: user.name });
                trackEvent(AnalyticsEvents.NEW_USERNAME, { username: user.name });
            }
        },

        /**
         * Track user login.
         * This function is called when (1) the App is loaded, or (2) the UID is changed.
         * This also binds the firebase UID to mixpanel distinct ID.
         * @param {String} [eventName]
         * @param {properties}
         * @returns
         */
        trackLoginUser: (user) => {
            if (enabled) {
                console.log(`[trackLoginUser] user`, user);
                mixpanel.shared().alias(user.id, distinctId);
                mixpanel.shared().identify(distinctId);
                updateUserIdentity(user);
                setUserProperties({ user_id: user.id, name: user.name });
            }
        },

        /**
         * Send events to both firebase analytics and mixpanel
         * @param {String} [eventName]
         * @param {properties}
         * @returns
         */
        trackEvent: (eventName, properties = {}) => {
            if (enabled) {
                trackEvent(eventName, properties);
            }
        },

        /**
         * Track start new activity
         * @param {properties} props
         * @returns
         */
        trackBeginActivity: (props) => {
            if (enabled) {
                trackEvent(AnalyticsEvents.BEGIN_ACTIVITY, props);
                if (props.group_id) {
                    trackEventToGroups([props.group_id], AnalyticsEvents.BEGIN_ACTIVITY, props);
                }
            }
        },

        /**
         * Track end activity
         * @param {properties} props
         * @returns
         */
        trackEndActivity: (props) => {
            if (enabled) {
                trackEvent(AnalyticsEvents.END_ACTIVITY, props);
                if (props.group_id) {
                    trackEventToGroups([props.group_id], AnalyticsEvents.END_ACTIVITY, props);
                }
            }
        },

        /**
         * Track pause activity
         * @param {properties} props
         * @returns
         */
        trackPauseActivity: (props) => {
            if (enabled) {
                trackEvent(AnalyticsEvents.PAUSE_ACTIVITY, props);
                if (props.group_id) {
                    trackEventToGroups([props.group_id], AnalyticsEvents.PAUSE_ACTIVITY, props);
                }
            }
        },

        /**
         * Track resume activity
         * @param {properties} props
         * @returns
         */
        trackResumeActivity: (props) => {
            if (enabled) {
                trackEvent(AnalyticsEvents.RESUME_ACTIVITY, props);
                if (props.group_id) {
                    trackEventToGroups([props.group_id], AnalyticsEvents.RESUME_ACTIVITY, props);
                }
            }
        },

        /**
         * Track create group
         * @param {properties} props
         * @returns
         */
        trackCreateGroup: (props) => {
            if (enabled) {
                trackEvent(AnalyticsEvents.CREATE_GROUP, props);
            }
        },

        /**
         * Track visit group
         * @param {string} groupId
         * @param {string} groupName
         * @param {boolean} isOwner
         * @returns
         */
        trackVisitGroup: (groupId, groupName, isOwner) => {
            if (enabled) {
                trackEvent(AnalyticsEvents.VISIT_GROUP, {
                    group_id: groupId,
                    group_name: groupName,
                    isOwner: isOwner,
                });
            }
        },

        /**
         * Track visit history
         * @returns
         */
        trackVisitHistory: () => {
            if (enabled) {
                trackEvent(AnalyticsEvents.VISIT_PERSONAL_HISTORY);
            }
        },

        /**
         * Update user all time stats
         * @param {properties} stats
         * @return
         */
        updateUserAllTimeStats: (stats) => {
            if (enabled) {
                setUserProperties(stats);
            }
        },

        // Group analytics
        trackJoinedGroup: (groupId) => {
            if (enabled) {
                addUserToGroup(groupId);
            }
        },

        updateGroupProperties: (groupId, props = {}) => {
            if (enabled) {
                setGroupProperties(groupId, props);
            }
        },

        // Goal analytics
        trackGoalCompleted: (props) => {
            if (enabled) {
                trackEvent(AnalyticsEvents.GOAL_COMPLETED, props);
                if (props.group_id) {
                    trackEventToGroups([props.group_id], AnalyticsEvents.GOAL_COMPLETED, props);
                }
            }
        },

        // settings
        setIsDev: (isDev = false) => {
            if (enabled) {
                setIsDev(isDev);
            }
        },

        trackGameStats: (props) => {
            if (enabled) {
                trackEvent(AnalyticsEvents.GAME_STATS, props);
            }
        },
    };
}
