import { PlayerPose } from '../pose/PlayerPose';
import {
    kLeftShoulderNodeIndex,
    kRightShoulderNodeIndex,
    kNoseNodeIndex,
    kLeftElbowNodeIndex,
    kRightElbowNodeIndex,
    Pose,
    kPoseNodeDetected,
} from '../detectors/Pose';
import { pickCenterPose } from '../pose/PoseUtils';
import { ExponentialAverage } from '../utils/Aggregate';

const nodesForPoseQualification = [
    kNoseNodeIndex,
    kLeftElbowNodeIndex,
    kRightElbowNodeIndex,
    kLeftShoulderNodeIndex,
    kRightShoulderNodeIndex,
];

const nodesForActionQualification = [
    kLeftShoulderNodeIndex,
    kRightShoulderNodeIndex,
    kLeftElbowNodeIndex,
    kRightElbowNodeIndex,
];

/**
 * Returns true if the given pose is suitable for running detection.
 *
 * @export
 * @param {Pose} pose
 * @returns
 */
export function isPoseQualifiedForRunningDetection(pose) {
    return poseQualificationCheck(pose, nodesForPoseQualification);
}

function poseQualificationCheck(pose, targetNodes) {
    if (pose === null || pose === undefined) {
        return false;
    }
    for (const nodeIndex of targetNodes) {
        if (pose.nodes[nodeIndex].validLevel !== kPoseNodeDetected) {
            return false;
        }
    }
    return true;
}

const JumpingJackState = {
    ARMS_DOWN: 'ARMS_DOWN',
    ARMS_UP: 'ARMS_UP',
    UNKNOWN: 'UNKNOWN',
};

class PoseNodeValue {
    constructor() {
        this._movingAverage = new ExponentialAverage(20.0);
        this._currentValue = undefined;
    }
    append(val) {
        this._movingAverage.append(val);
        this._currentValue = val;
    }
    movingAvg() {
        return this._movingAverage.value();
    }
    currentValue() {
        return this._currentValue;
    }
}
export class JumpingJackDetector {
    constructor() {
        /** @type {number} */
        this._frameHeight = 0;

        /** @type {number} */
        this._frameWidth = 0;

        /** @type {number} */
        this._curFrameTime = 0;

        /** @type {number} */
        this._prevFrameTime = 0;

        // Pose Detection

        /** @type {PlayerPose} */
        this._lastPlayerPose = new PlayerPose();

        /** @type {boolean} */
        this._isLastPoseAnalyzed = false;

        // State

        this._initialState = JumpingJackState.UNKNOWN;
        this._state = JumpingJackState.UNKNOWN;
        this._poseStore = undefined;

        // Stats

        this._armUpCount = 0;
        this._poseCount = 0;
    }

    /**
     * Sets some basic configuration. Need to be called before tick.
     *
     * @param {number} frameWidth
     * @param {number} frameHeight
     * @memberof JumpingJackDetector
     */
    config(frameWidth, frameHeight) {
        this._frameWidth = frameWidth;
        this._frameHeight = frameHeight;
    }

    elbowIsUp({ isLeft }) {
        const elbowStore = this._poseStore[isLeft ? kLeftElbowNodeIndex : kRightElbowNodeIndex];
        const shoulderStore = this._poseStore[isLeft ? kLeftShoulderNodeIndex : kRightShoulderNodeIndex];

        return (
            elbowStore.movingAvg() > shoulderStore.movingAvg() || elbowStore.currentValue() > shoulderStore.movingAvg()
        );
    }

    initPoseStore() {
        this._poseStore = [];
        for (const nodeIndex of nodesForActionQualification) {
            this._poseStore[nodeIndex] = new PoseNodeValue();
        }
    }

    updatePoseStore(pose) {
        for (const nodeIndex of nodesForActionQualification) {
            this._poseStore[nodeIndex].append(pose.nodes[nodeIndex].y);
        }
    }

    /**
     * Compute values based on current time and pose data.
     *
     * @param {number} frameTime
     * @memberof JumpingJackDetector
     */
    tick(frameTime) {
        if (this._isLastPoseAnalyzed) {
            return;
        }
        this._isLastPoseAnalyzed = true;

        const pose = this._lastPlayerPose.pose;
        if (!poseQualificationCheck(pose, nodesForActionQualification)) {
            // when the pose is not valid, we remove all stored data
            this._poseStore = undefined;
            return;
        }

        // Initialize poseStore if needed
        if (this._poseStore === undefined) {
            this.initPoseStore();
        }
        this.updatePoseStore(pose);

        const newState = (() => {
            const leftElbowIsUp = this.elbowIsUp({ pose, isLeft: true });
            const rightElbowIsUp = this.elbowIsUp({ pose, isLeft: false });

            if (leftElbowIsUp && rightElbowIsUp) {
                return JumpingJackState.ARMS_UP;
            } else if (!leftElbowIsUp && !rightElbowIsUp) {
                return JumpingJackState.ARMS_DOWN;
            } else {
                return JumpingJackState.UNKNOWN;
            }
        })();

        if (newState !== JumpingJackState.UNKNOWN) {
            if (this._initialState === JumpingJackState.UNKNOWN) {
                this._initialState = newState;
            }

            const oldState = this._state;
            this._state = newState;

            if (oldState !== newState) {
                if (newState === JumpingJackState.UNKNOWN || oldState === JumpingJackState.UNKNOWN) {
                    return;
                } else if (newState !== this._initialState) {
                    this._armUpCount++;
                }
            }
        }
        return;
    }

    /**
     * Accepts pose detection input.
     *
     * @param {Pose[]} poses
     * @param {number} frameTime
     * @memberof JumpingJackDetector
     */
    handleDetection(poses, frameTime) {
        const pose = this.pickPose(poses);

        this._lastPlayerPose = new PlayerPose(pose, frameTime);
        this._isLastPoseAnalyzed = false;
    }

    /**
     * Picks a best pose among all detected poses.
     *
     * @param {Pose[]} poses
     * @returns
     * @memberof JumpingJackDetector
     */
    pickPose(poses) {
        return pickCenterPose(poses, this._frameWidth);
    }

    totalJumpCount() {
        return this._armUpCount;
    }
}
