import { useEffect, useState } from 'react';
import Button from './Button';
import './Segmented.scss';

export default function Segmented({ className, style, values, onChange, selectedIndex }) {
    const [currentSelectedIndex, setCurrentSelectedIndex] = useState(selectedIndex ?? 0);

    useEffect(() => {
        if (onChange) {
            onChange(currentSelectedIndex);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentSelectedIndex]);

    const children = [];
    for (let i = 0; i < values.length; ++i) {
        if (i > 0) {
            children.push(<div key={'Spacer-' + i} className="Spacer" />);
        }
        const className = ['Segment'];
        if (currentSelectedIndex === i) {
            className.push('Selected');
        }
        children.push(
            <Button
                key={'button-' + i}
                className={className.join(' ')}
                onClick={(e) => {
                    setCurrentSelectedIndex(i);
                }}
            >
                {values[i]}
            </Button>
        );
    }

    return (
        <div className={className} style={{ style }}>
            <div className="Segmented">{children}</div>
        </div>
    );
}
