import classNames from 'classnames';
import { useHistory } from 'react-router-dom';
import MinAspectArea from '../MinAspectArea';
import PageInfoBox from '../PageInfoBox';
import { StartButton } from '../OpenDialogButton';
import submit from '../../utils/SubmitInputs';
import { SidebarSelection } from '../SideBar';
import { GroupSelection } from '../GroupSelection';
import { useMemo } from 'react';
import { PageControllerAction } from '../PageController';
import './Home.scss';
import HomeFooter from '../HomeFooter';
import { DialogBoxController, DIALOG_REQUIREMENTS } from '../DialogBoxController';
import HomeActivitySelector from '../HomeActivitySelector';
import { ActivityId } from '../../ActivityId';

export default function Home({ appUser, userUpdater, pageController, pageControllerState, joinedTeams, managedTeams }) {
    const history = useHistory();
    const requirements = []
        .concat(pageControllerState?.selectedTeamToStart ? [] : [DIALOG_REQUIREMENTS.GROUP_NAME])
        .concat(appUser?.name ? [] : [DIALOG_REQUIREMENTS.USER_NAME])
        .concat([DIALOG_REQUIREMENTS.SELECT_GOAL_MODE])
        .concat([DIALOG_REQUIREMENTS.SELECT_GOAL_TYPE]);
    const onDialogComplete = async (result) => {
        await submit({
            pageController,
            appUser,
            userUpdater,
            inputUserName: result[DIALOG_REQUIREMENTS.USER_NAME],
            inputGroupName: result[DIALOG_REQUIREMENTS.GROUP_NAME],
            startWithCreateGroup: true,
        });

        if (result[DIALOG_REQUIREMENTS.SELECT_GOAL_TYPE]) {
            const goal = result[DIALOG_REQUIREMENTS.SELECT_GOAL_TYPE];
            pageController(PageControllerAction.SET_PRESET_GOAL(goal));
        }
        history.push(`/setup/${pageControllerState.selectedActivityId}`);
    };

    const teamsForStartRun = useMemo(() => {
        return [...(joinedTeams || []), ...(managedTeams || [])];
    }, [joinedTeams, managedTeams]);

    return (
        <div className="MainContainer">
            <div className="ContentContainer">
                <MinAspectArea defaultBg={true} className={pageControllerState.selectedActivityId}>
                    <div style={{ display: 'grid' }}>
                        <div
                            className={classNames('group-selection-layer', {
                                hidden: pageControllerState.sidebarSelection === SidebarSelection.HISTORY,
                            })}
                        >
                            <GroupSelection
                                pageController={pageController}
                                pageControllerState={pageControllerState}
                                teamsForStartRun={teamsForStartRun}
                            />
                        </div>

                        <div>
                            <PageInfoBox>
                                <HomeActivitySelector
                                    pageController={pageController}
                                    pageControllerState={pageControllerState}
                                />
                                <div
                                    style={{
                                        marginTop: '93px',
                                        height: '78px',
                                        fontSize: '36px',
                                        lineHeight: '1.08',
                                        textShadow: '0 2px 4px rgba(0, 0, 0, 0.5)',
                                        whiteSpace: 'pre-wrap',
                                        fontFamily: 'Nunito',
                                        fontWeight: 'normal',
                                        fontStretch: 'normal',
                                        fontStyle: 'normal',
                                        letterSpacing: 'normal',
                                        color: 'white',
                                    }}
                                >
                                    {getActivityTitle(pageControllerState.selectedActivityId)}
                                </div>
                                <div
                                    style={{
                                        marginTop: '12px',
                                        height: '100px',
                                        fontSize: '18px',
                                        letterSpacing: '0.1px',
                                        lineHeight: '1.39',
                                        textShadow: '0 2px 4px rgba(0, 0, 0, 0.5)',
                                        whiteSpace: 'pre-wrap',
                                    }}
                                >
                                    {getActivityDescription(pageControllerState.selectedActivityId)}
                                </div>
                                <DialogBoxController
                                    DialogButton={StartButton}
                                    requirements={requirements}
                                    onComplete={onDialogComplete}
                                    style={{
                                        marginTop: '24px',
                                        marginLeft: '-3px',
                                        width: '163px',
                                        cursor: 'pointer',
                                    }}
                                    activityId={pageControllerState.selectedActivityId}
                                />
                                <div
                                    style={{
                                        marginTop: '22px',
                                        height: '34px',
                                        opacity: 0.5,
                                        fontSize: '12px',
                                        lineHeight: 1.42,
                                        letterSpacing: '0.07px',
                                        textShadow: '0 2px 4px rgba(0, 0, 0, 0.5)',
                                        whiteSpace: 'pre-wrap',
                                    }}
                                >
                                    Getting started just requires a{'\n'}device with a camera.
                                </div>
                            </PageInfoBox>
                        </div>
                    </div>
                </MinAspectArea>
            </div>
            <HomeFooter />
        </div>
    );
}

function getActivityTitle(activityId) {
    switch (activityId) {
        case ActivityId.TreadMill:
            return 'Jog with the\nwhole class';
        case ActivityId.JumpRope:
            return 'Jump Rope';
        case ActivityId.JumpingJack:
            return 'Jumping Jacks';
        default:
            return '';
    }
}

function getActivityDescription(activityId) {
    switch (activityId) {
        case ActivityId.TreadMill:
            return 'Create virtual competitions &\nreview performances easily.';
        case ActivityId.JumpRope:
            return 'Jump rope against classmates for\nthe top spot on the leaderboard.';
        case ActivityId.JumpingJack:
            return 'Jumping Jacks against classmates\nfor the top spot on the leaderboard.';
        default:
            return '';
    }
}
