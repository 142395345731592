import './LeaderboardHeader.scss';
import GroupTableFilter from './GroupTableFilter';
import { ActivityId } from '../ActivityId';

export default function LeaderboardHeader({ filterValue, setFilterValue, group, activityId }) {
    let unit;
    switch (activityId) {
        case ActivityId.JumpRope:
            unit = 'JUMPS';
            break;

        default:
            unit = 'STEPS';
            break;
    }
    return (
        <div className="LeaderboardHeader">
            <div className="row leaderboard-filter-row">
                <div className="leaderboard-title">
                    <span className="content">Leaderboard</span>
                </div>
                <GroupTableFilter
                    groupTableFilterState={filterValue}
                    filterChangeHandler={(value) => {
                        setFilterValue(value);
                    }}
                    theme={'theme-leaderboard'}
                />
            </div>

            <div className="leaderboard-header row">
                <div className="rank-label">RANK</div>
                <div className="kcal-label">{unit}</div>
            </div>
        </div>
    );
}
