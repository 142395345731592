import './GroupTableSortingHeader.scss';
import { GroupHeaderType, GroupSortingOrder, overallTab } from './GroupTable';
import { ActivityId } from '../ActivityId';

export class SortingHeaderState {
    constructor(type, status) {
        this.type = type; // GroupHeaderType
        this.status = status; // GroupSortingOrder
    }
}

function headerClassName(type) {
    switch (type) {
        case GroupHeaderType.NAME:
        case GroupHeaderType.RANK:
        case GroupHeaderType.TIME:
        case GroupHeaderType.STEP:
        case GroupHeaderType.JUMP:
        case GroupHeaderType.COMBO:
            return 'oneRow';
        case GroupHeaderType.CALORIES:
        case GroupHeaderType.DISTANCE:
        case GroupHeaderType.OUTPUT:
        case GroupHeaderType.PACE:
        case GroupHeaderType.SPEED:
        case GroupHeaderType.JPM:
        case GroupHeaderType.JOG_DURATION:
        case GroupHeaderType.JOG_SESSION_COUNT:
        case GroupHeaderType.JUMP_ROPE_DURATION:
        case GroupHeaderType.JUMP_ROPE_SESSION_COUNT:
        case GroupHeaderType.JUMPING_JACK_DURATION:
        case GroupHeaderType.JUMPING_JACK_SESSION_COUNT:
            return 'twoRow';
        default:
            return '';
    }
}

function HeaderContent({ type }) {
    const className = `header-content ${headerClassName(type)}`;
    switch (type) {
        case GroupHeaderType.RANK:
            return <span className={className}>RANK</span>;
        case GroupHeaderType.NAME:
            return <span className={className}>NAME</span>;
        case GroupHeaderType.CALORIES:
            return (
                <span className={className}>
                    CALORIES
                    <br />
                    (KCAL)
                </span>
            );

        case GroupHeaderType.DISTANCE:
            return (
                <span className={className}>
                    DISTANCE
                    <br />
                    (MI)
                </span>
            );

        case GroupHeaderType.TIME:
            return <span className={className}>TIME</span>;

        case GroupHeaderType.STEP:
            return <span className={className}>STEPS</span>;

        case GroupHeaderType.OUTPUT:
            return (
                <span className={className}>
                    OUTPUT
                    <br />
                    (WATTS)
                </span>
            );

        case GroupHeaderType.PACE:
            return (
                <span className={className}>
                    PACE
                    <br />
                    (MIN/MI)
                </span>
            );

        case GroupHeaderType.SPEED:
            return (
                <span className={className}>
                    SPEED
                    <br />
                    (MPH)
                </span>
            );

        case GroupHeaderType.COMBO:
            return <span className={className}>COMBO</span>;

        case GroupHeaderType.JUMP:
            return <span className={className}>JUMPS</span>;

        case GroupHeaderType.JPM:
            return (
                <span className={className}>
                    JPM
                    <br />
                    (JUMP/MIN)
                </span>
            );

        case GroupHeaderType.JOG_DURATION:
            return (
                <span className={className}>
                    JOG IN PLACE
                    <br />
                    (TOTAL TIME)
                </span>
            );
        case GroupHeaderType.JOG_SESSION_COUNT:
            return (
                <span className={className}>
                    JOG IN PLACE
                    <br />
                    (TOTAL MATCH)
                </span>
            );

        case GroupHeaderType.JUMP_ROPE_DURATION:
            return (
                <span className={className}>
                    JUMP ROPE
                    <br />
                    (TOTAL TIME)
                </span>
            );

        case GroupHeaderType.JUMP_ROPE_SESSION_COUNT:
            return (
                <span className={className}>
                    JUMP ROPE
                    <br />
                    (TOTAL MATCH)
                </span>
            );

        case GroupHeaderType.JUMPING_JACK_DURATION:
            return (
                <span className={className}>
                    JUMPING JACK
                    <br />
                    (TOTAL TIME)
                </span>
            );

        case GroupHeaderType.JUMPING_JACK_SESSION_COUNT:
            return (
                <span className={className}>
                    JUMPING JACK
                    <br />
                    (TOTAL MATCH)
                </span>
            );

        default:
            return <span></span>;
    }
}

function HeaderCell({ type, groupTableSorting, setGroupTableSorting }) {
    const onClick = () => {
        var state = {};
        if (groupTableSorting.type === type) {
            if (groupTableSorting.status === GroupSortingOrder.ASCENDING) {
                state = new SortingHeaderState(type, GroupSortingOrder.DESCENDING);
            } else {
                state = new SortingHeaderState(type, GroupSortingOrder.ASCENDING);
            }
        } else {
            state = new SortingHeaderState(type, GroupSortingOrder.ASCENDING);
        }
        setGroupTableSorting(state);
    };

    const className = `header-cell ${groupTableSorting?.type === type ? 'selected' : 'not-selected'}`;
    const key = `header-cell-${type}`;
    const arrowClass = groupTableSorting?.status === GroupSortingOrder.ASCENDING ? 'ascending' : 'descending';
    return (
        <th className={className} key={key} onClick={onClick}>
            <HeaderContent type={type} />
            <span className={`header-icon ${arrowClass}`}></span>
        </th>
    );
}

export default function GroupTableSortingHeader({ groupTableSorting, setGroupTableSorting, selectedTab }) {
    let headers;
    switch (selectedTab) {
        case overallTab:
            headers = [
                GroupHeaderType.NAME,
                GroupHeaderType.TIME,
                GroupHeaderType.CALORIES,
                GroupHeaderType.JOG_DURATION,
                GroupHeaderType.JOG_SESSION_COUNT,
                GroupHeaderType.JUMP_ROPE_DURATION,
                GroupHeaderType.JUMP_ROPE_SESSION_COUNT,
                GroupHeaderType.JUMPING_JACK_DURATION,
                GroupHeaderType.JUMPING_JACK_SESSION_COUNT,
            ];
            break;
        case ActivityId.TreadMill:
            headers = [
                GroupHeaderType.RANK,
                GroupHeaderType.NAME,
                GroupHeaderType.CALORIES,
                GroupHeaderType.TIME,
                GroupHeaderType.STEP,
                GroupHeaderType.DISTANCE,
                GroupHeaderType.OUTPUT,
                GroupHeaderType.PACE,
                GroupHeaderType.SPEED,
            ];
            break;
        case ActivityId.JumpRope:
        case ActivityId.JumpingJack:
            headers = [
                GroupHeaderType.RANK,
                GroupHeaderType.NAME,
                GroupHeaderType.JUMP,
                GroupHeaderType.COMBO,
                GroupHeaderType.TIME,
                GroupHeaderType.CALORIES,
                GroupHeaderType.JPM,
            ];
            break;
        default:
            headers = [];
            break;
    }

    return (
        <thead className="header">
            <tr className="row">
                {headers.map((type) => {
                    return (
                        <HeaderCell
                            key={`header-cell-${type}`}
                            type={type}
                            groupTableSorting={groupTableSorting}
                            setGroupTableSorting={setGroupTableSorting}
                        />
                    );
                })}
            </tr>
        </thead>
    );
}
