import './HomeActivitySelector.scss';
import Segmented from './Segmented';
import { ActivityId, getActivityName } from '../ActivityId';
import { useEffect, useState } from 'react';
import { map, findIndex } from 'lodash';
import { PageControllerAction } from './PageController';

export const availableActivities = [ActivityId.TreadMill, ActivityId.JumpRope, ActivityId.JumpingJack];

export default function HomeActivitySelector({ pageController, pageControllerState }) {
    const [selectedActivityId, setSelectedActivityId] = useState(pageControllerState.selectedActivityId);
    useEffect(() => {
        pageController(PageControllerAction.SELECT_ACTIVITY(selectedActivityId));
    }, [selectedActivityId, pageController]);
    return (
        <Segmented
            className="HomeActivitySelector"
            values={map(availableActivities, (id) => getActivityName({ activityId: id }))}
            onChange={(index) => setSelectedActivityId(availableActivities[index])}
            selectedIndex={findIndex(availableActivities, (id) => id === selectedActivityId)}
        />
    );
}
