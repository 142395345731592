class MovingAverage {
    /**
     * Constructs a moving average object of the given window size.
     * @param {number} windowSize
     * @param {number} defaultSample The default sample, before we have real data.
     */
    constructor(windowSize, defaultSample = 40) {
        this._windowSize = windowSize;
        const samples = (this._samples = []);
        for (let i = 0; i < windowSize; ++i) {
            samples.push(defaultSample);
        }
        this._sumSamples = defaultSample * windowSize;
        this._sampleIndex = 0;
    }

    addSample(sample) {
        this._sumSamples += sample - this._samples[this._sampleIndex];
        this._samples[this._sampleIndex] = sample;
        this._sampleIndex = (this._sampleIndex + 1) % this._windowSize;
    }

    /**
     * Returns the average of the last windowSize samples.
     * @returns {number}
     */
    average() {
        return this._sumSamples / this._windowSize;
    }
}

export default MovingAverage;
