export class Aggregate {
    constructor() {
        this._count = 0;
        this._sum = 0;
        this._max = undefined;
        this._min = undefined;
        this._list = [];
    }

    append(_value) {
        this._count++;
        this._sum += _value;
        this._max = this._max === undefined ? _value : Math.max(this._max, _value);
        this._min = this._min === undefined ? _value : Math.min(this._min, _value);
    }

    min() {
        return this._min;
    }

    max() {
        return this._max;
    }

    avg() {
        return this._sum / this._count;
    }
}

/*
struct ExponentialAverage<T>: SimpleAggregate where T: SummarizableValue {
    private let decay: Double
    private var sum: T = T()
    private var extra: Double = 1
    private(set) var count: Int = 0

    var value: T {
        return extra < 1 ? sum / (1 - extra) : T()
    }

    init(decay: Double) {
        self.decay = decay
    }

    init(averagingPeriod n: Double) {
        decay = 1 - 2 / (n + 1)
    }

    init(halfLife: Double) {
        decay = exp(-M_LN2 / halfLife)
    }

    mutating func append(_ value: T) {
        count += 1
        sum += (1 - decay) * (value - sum)
        extra = decay * extra
    }
}*/

export class ExponentialAverage {
    constructor(averagingPeriod) {
        this._decay = 1 - 2 / (averagingPeriod + 1);
        this._sum = 0;
        this._extra = 1;
        this._count = 0;
    }

    value() {
        return this._extra < 1 ? this._sum / (1.0 - this._extra) : 0;
    }

    append(_value) {
        this._count += 1;
        this._sum += (1 - this._decay) * (_value - this._sum);
        this._extra = this._decay * this._extra;
    }
}
