import classNames from 'classnames';
import './DialogBox.scss';
import { useState } from 'react';

const DialogType = {
    CREATE_GROUP: 'CREATE_GROUP',
    SET_USERNAME: 'SET_USERNAME',
    SELECT_GOAL_MODE: 'SELECT_GOAL_MODE',
    SELECT_GOAL_TYPE: 'SELECT_GOAL_TYPE',
    EDIT_USERNAME: 'EDIT_USERNAME',
};

export const DialogBoxAction = {
    BACKGROUND: 'BACKGROUND',
    BACK: 'BACK',
    NEXT: 'NEXT',
    CLOSE_BUTTON: 'CLOSE_BUTTON',
    FREEFORM_WORKOUT: 'FREEFORM_WORKOUT',
    TARGETED_WORKOUT: 'TARGETED_WORKOUT',
};

class MetaData {
    constructor({
        title,
        backButton,
        nextButton,
        description,
        closeButton,
        hasInput,
        freeFormButton,
        targetGoalButton,
        confirmTargetButton,
        goalType,
    }) {
        this.title = title;
        this.backButton = backButton;
        this.nextButton = nextButton;
        this.description = description;
        this.closeButton = closeButton;
        this.hasInput = hasInput;
        this.freeFormButton = freeFormButton;
        this.targetGoalButton = targetGoalButton;
        this.confirmTargetButton = confirmTargetButton;
        this.goalType = goalType;
    }

    static createMetadata(type) {
        switch (type) {
            case DialogType.CREATE_GROUP:
                return new MetaData({
                    title: 'New Group',
                    nextButton: 'NEXT',
                    description: 'This is the group name your students will see when they join your group.',
                    closeButton: true,
                    hasInput: true,
                });
            case DialogType.SET_USERNAME:
                return new MetaData({
                    title: 'Enter your name',
                    backButton: 'BACK',
                    nextButton: 'CREATE',
                    description: 'Enter your name to save your performance records and spot on the leaderboard.',
                    hasInput: true,
                });
            case DialogType.SELECT_GOAL_MODE:
                return new MetaData({
                    title: 'Select a mode',
                    description: 'Select a workout mode that suit you the best.',
                    closeButton: true,
                    freeFormButton: true,
                    targetGoalButton: true,
                });
            case DialogType.SELECT_GOAL_TYPE:
                return new MetaData({
                    title: 'Set Goal',
                    backButton: 'BACK',
                    confirmTargetButton: 'START NOW',
                });
            case DialogType.EDIT_USERNAME:
                return new MetaData({
                    title: 'Edit Name',
                    backButton: 'CANCEL',
                    nextButton: 'SAVE',
                    description: 'By editing your name, performance records will still remain unchanged.',
                    hasInput: true,
                });

            default:
                return new MetaData();
        }
    }
}
export class DialogBoxResult {
    constructor({ value, action }) {
        this.value = value;
        this.action = action;
    }
}

function DialogBox({
    initialInput,
    backButton,
    nextButton,
    title,
    description,
    onDismiss,
    closeButton,
    hasInput,
    targetGoalButton,
    freeFormButton,
    confirmTargetButton,
}) {
    const [inputText, setInputText] = useState(initialInput || '');
    const onChangeHandler = (event) => {
        setInputText(event.target.value);
    };
    const dismissHandler = ({ dismissAction }) => {
        return (event) => {
            event.stopPropagation();
            dismissAction && onDismiss && onDismiss(new DialogBoxResult({ value: inputText, action: dismissAction }));
        };
    };

    return (
        <div className="dialog-layer">
            <div
                className="dialog-background create-group"
                onClick={dismissHandler({ dismissAction: DialogBoxAction.BACKGROUND })}
            >
                <div className="dialog-content create-group" onClick={dismissHandler({ dismissAction: null })}>
                    {closeButton && (
                        <div
                            className="icon icon-close"
                            onClick={dismissHandler({ dismissAction: DialogBoxAction.CLOSE_BUTTON })}
                        />
                    )}

                    <div className="new-group-dialog">
                        <div className="title">{title}</div>
                        {hasInput && (
                            <div className="new-group-name">
                                <form
                                    onSubmit={(event) => {
                                        event.preventDefault();
                                        inputText.length > 0 &&
                                            onDismiss(
                                                new DialogBoxResult({ value: inputText, action: DialogBoxAction.NEXT })
                                            );
                                    }}
                                >
                                    <input
                                        autoFocus
                                        type="text"
                                        placeholder="Name"
                                        value={inputText || ''}
                                        onChange={onChangeHandler}
                                    ></input>
                                </form>
                            </div>
                        )}

                        {description && <div className="description">{description}</div>}

                        {(backButton || nextButton) && (
                            <div className="row-button-area">
                                {/* back button */}
                                {backButton && (
                                    <div
                                        className="back button"
                                        onClick={() => {
                                            onDismiss &&
                                                onDismiss(
                                                    new DialogBoxResult({
                                                        value: inputText,
                                                        action: DialogBoxAction.BACK,
                                                    })
                                                );
                                        }}
                                    >
                                        <span>{backButton}</span>
                                    </div>
                                )}

                                {/* next button */}
                                {nextButton && (
                                    <div
                                        className="next button"
                                        onClick={() => {
                                            if (inputText.length > 0) {
                                                onDismiss &&
                                                    onDismiss(
                                                        new DialogBoxResult({
                                                            value: inputText,
                                                            action: DialogBoxAction.NEXT,
                                                        })
                                                    );
                                            }
                                        }}
                                    >
                                        <span className={classNames({ disabled: hasInput && inputText?.length === 0 })}>
                                            {nextButton}
                                        </span>
                                    </div>
                                )}

                                {/* confirm target button */}
                                {confirmTargetButton && (
                                    <div
                                        className="confirmTarget button"
                                        onClick={() => {
                                            if (inputText.length > 0) {
                                                onDismiss &&
                                                    onDismiss(
                                                        new DialogBoxResult({
                                                            value: inputText,
                                                            action: DialogBoxAction.NEXT,
                                                        })
                                                    );
                                            }
                                        }}
                                    >
                                        <span className={classNames({ disabled: hasInput && inputText?.length === 0 })}>
                                            {confirmTargetButton}
                                        </span>
                                    </div>
                                )}
                            </div>
                        )}

                        {(freeFormButton || targetGoalButton) && (
                            <div className="column-button-area">
                                {/* free-form button */}
                                {freeFormButton && (
                                    <div
                                        className="freeForm button"
                                        onClick={() => {
                                            onDismiss &&
                                                onDismiss(
                                                    new DialogBoxResult({
                                                        action: DialogBoxAction.FREEFORM_WORKOUT,
                                                    })
                                                );
                                        }}
                                    >
                                        <div className="icon icon-freeform-white" />
                                        <span>{'QUICK START'}</span>
                                    </div>
                                )}

                                {/* targeted workout button */}
                                {targetGoalButton && (
                                    <div
                                        className="targetGoal button"
                                        onClick={() => {
                                            onDismiss &&
                                                onDismiss(
                                                    new DialogBoxResult({
                                                        action: DialogBoxAction.TARGETED_WORKOUT,
                                                    })
                                                );
                                        }}
                                    >
                                        <div className="icon icon-goal-white" />
                                        <span>{'SET A GOAL'}</span>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export function GroupDialogBox(props) {
    const metadata = MetaData.createMetadata(DialogType.CREATE_GROUP);
    return <DialogBox {...props} {...metadata} />;
}

export function UsernameDialogBox(props) {
    const metadata = MetaData.createMetadata(DialogType.SET_USERNAME);
    return <DialogBox {...props} {...metadata} />;
}

export function SelectGoalModeDialogBox(props) {
    const metadata = MetaData.createMetadata(DialogType.SELECT_GOAL_MODE);
    return <DialogBox {...props} {...metadata} />;
}

export function EditUsernameDialogBox(props) {
    const metadata = MetaData.createMetadata(DialogType.EDIT_USERNAME);
    return <DialogBox {...props} {...metadata} />;
}
