class Detector {
    /**
     * @param {number} imageWidth
     * @param {number} imageHeight
     * @param {Boolean} mirrored
     */
    constructor(imageWidth, imageHeight, mirrored) {
        this._imageWidth = imageWidth;
        this._imageHeight = imageHeight;
        this._mirrored = mirrored;

        const frameCanvas = document.createElement('canvas');
        frameCanvas.width = this._imageWidth;
        frameCanvas.height = this._imageHeight;
        this._frameCanvas = frameCanvas;
        this._frameCanvasContext = frameCanvas.getContext('2d');
        if (mirrored) {
            this._frameCanvasContext.translate(this._imageWidth, 0);
            this._frameCanvasContext.scale(-1, 1);
        }
    }

    async warmup() {
        await this.predict(this._frameCanvas, null);
    }

    /**
     * Predicts the classes in the given canvas.
     * The debug canvas is used to render debug information.
     * @param {HTMLCanvasElement} canvas The source canvas with the image data for prediction.
     * @param {Boolean} detectExtraInfo Whether we should detect extra information, if possible.
     * @param {HTMLCanvasElement} debugCanvas The canvas to which we draw our debug information.
     * @returns {Promise<Object>} The returned object should have a detection array, and optionally an extraInfo object/array.
     */
    async predict(canvas, debugCanvas) {}

    // For subclasses to implement.
    dispose() {}
}

export default Detector;
