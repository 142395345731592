import Detector from '../detectors/Detector';

const detectionStateFlipThreshold = 8;

/**
 * The game engine is a simple driver for calling the prediction and acting upon the predicted results.
 * The primary public functions are start() and stop(). The start() is synchronous, and it should be called
 * with an input canvas (from which predictions should be made). Optionally, the caller can also supply a debug
 * canvas, which will be supplied to the predictor for debug output, if supported.
 * The stop() method is asynchronous, and the returned promise will only resolve when all out-going predictions are done.
 *
 * @class GameEngine
 */
class GameEngine {
    /**
     * Creates an instance of GameEngine.
     * @param {Detector} detector
     * @param {function} dispatcher
     *
     * @memberof GameEngine
     */
    constructor(detector, dispatcher) {
        this._detector = detector;
        this._dispatcher = dispatcher;
        /** @type {function} */
        this._performanceReporter = null;

        /** @type {HTMLCanvasElement} */
        this._video = null;
        /** @type {HTMLCanvasElement} */
        this._debugCanvas = null;

        /** @type {number} */
        this._gameStartTime = 0;
        /** @type {number} */
        this._gamePauseTime = null;
        /** @type {number} */
        this._totalPauseTime = 0;
        /** @type {number} */
        this._lastReportedSeconds = -1;
        /** @type {number} */
        this._requestAnimationFrameHandle = null;

        /** @type {number} */
        this._lastTickTime = null;
        /** @type {number} */
        this._lastRenderTime = null;
        /** @type {boolean} */
        this._predicting = false;

        /** @type {function} */
        this._stopClosure = null; // This is the closure to be invoked when we have completed stopped.

        /** @type {number} */
        this._detectionDelay = 0;
        /** @type {number} */
        this._nextDetectionTime = 0;

        // Detection warning states.
        /** @type {Boolean} */
        this._noDetectionWarned = false;
        /** @type {number} */
        this._numDetectionFlipped = 0;
    }

    /**
     * Starts the game engine, running detection on canvas and rendering debug results in debugCanvas.
     *
     * @param {HTMLVideoElement} video
     * @param {HTMLCanvasElement} debugCanvas
     * @param {function} performanceReporter
     * @memberof GameEngine
     */
    start(video, debugCanvas, performanceReporter) {
        this._video = video;
        this._debugCanvas = debugCanvas;
        this._performanceReporter = performanceReporter;
        this._gameStartTime = Date.now();
        this._nextDetectionTime = this._gameStartTime;
        this._requestAnimationFrameHandle = requestAnimationFrame(() => this._update());
    }

    pause() {
        if (this._gamePauseTime === null) {
            this._gamePauseTime = Date.now();
            if (this._requestAnimationFrameHandle) {
                cancelAnimationFrame(this._requestAnimationFrameHandle);
                this._requestAnimationFrameHandle = null;
            }
        }
    }

    resume() {
        if (this._pauseTime !== null) {
            this._totalPauseTime += Date.now() - this._gamePauseTime;
            this._gamePauseTime = null;
            this._requestAnimationFrameHandle = requestAnimationFrame(() => this._update());
        }
    }

    /**
     * Stops auto updating, and resolve the returned promise when all on-going detections are done.
     *
     * @returns {Promise}
     * @memberof GameEngine
     */
    async stop() {
        this._dispatcher = null; // Reset the dispatcher, so this engine would no longer send anything.
        if (this._requestAnimationFrameHandle) {
            cancelAnimationFrame(this._requestAnimationFrameHandle);
        }
        const stopPromise = this._predicting
            ? new Promise((resolve, reject) => {
                  this._stopClosure = resolve;
              })
            : Promise.resolve();
        return stopPromise.then(() => {
            this._detector.dispose();
        });
    }

    /**
     * Sets the detection delay to value. This means that after one detection, we will wait until this amount of time before we run another detection.
     *
     * @param {number} value
     * @memberof GameEngine
     */
    setDetectionDelay(value) {
        this._detectionDelay = value;
    }

    /**
     * Returns whether the detector should try to return extra info.
     */
    _needsExtraInfo() {
        return false;
    }

    /**
     * Retrieve newest time and run the prediction / tick the engine as needed.
     *
     * @memberof GameEngine
     */
    _update() {
        if (this._gamePauseTime !== null) {
            // The game is paused right now.
            return;
        }
        let currTime = Date.now();
        if (this._lastTickTime != null) {
            this._performanceReporter({ tickingTime: currTime - this._lastTickTime });
        }
        this._lastTickTime = currTime;

        if (
            !this._predicting &&
            !this._stopClosure &&
            this._gamePauseTime === null &&
            currTime >= this._nextDetectionTime
        ) {
            // We are going to start predicting now.
            this._predicting = true;
            const startTime = Date.now();
            if (this._lastRenderTime != null) {
                this._performanceReporter({ renderingTime: startTime - this._lastRenderTime });
            }
            this._lastRenderTime = startTime;
            this._detector.predict(this._video, this._needsExtraInfo(), this._debugCanvas).then((result) => {
                const endTime = Date.now();
                this._predicting = false;
                if (this._stopClosure) {
                    this._stopClosure();
                    return;
                }
                this._performanceReporter({ predictionTime: endTime - startTime });
                this._nextDetectionTime = endTime + this._detectionDelay;
                // Check if we have empty detections a long time.
                const detection = result.detection;
                this._handleDetectionWarning(this._shouldShowDetectionWarning(detection));
                this._handleDetection(
                    detection,
                    // If the game is paused, we will use that time instead.
                    // This is to prevent time from going backward when the engine is resumed.
                    (this._gamePauseTime || endTime) - this._gameStartTime - this._totalPauseTime
                );

                if (result.extraInfo) {
                    this._handleExtraInfo(result.extraInfo);
                }
            });
        }

        const gameTime = currTime - this._gameStartTime - this._totalPauseTime;
        this._tick(gameTime);
        const seconds = Math.trunc(gameTime / 1000);
        if (this._dispatcher && seconds > this._lastReportedSeconds) {
            this._lastReportedSeconds = seconds;
            this._dispatcher({ stats: { totalTime: seconds } });
        }
        this._requestAnimationFrameHandle = requestAnimationFrame(() => this._update());
    }

    /**
     * Returns true if we think the warning message should be shown based on the latest pose detection.
     *
     * @param {Pose[]} poses
     * @returns
     * @memberof GameEngine
     */
    _shouldShowDetectionWarning(poses) {
        return poses.length > 0;
    }

    /**
     * Checks to see if we should send a warning for having no detection.
     * @param {Boolean} shouldShowWarning
     */
    _handleDetectionWarning(shouldShowWarning) {
        if (shouldShowWarning === this._noDetectionWarned) {
            // If the state hasn't changed, we have nothing to do.
            return;
        }
        // So now there is a flipped state.
        if (++this._numDetectionFlipped >= detectionStateFlipThreshold) {
            this._noDetectionWarned = !this._noDetectionWarned;
            this._numDetectionFlipped = 0;
            if (this._dispatcher) {
                this._dispatcher({ noDetection: this._noDetectionWarned });
            }
        }
    }

    /**
     * To be overridden by subclasses.
     *
     * @param {number} time  Time in millisecond, since game start.
     * @memberof GameEngine
     */
    _tick(time) {}

    /**
     * Handles the detection, which is the result returned by the detector.
     *
     * @param {any} detection
     * @param {number} time
     * @memberof GameEngine
     */
    _handleDetection(detection, time) {}

    /**
     * This is invoked when _needsExtraInfo() returns before, and the detector actually returned some extra info.
     * @param {any} extraInfo
     */
    _handleExtraInfo(extraInfo) {}

    /**
     * Subclasses can call _emit to emit signals / data that they want so that they can be fed back to the game stat.
     * The argument should be a dictionary with the appropriate key/value pairs.
     * @param {Object} data
     */
    _emit(data) {
        if (this._dispatcher) {
            this._dispatcher(data);
        }
    }
}

export default GameEngine;
