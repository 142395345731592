import classNames from 'classnames';
import { useEffect, useMemo } from 'react';
import GroupsPage from './GroupsPage';
import Home from './Home';
import { SidebarSelection } from '../SideBar';
import { Notification } from '../Notification';
import { GroupSelection } from '../GroupSelection';
import './GroupHome.scss';
import Summary from './Summary';
import { useHistory } from 'react-router-dom';
import { PageControllerAction } from '../PageController';
import { use100vh } from 'react-div-100vh';

export default function GroupHome(props) {
    const history = useHistory();
    const height = use100vh();

    useEffect(() => {
        switch (props.path) {
            case '/summary':
                history.push('/');
                props.pageController(PageControllerAction.SHOW_HISTORY);
                break;
            default:
                break;
        }
        // eslint-disable-next-line
    }, [props.path]);

    return (
        <div className="group-home" style={{ height: `${height}px` }}>
            <div className={classNames({ hidden: props.dependency.outstanding > 0 })}>
                <div
                    className={classNames('group-layer', {
                        'show-group-details':
                            props.pageControllerState.sidebarSelection === SidebarSelection.GROUPS &&
                            props.pageControllerState.displayGroup,
                    })}
                >
                    <GroupsPage {...props} />
                </div>
                <div className="home-layer">
                    {props.pageControllerState.sidebarSelection === SidebarSelection.HISTORY && <Summary {...props} />}
                    {props.pageControllerState.sidebarSelection !== SidebarSelection.HISTORY && <Home {...props} />}
                </div>
                <div className="notification-layer">
                    <Notification
                        pageController={props.pageController}
                        pageControllerState={props.pageControllerState}
                    />
                </div>
            </div>
        </div>
    );
}
