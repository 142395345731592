import { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import './MetricsDashboard.scss';

function timeString(seconds, maxBound = 6000) {
    const totalMinutes = Math.floor(seconds / 60);
    const totalRemainderSeconds = Math.floor(seconds) % 60;
    if (isNaN(totalMinutes) || isNaN(totalRemainderSeconds) || seconds > maxBound) {
        return '- : -';
    }
    return totalMinutes.toFixed(0) + ':' + (totalRemainderSeconds < 10 ? '0' : '') + totalRemainderSeconds.toFixed(0);
}

const updateThreshold = 1000;

export default function ComputedMetricsDashboard({ activityState }) {
    const [statsImprovement, setStatsImprovement] = useState({});
    const [avgStats, setAvgStats] = useState({});
    const [lastAvgUpdateTime, setLastAvgUpdateTime] = useState(null);

    // Calculates and update avg values
    useEffect(() => {
        const now = new Date().getTime();
        const updates = {
            output: (activityState.totalOutput / activityState.totalTime) * 1000,
            pace: activityState.totalTime / activityState.totalDistance,
            speed: activityState.totalDistance / (activityState.totalTime / 3600.0),
        };
        if (!lastAvgUpdateTime || now - lastAvgUpdateTime > updateThreshold) {
            setStatsImprovement({
                pace: avgStats.pace > updates.pace, // the smaller, the better
                output: avgStats.output < updates.output, // the bigger, the better
                speed: avgStats.speed < updates.speed, // the bigger, the better
            });
            setAvgStats(updates);
            setLastAvgUpdateTime(now);
        }
        // eslint-disable-next-line
    }, [activityState]);

    return (
        <div className="computed-dashboard">
            {/* OUTPUT */}
            <div className="container">
                <div className="metric-title">
                    OUTPUT&nbsp;<span className="unit-label">(WATTS)</span>
                </div>
                <div className="value">
                    {isNaN(activityState.currentOutput)
                        ? '-'
                        : Intl.NumberFormat('en-US', { maximumFractionDigits: 0 }).format(activityState.currentOutput)}
                </div>
                <div className="details-container">
                    <div className="details">
                        <div className="title">BEST</div>
                        <div className="value">
                            {isNaN(activityState.maxOutput)
                                ? '-'
                                : Intl.NumberFormat('en-US', { maximumFractionDigits: 0 }).format(
                                      activityState.maxOutput
                                  )}
                        </div>
                    </div>
                    <div className="details">
                        <div className="title">AVG</div>
                        <div className="value">
                            {isNaN(avgStats.output)
                                ? '-'
                                : Intl.NumberFormat('en-US', { maximumFractionDigits: 0 }).format(avgStats.output)}
                        </div>
                        <div
                            className={classNames('icon', {
                                'icon-up-green': statsImprovement.output,
                                'icon-down-red':
                                    statsImprovement.output !== undefined && statsImprovement.output === false,
                            })}
                        />
                    </div>
                </div>
            </div>

            {/* PACE */}
            <div className="container">
                <div className="metric-title">
                    PACE&nbsp;<span className="unit-label">(MIN/MI)</span>
                </div>
                <div className="value">{timeString(activityState.currentPace)}</div>
                <div className="details-container">
                    <div className="details">
                        <div className="title">BEST</div>
                        <div className="value">{timeString(activityState.minPace)}</div>
                    </div>
                    <div className="details">
                        <div className="title">AVG</div>
                        <div className="value">{timeString(avgStats.pace)}</div>
                        <div
                            className={classNames('icon', {
                                'icon-up-green': statsImprovement.pace,
                                'icon-down-red': statsImprovement.pace !== undefined && statsImprovement.pace === false,
                            })}
                        />
                    </div>
                </div>
            </div>

            {/* SPEED */}
            <div className="container">
                <div className="metric-title">
                    SPEED&nbsp;<span className="unit-label">(MPH)</span>
                </div>
                <div className="value">
                    {isNaN(activityState.currentSpeed)
                        ? '-'
                        : Intl.NumberFormat('en-US', {
                              minimumFractionDigits: 1,
                              maximumFractionDigits: 1,
                          }).format(activityState.currentSpeed)}
                </div>
                <div className="details-container">
                    <div className="details">
                        <div className="title">BEST</div>
                        <div className="value">
                            {isNaN(activityState.maxSpeed)
                                ? '-'
                                : Intl.NumberFormat('en-US', {
                                      minimumFractionDigits: 1,
                                      maximumFractionDigits: 1,
                                  }).format(activityState.maxSpeed)}
                        </div>
                    </div>
                    <div className="details">
                        <div className="title">AVG</div>
                        <div className="value">
                            {isNaN(avgStats.speed)
                                ? '-'
                                : Intl.NumberFormat('en-US', {
                                      minimumFractionDigits: 1,
                                      maximumFractionDigits: 1,
                                  }).format(avgStats.speed)}
                        </div>
                        <div
                            className={classNames('icon', {
                                'icon-up-green': statsImprovement.speed,
                                'icon-down-red':
                                    statsImprovement.speed !== undefined && statsImprovement.speed === false,
                            })}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
