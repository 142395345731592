import { useEffect, useState } from 'react';
import useSound from 'use-sound';
import stepSfx from './jog-count-002.wav';
import goal100Sfx from './100-percent-finished.wav';
import goal100Speech from './vo-100-percent-finished.aac';
import goal50Sfx from './50-percent-finished.mp3';
import goal50Speech from './vo-50-percent-finished.aac';
import { PageControllerAction } from '../components/PageController';

export default function AudioController({
    enabled,
    goalProgressPercent,
    totalStepCount,
    totalJumpCount,
    goal,
    pageController,
}) {
    const [playStepSfx] = useSound(stepSfx, { html5: true, volume: 1.0 });
    const [playGoal50Sfx] = useSound(goal50Sfx, { html5: true });
    const [playGoal50Speech] = useSound(goal50Speech, { html5: true });
    const [playGoal100Sfx] = useSound(goal100Sfx, { html5: true });
    const [playGoal100Speech] = useSound(goal100Speech, { html5: true });
    const [prevStats, setPrevStats] = useState({ totalStepCount: 0, totalJumpCount: 0 });

    useEffect(() => {
        return () => {
            console.log('[AudioController] cleanup');
            pageController(PageControllerAction.STOP_BACKGROUND_MUSIC);
        };
        // eslint-disable-next-line
    }, []);

    // Uncomment to debug
    // useEffect(() => {
    //     console.log('goalProgressPercent', goalProgressPercent);
    // }, [goalProgressPercent]);

    useEffect(() => {
        if (enabled) {
            if (prevStats.totalStepCount < totalStepCount) {
                setPrevStats({ ...prevStats, totalStepCount });
                playStepSfx();
            }
            if (prevStats.totalJumpCount < totalJumpCount) {
                setPrevStats({ ...prevStats, totalJumpCount });
                playStepSfx();
            }
        }
        // eslint-disable-next-line
    }, [enabled, prevStats, totalStepCount, totalJumpCount]);

    useEffect(() => {
        if (enabled && !goal.progress100 && goalProgressPercent >= 100) {
            playGoal100Sfx();
            playGoal100Speech();
            goal.progress100 = true;
            console.log('progress100 audio fired');
        }
        // eslint-disable-next-line
    }, [enabled, goal, goalProgressPercent]);

    useEffect(() => {
        if (enabled && !goal.progress50 && goalProgressPercent >= 50) {
            playGoal50Sfx();
            playGoal50Speech();
            goal.progress50 = true;
            console.log('progress50 audio fired');
        }
        // eslint-disable-next-line
    }, [enabled, goal, goalProgressPercent]);

    return <div></div>;
}
