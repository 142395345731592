import './LeaderboardBody.scss';
import classNames from 'classnames';
import numeral from 'numeral';

var elementToFocus = false;
const onlineThreshold = 10000; // Anyone with activity in the last 10s are included.

function LeaderboardRow({ data, key, highlight, rowRef, timestamp }) {
    const picStyle = { backgroundImage: `url("${data.pic}")` };
    const ref = elementToFocus === false && highlight ? rowRef : null;
    if (ref) {
        elementToFocus = true;
    }
    return (
        <div className={classNames('row', { highlight: highlight })} key={`row-${key}`} ref={ref}>
            <div className="rank" key={`rank-${key}`}>
                {data.rank}
            </div>
            <div
                className={classNames('pic', {
                    online: (data.lastUpdateTime?.getTime() || 0.0) + onlineThreshold > timestamp,
                })}
                key={`pic-${key}`}
                style={picStyle}
            />
            <div className="name" key={`name-${key}`}>
                {data.name}
            </div>
            <div className="score" key={`score-${key}`}>
                {numeral(data.score).format('0.[0]a')}
            </div>
        </div>
    );
}

export default function LeaderboardBody({ items, uid, rowRef, timestamp }) {
    elementToFocus = false;

    return (
        <div className="leaderboard-body">
            {items?.map((ppl) => {
                return LeaderboardRow({
                    data: ppl,
                    key: ppl.uid,
                    highlight: ppl.uid === uid,
                    rowRef,
                    timestamp,
                });
            })}
        </div>
    );
}
