import SideBarProfileButton from './SideBarProfileButton';
import './SideBarProfilePanel.scss';
import { DialogBoxAction, EditUsernameDialogBox } from './DialogBox';
import { isNil, isEmpty } from 'lodash';
import { useState } from 'react';

export default function SideBarProfilePanel({ appUser, userUpdater, onClickOutside }) {
    const [isEditingName, setIsEditingName] = useState(false);
    const editNameOnDismissHandler = (result) => {
        if (result.action === DialogBoxAction.NEXT && !isNil(result.value) && !isEmpty(result.value)) {
            userUpdater({ name: result.value });
        }
        setIsEditingName(false);
    };
    return (
        <div className="SideBarProfilePanelLayer" onClick={onClickOutside}>
            <div
                className="Panel"
                onClick={(event) => {
                    event.stopPropagation();
                }}
            >
                <div className="PreviewContainer">
                    <SideBarProfileButton appUser={appUser} />
                    <div className="PreviewTextContainer">
                        <div className="NameLabel">{appUser.name}</div>
                        <div className="OnlineLabel">Online</div>
                    </div>
                </div>
                <div
                    className="EditProfileRow"
                    onClick={() => {
                        setIsEditingName(true);
                    }}
                >
                    <div className="Icon" />
                    <div className="Text">Edit name</div>
                </div>
            </div>
            {isEditingName && (
                <EditUsernameDialogBox initialInput={appUser?.name || ''} onDismiss={editNameOnDismissHandler} />
            )}
        </div>
    );
}
