import { db, getExistData } from './firebase';
import { getGroupsAsync } from './group';

export function getUserRef(userId) {
    const usersCollection = db.collection('users');
    return usersCollection.doc(userId);
}

export async function getUserGroupsAsync(userId) {
    const user = await getUserRef(userId).get().then(getExistData);
    const groupIds = Object.keys(user.groups || {});
    return await getGroupsAsync(groupIds);
}
