import '../styles/common.scss';

export default function InviteButton({ onClickHandler }) {
    const onClick = (event) => {
        event.stopPropagation();
        if (onClickHandler) {
            return onClickHandler(event);
        }
    };
    return (
        <div className="invite-button" onClick={onClick}>
            <div className="text">Invite</div>
        </div>
    );
}
