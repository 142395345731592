const kBaseStepDistanceInMeters = 0.7;
const kLargeStepBonusMinInches = 5; // total diff (up + down)
const kLargeStepBonusMaxInches = 10; // total diff (up + down)
const kLargeStepBonusRate = 0.5; // max bonus = kLargeStepBonusRate * kBaseStepDistanceInMeters

export class Step {
    /**
     * Creates an instance of Step.
     * @param {number} frameTime
     * @param {number} upAmplitude
     * @param {number} downAmplitude
     * @param {number} upDuration
     * @param {number} downDuration
     * @param {number} pixelPerInch
     * @memberof Step
     */
    constructor(frameTime, upAmplitude, downAmplitude, upDuration, downDuration, pixelPerInch) {
        this.frameTime = frameTime;
        this.upAmplitude = upAmplitude;
        this.downAmplitude = downAmplitude;
        this.upDuration = upDuration;
        this.downDuration = downDuration;
        this.pixelPerInch = pixelPerInch;
    }

    /**
     * Returns the total amplitude of a step.
     *
     * @returns {number}
     * @memberof Step
     */
    amplitude() {
        return this.upAmplitude + this.downAmplitude;
    }

    /**
     * Returns the total duration of a step.
     *
     * @returns {number}
     * @memberof Step
     */
    duration() {
        return this.upDuration + this.downDuration;
    }

    /**
     * Returns whether the step is a strict step by checking up/down movement.
     *
     * @param {number} segDiffThreshold
     * @param {number} minSegDuration
     * @param {number} maxSegDuration
     * @returns {boolean}
     * @memberof Step
     */
    isStrict(segDiffThreshold, minSegDuration, maxSegDuration) {
        return (
            this.upDuration >= minSegDuration &&
            this.upDuration <= maxSegDuration &&
            this.downDuration >= minSegDuration &&
            this.downDuration <= maxSegDuration &&
            this.upAmplitude >= segDiffThreshold &&
            this.downAmplitude >= segDiffThreshold
        );
    }

    /**
     * Returns whether the step is a less strict step by checking total movement.
     *
     * @param {number} totalDiffThreshold
     * @param {number} minTotalDuration
     * @param {number} maxTotalDuration
     * @returns {boolean}
     * @memberof Step
     */
    isLessStrict(totalDiffThreshold, minTotalDuration, maxTotalDuration) {
        return (
            this.upDuration + this.downDuration >= minTotalDuration &&
            this.upDuration + this.downDuration <= maxTotalDuration &&
            this.upAmplitude + this.downAmplitude >= totalDiffThreshold
        );
    }

    /**
     * Return the distance of the step, considering the large step bonus.
     *
     * @returns {number}
     * @memberof Step
     */
    distance() {
        const bonusMinAmplitude = kLargeStepBonusMinInches * this.pixelPerInch;
        const bonusMaxAmplitude = kLargeStepBonusMaxInches * this.pixelPerInch;
        const amplitudeRate = Math.max(
            0.0,
            Math.min(1.0, (this.amplitude() - bonusMinAmplitude) / (bonusMaxAmplitude - bonusMinAmplitude))
        );
        return kBaseStepDistanceInMeters * (1 + amplitudeRate * kLargeStepBonusRate);
    }
}

/**
 * Pops old steps in the tail of a steps history (array sorted in descending time order)
 *
 * @export
 * @param {Step[]} steps
 * @param {double} curFrameTime
 * @param {double} timeWindow
 */
export function popOutdatedSteps(steps, curFrameTime, timeWindow) {
    while (steps.length > 0 && steps[steps.length - 1].frameTime < curFrameTime - timeWindow) {
        steps.pop();
    }
}
