import { useLayoutEffect, useEffect, useReducer, useState } from 'react';

function createDependencyStruct(initialDependencies) {
    const record = {};
    let outstanding = 0;
    for (const dep of initialDependencies) {
        record[dep] = true;
        ++outstanding;
    }
    return { outstanding, record };
}

function dependencyReducer(state, change) {
    // Check if there is anything in change.
    const record = state.record;
    let entries = Object.entries(change).filter(([key, val]) => record[key] !== val);
    if (entries.length === 0) {
        // Nothing changed.
        return state;
    }
    let delta = 0;
    const newRecord = { ...record };
    for (const [key, value] of entries) {
        newRecord[key] = value;
        delta += value ? 1 : -1;
    }
    const newState = {
        outstanding: state.outstanding + delta,
        record: newRecord,
    };
    return newState;
}

export default function useDependencyTracker(initialDependencies) {
    return useReducer(dependencyReducer, initialDependencies, createDependencyStruct);
}
