import '../styles/common.scss';

export function CreateGroupButton({ onClickHandler, style = {} }) {
    const onClick = (event) => {
        event.stopPropagation();
        if (onClickHandler) {
            return onClickHandler(event);
        }
    };
    return (
        <div className="create-group-button" onClick={onClick} style={style}>
            <div className="icon plus-icon"></div>
            <div className="text">CREATE GROUP</div>
        </div>
    );
}

export function StartButton({ onClickHandler, style = {} }) {
    const onClick = (event) => {
        event.stopPropagation();
        if (onClickHandler) {
            return onClickHandler(event);
        }
    };
    return (
        <div className="start-button" onClick={onClick} style={style}>
            <div className="text">START</div>
        </div>
    );
}
