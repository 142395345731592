import GameEngine from './engine/GameEngine';
import TreadmillGameEngine from './engine/TreadmillGameEngine';
import ExerciseRepGameEngine from './engine/ExerciseRepGameEngine';
import JumpRopeGameEngine from './engine/JumpRopeGameEngine';

// For other game modes.
// import ReactionGameEngine from './engine/ReactionGameEngine';
// import TrivialGameEngine from './engine/TrivialGameEngine';
// import BackboardDetector from './detectors/BackboardDetector';
// import PoseDetector from './detectors/PoseDetector';

// This is our default screen dimensions.
const landscapeWidth = 960;
const landscapeHeight = 720;
const portraitWidth = 405;
const portraitHeight = 720;
const unknownWidth = 540;
const unknownHeight = 540;

class CaptureConfig {
    /**
     *
     * @param {string} name
     * @param {string} orientation
     * @param {function} engineFactory
     */
    constructor(name, orientation, engineFactory) {
        this.name = name;
        this.orientation = orientation;

        if (orientation === 'landscape') {
            this.width = landscapeWidth;
            this.height = landscapeHeight;
        } else if (orientation === 'portrait') {
            this.width = portraitWidth;
            this.height = portraitHeight;
        } else {
            // This should really be an invalid case.
            this.width = unknownWidth;
            this.height = unknownHeight;
        }

        this._engineFactory = engineFactory;
    }

    /**
     * @param {HTMLDivElement} container
     * @param {function} dispatcher
     * @returns {Promise<GameEngine>}
     */
    async createEngine(container, dispatcher) {
        return this._engineFactory(container, dispatcher);
    }
}

// /** @type {CaptureConfig[]} */
// CaptureConfig.allConfigs = [
//     // new CaptureConfig('Treadmill', 'landscape', TreadmillGameEngine.create),
//     new CaptureConfig('ExerciseRep', 'landscape', ExerciseRepGameEngine.create),
//     // new CaptureConfig('Reaction', 'landscape', ReactionGameEngine.create),
//     // new CaptureConfig('Pose', 'landscape', TrivialGameEngine.getFactory(PoseDetector.create)),
//     // new CaptureConfig('Backboard', 'landscape', TrivialGameEngine.getFactory(BackboardDetector.create)),
// ];

CaptureConfig.allConfigs = {
    TreadMill: new CaptureConfig('Treadmill', 'landscape', TreadmillGameEngine.create),
    JumpRope: new CaptureConfig('JumpRope', 'landscape', JumpRopeGameEngine.create),
    JumpingJack: new CaptureConfig('ExerciseRep', 'landscape', ExerciseRepGameEngine.create),
};

export default CaptureConfig;
