import './GroupsPage.scss';
import { SideBar } from '../SideBar';
import GroupList from '../GroupList';
import { GroupTable } from '../GroupTable';
import { useState, useEffect } from 'react';

export default function GroupsPage(props) {
    const [groupTableSorting, setGroupTableSorting] = useState(null);
    const [groupTableFilterState, setGroupTableFilterState] = useState(props.pageControllerState.groupTableTimeFilter); // expected value: TimeRangeFilterType
    const groupPageState = {
        groupTableSorting,
        setGroupTableSorting,
        groupTableFilterState,
        setGroupTableFilterState,
    };

    // unset sorting state when display group changes
    useEffect(() => {
        setGroupTableSorting({});
    }, [props.pageControllerState.displayGroup]);

    return (
        <div className="group-page">
            <SideBar {...props} />
            <GroupList {...props} {...groupPageState} />
            <GroupTable {...props} {...groupPageState} />
        </div>
    );
}
