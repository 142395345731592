import { useReducer, useState } from 'react';
import MovingAverage from '../../utils/MovingAverage';
import { GameMetrics } from './GameMetrics';

// Styling.
import './PerformanceTracker.scss';

const windowSize = 8;
const defaultTime = 40; // The default time for various moving averages.

/**
 * Creates the initial state for React components to track GameEngine performances.
 */
function performanceCreator() {
    return {
        tickingTimeAverage: new MovingAverage(windowSize, defaultTime),
        predictionTimeAverage: new MovingAverage(windowSize, defaultTime),
        renderingTimeAverage: new MovingAverage(windowSize, defaultTime),
        tickingTime: defaultTime,
        predictionTime: defaultTime,
        renderingTime: defaultTime,
    };
}

function performanceReducer(state, action) {
    let updated = false;
    if (action.tickingTime) {
        state.tickingTimeAverage.addSample(action.tickingTime);
        updated = true;
    }
    if (action.predictionTime) {
        state.predictionTimeAverage.addSample(action.predictionTime);
        updated = true;
    }
    if (action.renderingTime) {
        state.renderingTimeAverage.addSample(action.renderingTime);
        updated = true;
    }
    if (!updated) {
        return state;
    } else {
        return {
            tickingTimeAverage: state.tickingTimeAverage,
            predictionTimeAverage: state.predictionTimeAverage,
            renderingTimeAverage: state.renderingTimeAverage,
            tickingTime: state.tickingTimeAverage.average(),
            predictionTime: state.predictionTimeAverage.average(),
            renderingTime: state.renderingTimeAverage.average(),
        };
    }
}

function usePerformanceTracker() {
    return useReducer(performanceReducer, undefined, performanceCreator);
}

/**
 * @param {number} value
 * @return {string}
 */
function computeClassName(value) {
    if (value < 20) {
        return 'fast';
    } else if (value > 40) {
        return 'slow';
    } else {
        return 'normal';
    }
}

function PerformanceTracker({ packet }) {
    const { tickingTime, predictionTime, renderingTime } = packet;
    return (
        <div className="performance">
            <div className="game-engine">
                Engine: <span className={computeClassName(tickingTime)}>{(1000 / tickingTime).toFixed(2)}</span>
            </div>
            <div className="prediction-time">
                ML: <span className={computeClassName(predictionTime)}>{predictionTime.toFixed(2)} ms</span>
            </div>
            <div className="prediction-time">
                FPS: <span className={computeClassName(renderingTime)}>{(1000 / renderingTime).toFixed(2)}</span>
            </div>
        </div>
    );
}

export { usePerformanceTracker, PerformanceTracker };
