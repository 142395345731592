import './LandscapeWatch.scss';

export default function LandscapeWatch({ children }) {
    return (
        <div className="landscape-watch">
            <div className="landscape-only">{children}</div>
            <div className="landscape-only-message centering">
                <div className="icon" />
                <div className="text">
                    Rotate your device screen to landscape, so you can see enjoy the whole experience.
                </div>
            </div>
        </div>
    );
}
