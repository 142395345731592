import './MetricsDashboard.scss';

function timeString(seconds) {
    const totalMinutes = Math.floor(seconds / 60);
    const totalRemainderSeconds = Math.floor(seconds) % 60;
    return totalMinutes.toFixed(0) + ':' + (totalRemainderSeconds < 10 ? '0' : '') + totalRemainderSeconds.toFixed(0);
}

export const MetricsType = {
    DISTANCE: 'DISTANCE',
    TIME: 'TIME',
    CALORIES: 'CALORIES',
    STEPS: 'STEPS',
    JUMPS: 'JUMPS',
};

export default function BasicMetricsDashboard({ activityState, metricsType }) {
    const distance = Intl.NumberFormat('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    }).format(parseInt(activityState.totalDistance * 100) / 100);

    const seconds = Math.floor(activityState.totalTime);

    const calories = Intl.NumberFormat('en-US', { maximumFractionDigits: 0 }).format(activityState.totalEnergy);

    const steps = activityState.totalStepCount;

    const jumps = activityState.totalJumpCount;

    var title;
    var value;
    switch (metricsType) {
        case MetricsType.DISTANCE:
            title = 'DISTANCE (MI)';
            value = distance;
            break;
        case MetricsType.TIME:
            title = 'TIME';
            value = timeString(seconds);
            break;

        case MetricsType.CALORIES:
            title = 'CALORIES';
            value = calories;
            break;

        case MetricsType.STEPS:
            title = 'STEPS';
            value = steps;
            break;

        case MetricsType.JUMPS:
            title = 'JUMPS';
            value = jumps;
            break;

        default:
            break;
    }

    return (
        <div className="basic-dashboard">
            <div className="metric-title">{title}</div>
            <div className="metric-value">{value}</div>
        </div>
    );
}
