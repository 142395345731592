import BasicMetricsDashboard, { MetricsType } from './BasicMetricsDashboard';
import GoalDashboard from './GoalDashboard';
import ComputedMetricsDashboard from './ComputedMetricsDashboard';

export default function JoggingDashboard({ stats, goal, goalProgressPercent }) {
    return (
        <div className="dashboardContainer">
            <div className="upperContainer">
                <div className="basicMetricsContainer">
                    <BasicMetricsDashboard activityState={stats} metricsType={MetricsType.TIME} />
                    <BasicMetricsDashboard activityState={stats} metricsType={MetricsType.CALORIES} />
                </div>
                <GoalDashboard goal={goal} goalProgressPercent={goalProgressPercent} />
                <div className="basicMetricsContainer">
                    <BasicMetricsDashboard activityState={stats} metricsType={MetricsType.STEPS} />
                    <BasicMetricsDashboard activityState={stats} metricsType={MetricsType.DISTANCE} />
                </div>
            </div>
            <ComputedMetricsDashboard activityState={stats} />
        </div>
    );
}
