import './Notification.scss';
import { useEffect, useState, useMemo } from 'react';
import { PageControllerAction } from './PageController';

const TIMEOUT = {
    INVITE_FADE_OUT: 300,
    INVITE_PRESENT: 2000,
};

const NotificationType = {
    INVITE_LINK: 'INVITE_LINK',
};

const NotificationAnimationPhase = {
    ENTER: 'enter',
    LEAVE: 'leave',
};

function notificationContent(type) {
    switch (type) {
        case NotificationType.INVITE_LINK:
            return 'Invite link copied';
        default:
            return '';
    }
}

function Notification({ pageController, pageControllerState }) {
    const [notifTimeout, setNotifTimeout] = useState(null);
    const [animationPhase, setAnimationPhase] = useState(null);

    useEffect(() => {
        if (notifTimeout?.fn) {
            setTimeout(notifTimeout.fn, notifTimeout.time);
        }
    }, [notifTimeout]);

    useEffect(() => {
        const leaveAnimation = () => {
            // when leave animation ends
            setAnimationPhase(null);
            setNotifTimeout(null);
            pageController(PageControllerAction.DISMISS_NOTIFICATION);
        };

        const presentTimeout = () => {
            // when present timeout kicks off
            setAnimationPhase(NotificationAnimationPhase.LEAVE);
            setNotifTimeout({ fn: leaveAnimation, time: TIMEOUT.INVITE_FADE_OUT });
        };

        switch (pageControllerState.notification) {
            case NotificationType.INVITE_LINK:
                // if there is a previously set timeout, clear it
                if (notifTimeout?.fn) {
                    clearTimeout(notifTimeout.fn);
                }
                // kick start animation
                setAnimationPhase(NotificationAnimationPhase.ENTER);
                // set up animation after timeout
                setNotifTimeout({ fn: presentTimeout, time: TIMEOUT.INVITE_PRESENT });
                break;

            default:
                clearTimeout(notifTimeout);
                setNotifTimeout(null);
                setAnimationPhase(null);
                break;
        }
        // eslint-disable-next-line
    }, [pageControllerState.notification]);

    const content = useMemo(() => {
        return notificationContent(pageControllerState.notification);
    }, [pageControllerState.notification]);

    const animationClass = useMemo(() => {
        switch (animationPhase) {
            case NotificationAnimationPhase.ENTER:
                return 'clipboard-animate-enter';
            case NotificationAnimationPhase.LEAVE:
                return 'clipboard-animate-leave';
            default:
                return '';
        }
    }, [animationPhase]);

    const className = useMemo(() => {
        var classes = ['notification'];
        switch (pageControllerState.notification) {
            case NotificationType.INVITE_LINK:
                classes.push('clipboard');
                classes.push(animationClass);
                break;
            default:
                classes.push('empty');
                break;
        }
        return classes.join(' ');
    }, [pageControllerState.notification, animationClass]);

    return (
        <div className={className}>
            <div className="content">{content}</div>
        </div>
    );
}

export { Notification, NotificationType, NotificationAnimationPhase };
