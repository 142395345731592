import { useEffect, useState, useRef, useMemo } from 'react';
import classNames from 'classnames';
import './Leaderboard.scss';
import Button from './Button';
import LeaderboardHeader from './LeaderboardHeader';
import LeaderboardBody from './LeaderboardBody';
import mockdata from '../mock_data/leaderboard.json';
import { useSettings } from '../utils/Settings';

export default function Leaderboard({
    items,
    uid,
    group,
    filterValue,
    setFilterValue,
    videoRef,
    cannotSeeYou,
    isPaused,
    activityId
}) {
    const [hidden, setHidden] = useState(false);
    const [timestamp, setTimestamp] = useState(() => Date.now());
    const [mouseOver, setMouseOver] = useState(false);
    const [settings, settingsUpdater] = useSettings();

    const includeMockData = useMemo(() => {
        return settings.dev.includeMockLeaderboardData;
    }, [settings]);

    const mouseOverHandler = () => {
        setMouseOver(true);
    };

    const mouseLeaveHandler = () => {
        setMouseOver(false);
    };

    useEffect(() => {
        const handle = setInterval(() => {
            setTimestamp(Date.now());
        }, 500);
        return () => {
            clearInterval(handle);
        };
    }, []);

    const processedItems = useMemo(() => {
        const data = [...items].concat(includeMockData ? mockdata : []).sort((a, b) => b.score - a.score);
        return data.map((val, idx) => {
            return { ...val, rank: idx + 1 };
        });
    }, [items, includeMockData]);

    // referencing the first highlighted row
    // usage: for scrollIntoView
    const rowRef = useRef(null);

    useEffect(() => {
        !mouseOver && rowRef?.current && rowRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }, [rowRef, processedItems, mouseOver]);

    return (
        <div
            className={classNames('Leaderboard', { hidden: hidden })}
            onMouseOver={mouseOverHandler}
            onMouseLeave={mouseLeaveHandler}
        >
            {/* <Button
                className={classNames({ ShowButton: hidden, HideButton: !hidden })}
                onClick={() => setHidden(!hidden)}
            /> */}
            <div className={classNames('PreviewWindow')}>
                <video
                    className={classNames('video', { cannotSeeYou: cannotSeeYou })}
                    ref={videoRef}
                    playsInline={true}
                ></video>
                <div className={classNames('noDetectionWarningBackground', { hidden: !cannotSeeYou || isPaused })} />
                <div className={classNames('noDetectionWarning ' + activityId, { hidden: !cannotSeeYou || isPaused })} />
            </div>
            {!hidden && <LeaderboardHeader filterValue={filterValue} setFilterValue={setFilterValue} group={group} />}
            {!hidden && <LeaderboardBody items={processedItems} uid={uid} rowRef={rowRef} timestamp={timestamp} />}
        </div>
    );
}
