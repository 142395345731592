import './GroupList.scss';
import { GroupListType } from './GroupList';
import GroupListRow from './GroupListRow';

function title(type) {
    switch (type) {
        case GroupListType.MANAGE:
            return 'Groups you manage';
        case GroupListType.JOINED:
            return 'Groups you joined';
        default:
            return '';
    }
}

function noDataMessage(type) {
    switch (type) {
        case GroupListType.MANAGE:
            return 'Tap the button below to create new group and share the invitation link to your memebers.';
        case GroupListType.JOINED:
            return 'To join groups, paste the invitation link to the browser.';
        default:
            return '';
    }
}

export default function GroupListView(props) {
    const hasData = (data) => {
        return data.length && data.length > 0;
    };

    return (
        <div className={`group-list-view ${props.type}`} key={`{group-list-view-${props.type}}`}>
            <div className="title-text" key={`{title-text-${props.type}}`}>
                {title(props.type)}
            </div>
            {!hasData(props.data) && (
                <div className="no-data" key={`{no-data-${props.type}}`}>
                    {noDataMessage(props.type)}
                </div>
            )}
            <div className="data-area" key={`{data-area-${props.type}}`}>
                {props.data.map((row, rowNum) => {
                    return (
                        <GroupListRow
                            {...props}
                            key={`group-row-${row.id}-${props.type}`}
                            row={row}
                            rowNum={rowNum}
                            selected={row.id === props.pageControllerState.displayGroup?.id}
                        />
                    );
                })}
            </div>
        </div>
    );
}
