import { useState } from 'react';
import classNames from 'classnames';
import './Button.scss';

export default function Button({ className, children, style, onClick }) {
    const [active, setActive] = useState(false);
    return (
        <div
            className={classNames({ Active: active }, (className ?? '').split(' '))}
            style={style}
            tabIndex="0"
            role="button"
            onMouseDown={(e) => {
                e.preventDefault();
                setActive(true);
            }}
            onMouseUp={() => setActive(false)}
            onMouseOut={() => setActive(false)}
            onKeyPress={(e) => {
                if (e.key === 'Enter') {
                    e.target.click();
                }
            }}
            onClick={onClick}
        >
            {children}
        </div>
    );
}
