import { useReducer, useMemo } from 'react';
// This is a list of settings that we have in our app.

class Field {
    /**
     * @param {string} description
     * @param {any} defaultValue
     */
    constructor(description, defaultValue) {
        this.description = description;
        this._defaultValue = defaultValue;
        /** @type{string} */
        this.keyPath = null;
        /** @type{any} */
        this.value = null; // This is what the current known value is, after initialization.
    }

    // These functions are late bindings, after scanning through the SettingsSpec.
    /**
     *
     * @param {string} keyPath
     */
    initialize(keyPath) {
        this.keyPath = keyPath;
        const encoded = localStorage.getItem('settings-' + keyPath);
        if (encoded === null) {
            this.value = this._defaultValue;
        } else {
            this.value = this._decode(encoded);
        }
    }

    /**
     * Sets the value, and store that inside local storage.
     * @param {any} value
     * @returns {any}
     */
    set(value) {
        localStorage.setItem('settings-' + this.keyPath, this._encode(value));
        this.value = value;
        return value;
    }

    // The following functions are to be overridden by subclasses.
    /**
     * @param {any} value
     * @returns {string}
     */
    _encode(value) {}
    /**
     * @param {string} encoded
     * @returns {any}
     */
    _decode(encoded) {}

    /**
     * @param {function} setters
     * @returns {React.Component}
     */
    getInput(setter) {}
}

class BooleanField extends Field {
    _encode(value) {
        return value ? 'true' : 'false';
    }

    _decode(encoded) {
        return encoded === 'true';
    }

    getInput(setter) {
        const handleChange = (event) => {
            const newValue = event.target.checked;
            setter(this.keyPath, newValue);
        };
        return (
            <div key={this.keyPath}>
                <input type="checkbox" checked={this.value} onChange={handleChange} />
                <label>{this.description}</label>
            </div>
        );
    }
}

class FieldSet {
    constructor(description, contents) {
        this.description = description;
        /** @type { Object.<string, FieldSet> } */
        this.subsets = {};
        /** @type { Object.<string, Field> } */
        this.fields = {};
        for (const [key, value] of Object.entries(contents)) {
            if (value instanceof Field) {
                this.fields[key] = value;
            } else {
                // This is another subset.
                this.subsets[key] = value;
            }
        }
    }

    /**
     * Initializes all the underlying fields.
     * @param {string} [rootPath]
     */
    initialize(rootPath) {
        const prefix = rootPath ? rootPath + '.' : '';
        for (const [key, field] of Object.entries(this.fields)) {
            field.initialize(prefix + key);
        }
        for (const [key, subset] of Object.entries(this.subsets)) {
            subset.initialize(prefix + key);
        }
    }

    /**
     *
     * @param {string[]} keys
     * @param {number} index
     * @returns {any}
     */
    getValue(keys, index) {
        const key = keys[index];
        if (keys.length - 1 === index) {
            return this.fields[key].value;
        } else {
            return this.subsets[key].getValue(keys, index + 1);
        }
    }

    createReducerState() {
        // Create an initial hash, for useReducer.
        const ret = {};
        for (const [key, field] of Object.entries(this.fields)) {
            ret[key] = field.value;
        }
        for (const [key, subset] of Object.entries(this.subsets)) {
            ret[key] = subset.createReducerState();
        }
        return ret;
    }

    _reducerUpdate(state, keys, index, value) {
        // We are going to navigate down the keys.
        const key = keys[index];
        if (keys.length - 1 === index) {
            // This is the last level. We should find the field instead of the subset.
            const field = this.fields[key];
            return { ...state, key: field.set(value) };
        } else {
            // This is just a subset. Gets deeper.
            const subset = this.subsets[key];
            return { ...state, key: subset._reducerUpdate(state[key], keys, index + 1, value) };
        }
    }

    reducerUpdate(state, keyPath, value) {
        const keys = keyPath.split('.');
        return this._reducerUpdate(state, keys, 0, value);
    }
}

// This spec tells us the default value and the type of each field.
// This will be recursively traversed to figure out what paths there are.
const allSettings = new FieldSet('Settings', {
    activity: new FieldSet('Activity', {
        showDebugFrame: new BooleanField('Show debug frame', false),
        showPerformancePanel: new BooleanField('Show performance panel', false),
        useSmallModel: new BooleanField('Use smaller model', true),
        isDev: new BooleanField('Set as Dev account (reload to take effect)', false),
        useMotionCountdown: new BooleanField('use "Jog to Start" ("3-2-1 countdown", otherwise)', true),
        ensureGroupPresence: new BooleanField('Ensure group is set (redirect to home, otherwise)', true),
        showSteps: new BooleanField('Display STEPS instead of CALORIES', true),
    }),
    perfTuning: new FieldSet('Performance Tuning', {
        enableStatsUpdate: new BooleanField('Enable game stats updates', true),
        disablePrediction: new BooleanField('Disable ML prediction', false),
        disablePose: new BooleanField('Disable pose computation', false),
    }),
    dev: new FieldSet('Development', {
        includeMockLeaderboardData: new BooleanField('Include mock leaderboard data', false),
        autoStep: new BooleanField('Auto step: 1 step per second', false),
        enableAnalytics: new BooleanField('Enable analytics', true),
        enableBGM: new BooleanField('Enable BGM', true),
        enableJumpingJack: new BooleanField('Default drill: Jumping jack', false),
    }),
});

allSettings.initialize();

function useSettings() {
    // This returns a settings object (hash), and a config object that we can render the settings page / set properties.
    const [hash, dispatch] = useReducer(
        (state, action) => {
            const keyPath = action.keyPath;
            const value = action.value;
            return allSettings.reducerUpdate(state, keyPath, value);
        },
        undefined,
        () => allSettings.createReducerState()
    );
    const setter = useMemo(() => {
        return (keyPath, value) => {
            dispatch({ keyPath, value });
        };
    }, [dispatch]);
    return [hash, setter];
}

/**
 * Gets the value of the field at keyPath.
 * @param {string} keyPath
 */
function getSetting(keyPath) {
    return allSettings.getValue(keyPath.split('.'), 0);
}

export { useSettings, allSettings, getSetting };
