import { ActivityId } from '../ActivityId';
import Metric from './Metric';
import './SummaryStats.css';

export default function SummaryStats({ stats, activityId }) {
    let metrics;
    switch (activityId) {
        case ActivityId.TreadMill:
            metrics = generateTreadMillMetrics(stats);
            break;
        case ActivityId.JumpRope:
            metrics = generateJumpRopeMetrics(stats);
            break;
        case ActivityId.JumpingJack:
            metrics = generateJumpingJackMetrics(stats);
            break;
        default:
            metrics = {
                upperMetrics: [],
                lowerMetrics: [],
            };
            break;
    }

    return (
        <div className="SummaryStats">
            <div>{generateMetricComponents(metrics.upperMetrics)}</div>
            <div>{generateMetricComponents(metrics.lowerMetrics)}</div>
        </div>
    );
}

function getDurationString(duration) {
    const totalMinutes = Math.floor(duration / 60);
    const totalRemainderSeconds = Math.floor(duration) % 60;
    const timeStr =
        totalMinutes.toFixed(0) + ':' + (totalRemainderSeconds < 10 ? '0' : '') + totalRemainderSeconds.toFixed(0);
    return timeStr;
}

function generateMetricComponent(data) {
    return <Metric title={data.title} value={data.value} unit={data.unit} key={data.title} />;
}

function generateMetricComponents(dataArray) {
    let row = [];
    for (const data of dataArray) {
        if (row.length > 0) {
            row.push(<div className="Spacer" key={data.title + 'Spacer'} />);
        }
        row.push(generateMetricComponent(data));
    }
    return row;
}

function generateTreadMillMetrics(stats) {
    let upperMetrics = [
        {
            title: 'CALORIES',
            value: Intl.NumberFormat('en-US', { maximumFractionDigits: 0 }).format(stats.energy),
            unit: 'KCAL',
        },
        {
            title: 'STEPS',
            value: Intl.NumberFormat('en-US', { maximumFractionDigits: 0 }).format(stats.steps),
            unit: 'STEPS',
        },
        {
            title: 'DISTANCE',
            value: Intl.NumberFormat('en-US', { minimumFractionDigits: 1, maximumFractionDigits: 1 }).format(
                stats.distance
            ),
            unit: 'MI',
        },
        {
            title: 'TIME',
            value: getDurationString(stats.duration),
        },
    ];

    let lowerMetrics = [
        {
            title: 'PACE',
            value: Intl.NumberFormat('en-US', { maximumFractionDigits: 0 }).format(stats.pace),
            unit: 'MIN/\nMI',
        },
        {
            title: 'OUTPUT',
            value: Intl.NumberFormat('en-US', { maximumFractionDigits: 0 }).format(stats.output),
            unit: 'KJ',
        },
        {
            title: 'SPEED',
            value: Intl.NumberFormat('en-US', { minimumFractionDigits: 1, maximumFractionDigits: 1 }).format(
                stats.speed
            ),
            unit: 'MPH',
        },
    ];
    return {
        upperMetrics,
        lowerMetrics,
    };
}

function generateJumpRopeMetrics(stats) {
    let upperMetrics = [
        {
            title: 'CALORIES',
            value: Intl.NumberFormat('en-US', { maximumFractionDigits: 0 }).format(stats.jumpRope.energy),
            unit: 'KCAL',
        },
        {
            title: 'JUMPS',
            value: Intl.NumberFormat('en-US', { maximumFractionDigits: 0 }).format(stats.jumpRope.jumps),
            unit: 'JUMPS',
        },
        {
            title: 'TIME',
            value: getDurationString(stats.jumpRope.duration),
        },
    ];

    let lowerMetrics = [
        {
            title: 'JPM',
            value: Intl.NumberFormat('en-US', { maximumFractionDigits: 0 }).format(stats.jumpRope.jpm),
            unit: 'JUMP/\nMIN',
        },
        {
            title: 'MAX COMBO',
            value: Intl.NumberFormat('en-US', { maximumFractionDigits: 0 }).format(stats.jumpRope.maxCombo),
        },
    ];
    return {
        upperMetrics,
        lowerMetrics,
    };
}

function generateJumpingJackMetrics(stats) {
    let upperMetrics = [
        {
            title: 'CALORIES',
            value: Intl.NumberFormat('en-US', { maximumFractionDigits: 0 }).format(stats.jumpingJack.energy),
            unit: 'KCAL',
        },
        {
            title: 'JUMPS',
            value: Intl.NumberFormat('en-US', { maximumFractionDigits: 0 }).format(stats.jumpingJack.jumps),
            unit: 'JUMPS',
        },
        {
            title: 'TIME',
            value: getDurationString(stats.jumpingJack.duration),
        },
    ];

    let lowerMetrics = [
        {
            title: 'JPM',
            value: Intl.NumberFormat('en-US', { maximumFractionDigits: 0 }).format(stats.jumpingJack.jpm),
            unit: 'JUMP/\nMIN',
        },
        {
            title: 'MAX COMBO',
            value: Intl.NumberFormat('en-US', { maximumFractionDigits: 0 }).format(stats.jumpingJack.maxCombo),
        },
    ];
    return {
        upperMetrics,
        lowerMetrics,
    };
}
