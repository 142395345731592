import './Balloon.scss';
import { useMemo, useState } from 'react';

export const BalloonType = {
    EAST: 'east',
    WEST: 'west',
    NORTH: 'north',
    SOUTH: 'south',
};

export const BalloonTimeout = {
    NEW_GROUP: 5000,
};

function dismissBallon({ setDismiss, onClickHandler }) {
    return (event) => {
        onClickHandler && onClickHandler(event);
        setDismiss(true);
        event.stopPropagation();
    };
}

export function Balloon(props) {
    const { onClickHandler } = props;
    const [dismiss, setDismiss] = useState(false);
    const balloonOverallStyle = useMemo(() => {
        return dismiss ? { display: 'none' } : {};
    }, [dismiss]);

    return (
        <div style={balloonOverallStyle}>
            <div className="ballon" style={props.style || { display: 'none' }}>
                <div className={`triangle ${props.type}`}></div>
                <div className="title">{props.title}</div>
                <div className="content">{props.content}</div>
            </div>
            <div
                style={balloonOverallStyle}
                className="ballon-overlay"
                onClick={dismissBallon({ setDismiss, onClickHandler })}
            ></div>
        </div>
    );
}
