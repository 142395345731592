import './Metric.css';
import classNames from 'classnames';

export default function Metric({ big, style, title, value, unit }) {
    const rows = [];
    if (title) {
        rows.push(
            <div key="TitleRow" className="TitleRow">
                <div className="Title">{title}</div>
            </div>
        );
    }
    rows.push(
        <div key="ValueRow" className="ValueRow">
            <div className="Value">{value}</div>
            <div style={unit ? {} : { display: 'none' }} className="Unit">
                {unit}
            </div>
        </div>
    );
    return (
        <div className={classNames('Metric', big ? 'Big' : 'Small')} style={{ style }}>
            {rows}
        </div>
    );
}
