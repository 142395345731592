import './SideBar.scss';
import { PageControllerAction } from './PageController';
import analyticsManager from '../analytics/AnalyticsManager';
import { useState } from 'react';
import SideBarProfilePanel from './SideBarProfilePanel';
import SideBarProfileButton from './SideBarProfileButton';

const SidebarSelection = {
    HOME: 'home',
    GROUPS: 'groups',
    HISTORY: 'history',
};

function SideBar({ pageControllerState, pageController, appUser, userUpdater }) {
    const [isShowingProfilePanel, setIsShowingProfilePanel] = useState(false);
    const onClickHandler = (action) => {
        return (event) => {
            event.stopPropagation();
            switch (action) {
                case PageControllerAction.SHOW_HISTORY:
                    analyticsManager().trackVisitHistory();
                    break;
                default:
                    break;
            }
            pageController(action);
        };
    };
    const homeClassName =
        'home button' +
        (pageControllerState.sidebarSelection === SidebarSelection.HOME ? ' selected' : ' not-selected');
    const groupsClassName =
        'groups button' +
        (pageControllerState.sidebarSelection === SidebarSelection.GROUPS ? ' selected' : ' not-selected');
    const historyClassName =
        'history button' +
        (pageControllerState.sidebarSelection === SidebarSelection.HISTORY ? ' selected' : ' not-selected');
    return (
        <div className="sidebar">
            <div className={homeClassName} onClick={onClickHandler(PageControllerAction.SHOW_HOME)}>
                <div className="icon"></div>
                <div className="text-label">Home</div>
            </div>
            <div className="separator"></div>
            <div className={groupsClassName} onClick={onClickHandler(PageControllerAction.SHOW_GROUP_LIST)}>
                <div className="icon"></div>
                <div className="text-label">Groups</div>
            </div>
            <div className="separator"></div>
            <div className={historyClassName} onClick={onClickHandler(PageControllerAction.SHOW_HISTORY)}>
                <div className="icon"></div>
                <div className="text-label">History</div>
            </div>
            <div className="Spacer" />
            {appUser && appUser?.name && (
                <SideBarProfileButton
                    appUser={appUser}
                    onClick={() => {
                        setIsShowingProfilePanel(true);
                    }}
                />
            )}
            {isShowingProfilePanel && (
                <SideBarProfilePanel
                    appUser={appUser}
                    userUpdater={userUpdater}
                    onClickOutside={() => {
                        setIsShowingProfilePanel(false);
                    }}
                />
            )}
        </div>
    );
}

export { SideBar, SidebarSelection };
