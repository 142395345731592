import './HomeFooter.scss';

export default function HomeFooter() {
    return (
        <div className="Container">
            <div className="Links">
                <a href="mailto:inquiry@nexlab.ai">Contact us</a>
            </div>
            <div className="Notes">
                <span>© 2017 - 2021 NEX Team Inc. All rights reserved.</span>
                <a target="_blank" rel="noreferrer" href="https://www.nexlab.ai/legal/terms">
                    Terms of Use
                </a>
                <a target="_blank" rel="noreferrer" href="https://www.nexlab.ai/legal/privacy">
                    Privacy Policy
                </a>
            </div>
        </div>
    );
}
