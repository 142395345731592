import { ActivityId } from '../ActivityId';

export const GoalType = {
    DISTANCE_MILES: 'DISTANCE_MILES',
    DISTANCE_METERS: 'DISTANCE_METERS',
    TIME_MINUTES: 'TIME_MINUTES',
    TIME_HOURS: 'TIME_HOURS',
    CALORIES: 'CALORIES',
    STEPS: 'STEPS',
    JUMPS: 'JUMPS',
};

const metersToMiles = (meters) => {
    return meters * 0.000621371;
};

export class GoalSetting {
    static exponentialSettings = [100, 200, 400, 800];
    static linearSettings = { start: 1.0, increment: 0.5 };
    static jumpRopeLinearSettings = { start: 50, increment: 50 };

    static exponentialGoalIndex = (val) => {
        for (var i = 0; i < this.exponentialSettings.length; i++) {
            if (Math.abs(metersToMiles(this.exponentialSettings[i]) - val) < 1e-6) {
                return i;
            }
        }
        return -1;
    };

    constructor({
        type,
        presentingDistance = 0,
        presentingTime = 0,
        presentingCalories = 0,
        presentingSteps = 0,
        presentingJumps = 0,
        activityState = {},
        isAutoGoal = false,
        goalSeqNum = 1,
        options = {},
    }) {
        // properties

        switch (type) {
            case GoalType.DISTANCE_METERS:
                this.amount = metersToMiles(presentingDistance);
                this.presentingDistance = presentingDistance;
                break;
            case GoalType.DISTANCE_MILES:
                this.amount = presentingDistance;
                this.presentingDistance = presentingDistance;
                break;
            case GoalType.TIME_MINUTES:
                this.amount = presentingTime * 60;
                this.presentingTime = presentingTime;
                break;
            case GoalType.TIME_HOURS:
                this.amount = presentingTime * 60 * 60;
                this.presentingTime = presentingTime;
                break;
            case GoalType.CALORIES:
                this.amount = presentingCalories;
                this.presentingCalories = presentingCalories;
                break;
            case GoalType.STEPS:
                this.amount = presentingSteps;
                this.presentingSteps = presentingSteps;
                break;
            case GoalType.JUMPS:
                this.amount = presentingJumps;
                this.presentingJumps = presentingJumps;
                break;
            default:
                break;
        }

        this.type = type;
        this.isAutoGoal = isAutoGoal;
        this.options = options;

        // ignore accumulated amounts
        this.completedDistance = activityState?.totalDistance || 0;
        this.completedTime = activityState?.totalTime || 0;
        this.completedCalories = activityState?.totalEnergy || 0;
        this.completedSteps = activityState?.totalStepCount || 0;
        this.completedJumps = activityState?.totalJumpCount || 0;

        // analytics props

        this.goalSeqNum = goalSeqNum;

        this.analyticsProps = () => {
            const value = () => {
                switch (this.type) {
                    case GoalType.DISTANCE_METERS:
                        return this.presentingDistance;
                    case GoalType.DISTANCE_MILES:
                        return this.presentingDistance;
                    case GoalType.TIME_MINUTES:
                        return this.presentingTime;
                    case GoalType.TIME_HOURS:
                        return this.presentingTime;
                    case GoalType.CALORIES:
                        return this.presentingCalories;
                    case GoalType.STEPS:
                        return this.presentingSteps;
                    case GoalType.JUMPS:
                        return this.presentingJumps;
                    default:
                        return undefined;
                }
            };
            const unit = () => {
                switch (this.type) {
                    case GoalType.DISTANCE_METERS:
                        return 'meters';
                    case GoalType.DISTANCE_MILES:
                        return 'miles';
                    case GoalType.TIME_MINUTES:
                        return 'minutes';
                    case GoalType.TIME_HOURS:
                        return 'hours';
                    case GoalType.CALORIES:
                        return 'kcal';
                    case GoalType.STEPS:
                        return 'steps';
                    case GoalType.JUMPS:
                        return 'jumps';
                    default:
                        return 'undefined';
                }
            };
            return {
                goal_type: this.type,
                goal_target_value: String(value()),
                goal_unit: unit(),
                goal_seq_num: this.goalSeqNum,
                goal_selected: this.isAutoGoal ? 'quick_start' : 'set goal',
            };
        };

        // functions

        this.progress = (activityState) => {
            switch (this.type) {
                case GoalType.DISTANCE_METERS:
                case GoalType.DISTANCE_MILES:
                    return Math.min(Math.abs(activityState.totalDistance - this.completedDistance) / this.amount, 1.0);

                case GoalType.TIME_HOURS:
                case GoalType.TIME_MINUTES:
                    return Math.min(Math.abs(activityState.totalTime - this.completedTime) / this.amount, 1.0);

                case GoalType.CALORIES:
                    return Math.min(Math.abs(activityState.totalEnergy - this.completedCalories) / this.amount, 1.0);

                case GoalType.STEPS:
                    return Math.min(Math.abs(activityState.totalStepCount - this.completedSteps) / this.amount, 1.0);

                case GoalType.JUMPS:
                    return Math.min(Math.abs(activityState.totalJumpCount - this.completedJumps) / this.amount, 1.0);

                default:
                    return 0;
            }
        };

        this.unitString = () => {
            switch (this.type) {
                case GoalType.DISTANCE_METERS:
                    return 'meters';
                case GoalType.DISTANCE_MILES:
                    return 'miles';
                case GoalType.TIME_HOURS:
                    return 'hours';
                case GoalType.TIME_MINUTES:
                    return 'mins';
                case GoalType.CALORIES:
                    return 'kcal';
                case GoalType.STEPS:
                    return 'steps';
                case GoalType.JUMPS:
                    return 'jumps';
                default:
                    return {};
            }
        };

        this.presentingAmountString = () => {
            switch (this.type) {
                case GoalType.DISTANCE_METERS:
                    return Intl.NumberFormat('en-US', {
                        maximumFractionDigits: 0,
                    }).format(this.presentingDistance);
                case GoalType.DISTANCE_MILES:
                    return Intl.NumberFormat('en-US', {
                        maximumFractionDigits: 0,
                    }).format(this.presentingDistance);

                case GoalType.TIME_HOURS:
                    return Intl.NumberFormat('en-US', {
                        maximumFractionDigits: 0,
                    }).format(this.presentingTime);

                case GoalType.TIME_MINUTES:
                    return Intl.NumberFormat('en-US', {
                        maximumFractionDigits: 0,
                    }).format(this.presentingTime);

                case GoalType.CALORIES:
                    return Intl.NumberFormat('en-US', {
                        maximumFractionDigits: 0,
                    }).format(this.presentingCalories);

                case GoalType.STEPS:
                    return Intl.NumberFormat('en-US', {
                        maximumFractionDigits: 0,
                    }).format(this.presentingSteps);

                case GoalType.JUMPS:
                    return Intl.NumberFormat('en-US', {
                        maximumFractionDigits: 0,
                    }).format(this.presentingJumps);

                default:
                    return '';
            }
        };

        this.nextExponentialGoal = (activityState) => {
            if (this.type !== GoalType.DISTANCE_METERS) {
                return null;
            }
            const idx = GoalSetting.exponentialGoalIndex(this.amount);
            if (idx === -1 || idx + 1 >= GoalSetting.exponentialSettings.length) {
                return null;
            }
            return new GoalSetting({
                type: GoalType.DISTANCE_METERS,
                presentingDistance: GoalSetting.exponentialSettings[idx + 1],
                isAutoGoal: true,
                activityState,
            });
        };

        this.nextLinearGoal = () => {
            switch (this.type) {
                case GoalType.DISTANCE_METERS:
                    // from exponential goals to linear goals
                    return new GoalSetting({
                        type: GoalType.DISTANCE_MILES,
                        presentingDistance: GoalSetting.linearSettings.start,
                        isAutoGoal: true,
                        activityState,
                    });
                case GoalType.DISTANCE_MILES:
                    return new GoalSetting({
                        type: GoalType.DISTANCE_MILES,
                        presentingDistance: this.amount + GoalSetting.linearSettings.increment,
                        isAutoGoal: true,
                        activityState,
                    });
                case GoalType.JUMPS:
                    return new GoalSetting({
                        type: GoalType.JUMPS,
                        presentingJumps: this.amount + GoalSetting.jumpRopeLinearSettings.increment,
                        isAutoGoal: true,
                        activityState,
                    });
                default:
                    return null;
            }
        };

        this.changeGoal = (activityState) => {
            var newGoal = {};
            if (this.isAutoGoal) {
                newGoal = this.nextExponentialGoal(activityState) || this.nextLinearGoal(activityState);
            } else {
                // no further goal
                newGoal = this;
            }
            newGoal.goalSeqNum = this.goalSeqNum + 1;
            return newGoal;
        };

        this.selectCellString = () => {
            switch (this.type) {
                case GoalType.DISTANCE_METERS:
                    return `${this.presentingDistance.toLocaleString()} M`;
                case GoalType.DISTANCE_MILES:
                    return `${this.presentingDistance.toLocaleString()} MI`;
                case GoalType.TIME_HOURS:
                    return `${this.presentingTime} HR`;
                case GoalType.TIME_MINUTES:
                    return `${this.presentingTime} MIN`;
                case GoalType.CALORIES:
                    return '';
                case GoalType.STEPS:
                    return `${this.presentingSteps}`;
                case GoalType.JUMPS:
                    return `${this.presentingJumps}`;
                default:
                    return '';
            }
        };

        this.caloriesIcon = () => {
            if (this.type === GoalType.CALORIES) {
                return options?.caloriesIcon;
            } else {
                return undefined;
            }
        };
    }

    static generateFirstAutoGoal(activityId) {
        switch (activityId) {
            case ActivityId.JumpRope:
            case ActivityId.JumpingJack:
                return new GoalSetting({
                    type: GoalType.JUMPS,
                    presentingJumps: this.jumpRopeLinearSettings.start,
                    isAutoGoal: true,
                });
            default:
                return new GoalSetting({
                    type: GoalType.DISTANCE_METERS,
                    presentingDistance: this.exponentialSettings[0],
                    isAutoGoal: true,
                });
        }
    }
}

export const PresetGoals = {
    TIME: [
        new GoalSetting({ type: GoalType.TIME_MINUTES, presentingTime: 1, isAutoGoal: false }),
        new GoalSetting({ type: GoalType.TIME_MINUTES, presentingTime: 3, isAutoGoal: false }),
        new GoalSetting({ type: GoalType.TIME_MINUTES, presentingTime: 5, isAutoGoal: false }),
        new GoalSetting({ type: GoalType.TIME_MINUTES, presentingTime: 7, isAutoGoal: false }),
        new GoalSetting({ type: GoalType.TIME_MINUTES, presentingTime: 10, isAutoGoal: false }),
        new GoalSetting({ type: GoalType.TIME_MINUTES, presentingTime: 15, isAutoGoal: false }),
        new GoalSetting({ type: GoalType.TIME_MINUTES, presentingTime: 20, isAutoGoal: false }),
        new GoalSetting({ type: GoalType.TIME_MINUTES, presentingTime: 30, isAutoGoal: false }),
        new GoalSetting({ type: GoalType.TIME_MINUTES, presentingTime: 45, isAutoGoal: false }),
        new GoalSetting({ type: GoalType.TIME_HOURS, presentingTime: 1, isAutoGoal: false }),
    ],
    STEPS: [
        new GoalSetting({ type: GoalType.STEPS, presentingSteps: 50, isAutoGoal: false }),
        new GoalSetting({ type: GoalType.STEPS, presentingSteps: 100, isAutoGoal: false }),
        new GoalSetting({ type: GoalType.STEPS, presentingSteps: 200, isAutoGoal: false }),
        new GoalSetting({ type: GoalType.STEPS, presentingSteps: 300, isAutoGoal: false }),
        new GoalSetting({ type: GoalType.STEPS, presentingSteps: 500, isAutoGoal: false }),
        new GoalSetting({ type: GoalType.STEPS, presentingSteps: 700, isAutoGoal: false }),
        new GoalSetting({ type: GoalType.STEPS, presentingSteps: 1000, isAutoGoal: false }),
        new GoalSetting({ type: GoalType.STEPS, presentingSteps: 1500, isAutoGoal: false }),
        new GoalSetting({ type: GoalType.STEPS, presentingSteps: 2000, isAutoGoal: false }),
        new GoalSetting({ type: GoalType.STEPS, presentingSteps: 2500, isAutoGoal: false }),
    ],
    JUMPS: [
        new GoalSetting({ type: GoalType.JUMPS, presentingJumps: 50, isAutoGoal: false }),
        new GoalSetting({ type: GoalType.JUMPS, presentingJumps: 100, isAutoGoal: false }),
        new GoalSetting({ type: GoalType.JUMPS, presentingJumps: 200, isAutoGoal: false }),
        new GoalSetting({ type: GoalType.JUMPS, presentingJumps: 300, isAutoGoal: false }),
        new GoalSetting({ type: GoalType.JUMPS, presentingJumps: 500, isAutoGoal: false }),
        new GoalSetting({ type: GoalType.JUMPS, presentingJumps: 700, isAutoGoal: false }),
        new GoalSetting({ type: GoalType.JUMPS, presentingJumps: 1000, isAutoGoal: false }),
        new GoalSetting({ type: GoalType.JUMPS, presentingJumps: 1500, isAutoGoal: false }),
        new GoalSetting({ type: GoalType.JUMPS, presentingJumps: 2000, isAutoGoal: false }),
        new GoalSetting({ type: GoalType.JUMPS, presentingJumps: 2500, isAutoGoal: false }),
    ],
    DISTANCE: [
        new GoalSetting({ type: GoalType.DISTANCE_METERS, presentingDistance: 100, isAutoGoal: false }),
        new GoalSetting({ type: GoalType.DISTANCE_METERS, presentingDistance: 200, isAutoGoal: false }),
        new GoalSetting({ type: GoalType.DISTANCE_METERS, presentingDistance: 400, isAutoGoal: false }),
        new GoalSetting({ type: GoalType.DISTANCE_METERS, presentingDistance: 500, isAutoGoal: false }),
        new GoalSetting({ type: GoalType.DISTANCE_METERS, presentingDistance: 1200, isAutoGoal: false }),
        new GoalSetting({ type: GoalType.DISTANCE_MILES, presentingDistance: 1, isAutoGoal: false }),
        new GoalSetting({ type: GoalType.DISTANCE_METERS, presentingDistance: 3000, isAutoGoal: false }),
        new GoalSetting({ type: GoalType.DISTANCE_MILES, presentingDistance: 2, isAutoGoal: false }),
        new GoalSetting({ type: GoalType.DISTANCE_MILES, presentingDistance: 3, isAutoGoal: false }),
        new GoalSetting({ type: GoalType.DISTANCE_METERS, presentingDistance: 5000, isAutoGoal: false }),
    ],
    CALORIES: [
        new GoalSetting({
            type: GoalType.CALORIES,
            presentingCalories: 45,
            isAutoGoal: false,
            options: { caloriesIcon: 'strawberry' },
        }),
        new GoalSetting({
            type: GoalType.CALORIES,
            presentingCalories: 81,
            isAutoGoal: false,
            options: { caloriesIcon: 'apple' },
        }),
        new GoalSetting({
            type: GoalType.CALORIES,
            presentingCalories: 105,
            isAutoGoal: false,
            options: { caloriesIcon: 'banana' },
        }),
        new GoalSetting({
            type: GoalType.CALORIES,
            presentingCalories: 114,
            isAutoGoal: false,
            options: { caloriesIcon: 'grape' },
        }),
        new GoalSetting({
            type: GoalType.CALORIES,
            presentingCalories: 119,
            isAutoGoal: false,
            options: { caloriesIcon: 'hotdog' },
        }),
        new GoalSetting({
            type: GoalType.CALORIES,
            presentingCalories: 290,
            isAutoGoal: false,
            options: { caloriesIcon: 'pizza' },
        }),
    ],
};
