import { useReducer } from 'react';
import { ActivityId } from '../ActivityId';
import { SidebarSelection } from './SideBar';
import TimeRangeFilterType from './TimeRangeFilterType';

const ActionType = {
    SHOW_HOME: 'SHOW_HOME',
    SHOW_GROUP_LIST: 'SHOW_GROUP_LIST',
    SHOW_MEMBER_LIST: 'SHOW_MEMBER_LIST',
    SHOW_HISTORY: 'SHOW_HISTORY',
    DISMISS_MEMBER_LIST: 'DISMISS_MEMBER_LIST',

    // Balloon control
    SHOW_NEW_GROUP_BALLOON: 'SHOW_NEW_GROUP_BALLOON',
    DISMISS_NEW_GROUP_BALLOON: 'DISMISS_NEW_GROUP_BALLOON',
    SHOW_JOIN_GROUP_BALLOON: 'SHOW_JOIN_GROUP_BALLOON',
    DISMISS_JOIN_GROUP_BALLOON: 'DISMISS_JOIN_GROUP_BALLOON',

    // after adding new group list
    REFRESH_GROUP_LIST: 'REFRESH_GROUP_LIST',
    REFRESH_GROUP_LIST_DONE: 'REFRESH_GROUP_LIST_DONE',
    PIN_NEW_GROUP: 'PIN_NEW_GROUP',

    // select team to start
    SELECT_TEAM_TO_START: 'SELECT_TEAM_TO_START',

    // notifications
    SET_NOTIFICATION: 'SET_NOTIFICATION',
    DISMISS_NOTIFICATION: 'DISMISS_NOTIFICATION',

    // loading spinner
    SHOW_SPINNER: 'SHOW_SPINNER',
    DISMISS_SPINNER: 'DISMISS_SPINNER',
    SELECT_GROUP_TIME_FILTER: 'SELECT_GROUP_TIME_FILTER',

    // background music
    START_BACKGROUND_MUSIC: 'START_BACKGROUND_MUSIC',
    STOP_BACKGROUND_MUSIC: 'STOP_BACKGROUND_MUSIC',

    // Sound setting
    TOGGLE_SOUND: 'TOGGLE_SOUND',

    // Set preset goal
    SET_PRESET_GOAL: 'SET_PRESET_GOAL',
    REMOVE_PRESET_GOAL: 'REMOVE_PRESET_GOAL',

    // Select activity
    SELECT_ACTIVITY: 'SELECT_ACTIVITY',
};

export const PageControllerAction = {
    SHOW_HOME: { type: ActionType.SHOW_HOME },
    SHOW_GROUP_LIST: { type: ActionType.SHOW_GROUP_LIST },
    SHOW_HISTORY: { type: ActionType.SHOW_HISTORY },
    SHOW_MEMBER_LIST: (gp) => {
        return { type: ActionType.SHOW_MEMBER_LIST, param: gp };
    },
    DISMISS_MEMBER_LIST: { type: ActionType.DISMISS_MEMBER_LIST },
    // Balloon control
    SHOW_NEW_GROUP_BALLOON: (gid) => {
        return { type: ActionType.SHOW_NEW_GROUP_BALLOON, param: gid };
    },
    DISMISS_NEW_GROUP_BALLOON: { type: ActionType.DISMISS_NEW_GROUP_BALLOON },
    SHOW_JOIN_GROUP_BALLOON: { type: ActionType.SHOW_JOIN_GROUP_BALLOON },
    DISMISS_JOIN_GROUP_BALLOON: { type: ActionType.DISMISS_JOIN_GROUP_BALLOON },
    // after adding new group list
    REFRESH_GROUP_LIST: (callback) => {
        return { type: ActionType.REFRESH_GROUP_LIST, param: callback };
    },
    REFRESH_GROUP_LIST_DONE: { type: ActionType.REFRESH_GROUP_LIST_DONE },
    PIN_NEW_GROUP: (gp) => {
        return { type: ActionType.PIN_NEW_GROUP, param: gp };
    },
    // team selection
    SELECT_TEAM_TO_START: (group) => {
        return { type: ActionType.SELECT_TEAM_TO_START, param: group };
    },
    SET_NOTIFICATION: (notif) => {
        return { type: ActionType.SET_NOTIFICATION, param: notif };
    },
    DISMISS_NOTIFICATION: { type: ActionType.DISMISS_NOTIFICATION },
    SHOW_SPINNER: { type: ActionType.SHOW_SPINNER },
    DISMISS_SPINNER: { type: ActionType.DISMISS_SPINNER },
    SELECT_GROUP_TIME_FILTER: (filterType) => {
        return { type: ActionType.SELECT_GROUP_TIME_FILTER, param: filterType };
    },
    START_BACKGROUND_MUSIC: (music) => {
        return { type: ActionType.START_BACKGROUND_MUSIC, param: music };
    },
    STOP_BACKGROUND_MUSIC: { type: ActionType.STOP_BACKGROUND_MUSIC },
    TOGGLE_SOUND: { type: ActionType.TOGGLE_SOUND },

    SET_PRESET_GOAL: (goal) => {
        return { type: ActionType.SET_PRESET_GOAL, param: goal };
    },
    REMOVE_PRESET_GOAL: { type: ActionType.REMOVE_PRESET_GOAL },
    SELECT_ACTIVITY: (activityId) => {
        return { type: ActionType.SELECT_ACTIVITY, param: activityId };
    },
};

function reducer(state, action) {
    // Uncomment to debug
    // console.log('state', state);
    // console.log('action', action);
    switch (action.type) {
        case ActionType.SHOW_HOME:
            return { ...state, sidebarSelection: SidebarSelection.HOME, newManagedGroup: null };

        case ActionType.SHOW_GROUP_LIST:
            return { ...state, sidebarSelection: SidebarSelection.GROUPS };

        case ActionType.SHOW_MEMBER_LIST:
            return { ...state, displayGroup: action.param };

        case ActionType.DISMISS_MEMBER_LIST:
            return { ...state, displayGroup: null };

        case ActionType.SHOW_HISTORY:
            return { ...state, sidebarSelection: SidebarSelection.HISTORY, newManagedGroup: null };

        case ActionType.SHOW_NEW_GROUP_BALLOON:
            return { ...state, sidebarSelection: SidebarSelection.GROUPS, newGroupBalloon: action.param };

        case ActionType.DISMISS_NEW_GROUP_BALLOON:
            return { ...state, newGroupBalloon: null };

        case ActionType.SHOW_JOIN_GROUP_BALLOON:
            return { ...state, joinGroupBalloon: true };

        case ActionType.DISMISS_JOIN_GROUP_BALLOON:
            return { ...state, joinGroupBalloon: false };

        case ActionType.REFRESH_GROUP_LIST:
            return { ...state, refreshGroupList: true, refreshGroupListCallback: action.param };

        case ActionType.REFRESH_GROUP_LIST_DONE:
            return { ...state, refreshGroupList: false, refreshGroupListCallback: null };

        case ActionType.PIN_NEW_GROUP:
            return { ...state, newManagedGroup: action.param };

        case ActionType.SELECT_TEAM_TO_START:
            return { ...state, selectedTeamToStart: action.param };

        case ActionType.SET_NOTIFICATION:
            return { ...state, notification: action.param };

        case ActionType.DISMISS_NOTIFICATION:
            return { ...state, notification: null };

        case ActionType.SHOW_SPINNER:
            return { ...state, displaySpinner: true };

        case ActionType.DISMISS_SPINNER:
            return { ...state, displaySpinner: false };

        case ActionType.SELECT_GROUP_TIME_FILTER:
            return { ...state, groupTableTimeFilter: action.param };

        case ActionType.START_BACKGROUND_MUSIC:
            return { ...state, backgroundMusic: action.param };

        case ActionType.STOP_BACKGROUND_MUSIC:
            return { ...state, backgroundMusic: null };

        case ActionType.TOGGLE_SOUND:
            toLocalStorage({ enabledSound: !state.enabledSound });
            return { ...state, enabledSound: !state.enabledSound };

        case ActionType.SET_PRESET_GOAL:
            return { ...state, presetGoal: action.param };

        case ActionType.REMOVE_PRESET_GOAL:
            return { ...state, presetGoal: null };

        case ActionType.SELECT_ACTIVITY:
            return { ...state, selectedActivityId: action.param };

        default:
            console.error('Cannot recognize action', action.type);
            return state;
    }
}

function toLocalStorage({ enabledSound }) {
    if (enabledSound !== undefined) {
        localStorage.setItem('enabledSound', `${enabledSound}`);
    }
}

function fromLocalStorage() {
    const enabledSound = () => {
        var val = localStorage.getItem('enabledSound');
        if (val === null) {
            localStorage.setItem('enabledSound', 'true');
        }
        val = localStorage.getItem('enabledSound') === 'true';
        return val;
    };
    return { enabledSound: enabledSound() };
}

export function PageController() {
    const settings = fromLocalStorage();

    const initialState = {
        // expected value: SidebarSelection.xxx
        sidebarSelection: SidebarSelection.HOME,

        // expected value: group object
        // to load and display the member list
        displayGroup: null,

        // expected value: group id
        // to highlight the newly-created managed group
        newGroupBalloon: null,

        // expected value: Boolean
        // to display joined group notification balloon
        joinGroupBalloon: false,

        // expected value: Boolean
        // default value = true
        // to refresh group list when page is loaded
        refreshGroupList: true,

        // expected value: function
        // set the callback function when group list is loaded
        // use case: display new group after receiving an invitation
        refreshGroupListCallback: null,

        // expected value: group object
        // to bring the newly-created group to the top of the list
        newManagedGroup: null,

        // expected value: DialogBoxType.xxx
        // to display which the dialog box
        dialogBox: null,

        // expected value: group object
        // to update the selected team in the top-right corner
        selectedTeamToStart: null,

        // expected value: NotificationType.xxx
        // to update the notification display
        notification: null,

        // expected value: boolean
        // to set the spinner when loading data
        displaySpinner: true,

        // expected value: TimeRangeFilterType
        // to update the select time filter in group list
        groupTableTimeFilter: TimeRangeFilterType.THIS_WEEK,

        // expected value: a ref to music asset,
        // as a hack, we use boolean: true
        backgroundMusic: null,

        // expected value: boolean
        enabledSound: settings.enabledSound,

        // expected value: GoalSetting from Goal.js
        presetGoal: null,

        // expected value: ActivityId from ActivityId.js
        selectedActivityId: ActivityId.TreadMill,
    };
    return useReducer(reducer, initialState);
}
