import { Step } from './Step';

export class StepCounter {
    /**
     * Creates an instance of StepCounter.
     * @memberof StepCounter
     */
    constructor() {
        this._curFrameTime = 0;
        this._lastThreshold = 0;

        this._state = 0; // 0: none, 1: +, 2: -
        this._state1StartTime = 0;
        this._state2StartTime = 0;
        this._upAmplitude = 0;
        this._downAmplitude = 0;
        this._curSegDiff = 0;
    }

    /**
     * Add a new diff to the counter and try to detect step.
     *
     * @param {number} valDiff
     * @param {number} frameTime
     * @returns {Step} return a step if one is detected, and null otherwise.
     * @memberof StepCounter
     */
    addNewDiff(valDiff, frameTime, pixelPerInch) {
        this._curFrameTime = frameTime;

        let shouldStartNewSeg = false;

        let step = null;

        switch (this._state) {
            case 0: // None
                if (valDiff < 0) {
                    // Start going up

                    this._state = 1;
                    this._state1StartTime = frameTime;
                    shouldStartNewSeg = true;
                }
                break;
            case 1: // Going up
                if (valDiff > 0) {
                    // On top, Start going down

                    this._state = 2;
                    this._state2StartTime = frameTime;
                    this._upAmplitude = Math.abs(this._curSegDiff);

                    shouldStartNewSeg = true;
                }
                break;
            case 2: // Going down
                if (valDiff < 0) {
                    // On bottom, start going up again

                    this._downAmplitude = Math.abs(this._curSegDiff);

                    step = new Step(
                        this._curFrameTime,
                        this._upAmplitude,
                        this._downAmplitude,
                        this._state2StartTime - this._state1StartTime,
                        this._curFrameTime - this._state2StartTime,
                        pixelPerInch
                    );

                    this._state = 1;
                    this._state1StartTime = frameTime;

                    shouldStartNewSeg = true;
                }
                break;
            default:
                break;
        }

        if (shouldStartNewSeg) {
            this._curSegDiff = 0;
        } else {
            this._curSegDiff += valDiff;
        }

        return step;
    }
}
