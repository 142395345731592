import { PlayerPose } from '../pose/PlayerPose';
import { SignalHistory } from './SignalHistory';
import { StepCounter } from './StepCounter';
import { Step, popOutdatedSteps } from './Step';
import {
    kChestNodeIndex,
    kLeftShoulderNodeIndex,
    kRightShoulderNodeIndex,
    kNoseNodeIndex,
    kLeftEyeNodeIndex,
    kRightEyeNodeIndex,
    kLeftEarNodeIndex,
    kRightEarNodeIndex,
    kLeftWristNodeIndex,
    kRightWristNodeIndex,
    Pose,
    numPoseNodes,
    kPoseNodeDetected,
} from '../detectors/Pose';
import { pickCenterPose } from '../pose/PoseUtils';
import { getSetting } from '../utils/Settings';

const kDefaultHistoryLength = 3.0; // TODO: sync implementation with C++

const kStepThresholdInInches = 0.5;
const kSmoothTimeWindow = 0.2;
const kSmoothTimeSigma = 0.01;
const kMaxStepSegDuration = 0.5;
const kMinStepSegDuration = 0.05;
const kTimeWindowForSpeedMeasurements = 4;

const kArmStepTotalDiffThresholdInInches = 3;
const kMaxArmStepTotalDuration = 1.0;
const kMinArmStepTotalDuration = 0.03;
const kTimeWindowForArmStepsSpeed = 2;
const kMinArmStepsPerSecondToTriggerLooseCheck = 3.5;

// ok step
const kOkStepTotalDiffThresholdInInches = 1;
const kMaxOkStepTotalDuration = 1.0;
const kMinOkStepTotalDuration = 0.03;
const kTimeWindowForOkStepsSpeed = 4;
const kMinOkStepsPerSecondToTriggerLooseCheck = 0.5; // At least it has a little bit ok step we will consider it's not still.

const kNodeIndexesForStepCounting = [
    kChestNodeIndex,
    kLeftShoulderNodeIndex,
    kRightShoulderNodeIndex,
    kNoseNodeIndex,
    kLeftEyeNodeIndex,
    kRightEyeNodeIndex,
    kLeftEarNodeIndex,
    kRightEarNodeIndex,
];

const kNodeIndexesForArmStepCounting = [kLeftWristNodeIndex, kRightWristNodeIndex];

const kNodeIndexesForPoseQualification = [
    kChestNodeIndex,
    kNoseNodeIndex,
    kLeftShoulderNodeIndex,
    kRightShoulderNodeIndex,
];

/**
 * Returns true if the given pose is suitable for running detection.
 *
 * @export
 * @param {Pose} pose
 * @returns
 */
export function isPoseQualifiedForRunningDetection(pose) {
    if (pose === null || pose === undefined) {
        return false;
    }
    for (const nodeIndex of kNodeIndexesForPoseQualification) {
        if (pose.nodes[nodeIndex].validLevel !== kPoseNodeDetected) {
            return false;
        }
    }
    return true;
}

export class RunningDetector {
    constructor() {
        /** @type {number} */
        this._frameHeight = 0;

        /** @type {number} */
        this._frameWidth = 0;

        /** @type {number} */
        this._curFrameTime = 0;

        /** @type {number} */
        this._prevFrameTime = 0;

        // Pose Detection

        /** @type {PlayerPose} */
        this._lastPlayerPose = new PlayerPose();

        /** @type {boolean} */
        this._isLastPoseAnalyzed = false;

        // Signal History

        /** @type {SignalHistory[]} */
        this._yHistoryList = [];
        for (let i = 0; i < numPoseNodes; i++) {
            this._yHistoryList.push(new SignalHistory(kDefaultHistoryLength));
        }

        /** @type {SignalHistory[]} */
        this._smoothedYHistoryList = [];
        for (let i = 0; i < numPoseNodes; i++) {
            this._smoothedYHistoryList.push(new SignalHistory(kDefaultHistoryLength));
        }

        /** @type {SignalHistory} */
        this._ppiHistory = new SignalHistory(kDefaultHistoryLength);

        // Step Counting

        /** @type {StepCounter} */
        this._stepCounter = new StepCounter();

        /** @type {number} */
        this._stepCount = 0;

        /** @type {Step[]} */
        this._countedSteps = [];

        /** @type {number} */
        this._curStepsPerSecond = 0;

        /** @type {number} */
        this._smoothedStepsPerSecond = 0;

        /** @type {number} */
        this._smoothedMeterPerSecond = 0;

        // Arm Step Counting

        /** @type {StepCounter} */
        this._leftArmStepCounter = new StepCounter();

        /** @type {StepCounter} */
        this._rightArmStepCounter = new StepCounter();

        /** @type {Step[]} */
        this._armSteps = [];

        /** @type {number} */
        this._armStepCount = 0;

        /** @type {number} */
        this._curArmStepsPerSecond = 0;

        // Stats

        /** @type {number} */
        this._totalDistance = 0;

        // Ok Steps

        /** @type {Step[]} */
        this._okSteps = [];

        /** @type {number} */
        this._curOkStepsPerSecond = 0;

        /* for auto step dev config */
        this._lastSecond = undefined;
        this._autoStep = !!getSetting('dev.autoStep');
    }

    /**
     * Sets some basic configuration. Need to be called before tick.
     *
     * @param {number} frameWidth
     * @param {number} frameHeight
     * @memberof RunningDetector
     */
    config(frameWidth, frameHeight) {
        this._frameWidth = frameWidth;
        this._frameHeight = frameHeight;
    }

    /**
     * Gets smoothedStepsPerSecond
     *
     * @returns {number}
     * @memberof RunningDetector
     */
    smoothedStepsPerSecond() {
        return this._smoothedStepsPerSecond;
    }

    /**
     * Gets smoothedMeterPerSecond
     *
     * @returns {number}
     * @memberof RunningDetector
     */
    smoothedMeterPerSecond() {
        return this._smoothedMeterPerSecond;
    }

    /**
     * Gets stepCount
     *
     * @returns {number}
     * @memberof RunningDetector
     */
    stepCount() {
        return this._stepCount;
    }

    /**
     * Gets totalDistance
     *
     * @returns {number}
     * @memberof RunningDetector
     */
    totalDistance() {
        return this._totalDistance;
    }

    /* for auto step dev config */
    frameSecond(now) {
        const curSecond = parseInt(now);
        if (this._lastSecond === undefined) {
            return curSecond;
        } else if (curSecond !== this._lastSecond) {
            return curSecond;
        } else {
            return this._lastSecond;
        }
    }

    /**
     * Compute values based on current time and pose data.
     *
     * @param {number} frameTime
     * @memberof RunningDetector
     */
    tick(frameTime) {
        const curSecond = this.frameSecond(frameTime);
        if (this._autoStep && curSecond !== this._lastSecond) {
            this._stepCount += 1;
            this._totalDistance += 1;
        }
        this._lastSecond = curSecond;

        // Time
        this._prevFrameTime = this._curFrameTime;
        this._curFrameTime = frameTime;
        for (const history of this._yHistoryList) {
            history.updateCurrentFrameTime(frameTime);
        }
        for (const history of this._smoothedYHistoryList) {
            history.updateCurrentFrameTime(frameTime);
        }
        this._ppiHistory.updateCurrentFrameTime(frameTime);

        // Add values to history
        const isLastPoseQualified = isPoseQualifiedForRunningDetection(this._lastPlayerPose.pose);
        if (!this._isLastPoseAnalyzed && this._lastPlayerPose.detected() && isLastPoseQualified) {
            // Update history
            const frameTime = this._lastPlayerPose.frameTime;
            for (let nodeIndex = 0; nodeIndex < numPoseNodes; nodeIndex++) {
                this._yHistoryList[nodeIndex].addPoseNodeY(this._lastPlayerPose.pose.nodes[nodeIndex], frameTime);
            }
            this._ppiHistory.addNewVal(this._lastPlayerPose.pose.pixelsPerInch, frameTime);
            this._isLastPoseAnalyzed = true;
        }

        // RefFrameTime is the current time considering the smoothing window delay.
        const refFrameTime = this._curFrameTime - kSmoothTimeWindow * 0.5;
        const prevRefFrameTime = this._prevFrameTime - kSmoothTimeWindow * 0.5;

        const pixelsPerInch = this._ppiHistory.avgValue();
        const tempFixedPixelPerInch = Math.max(pixelsPerInch, (this._frameHeight * 8.0) / 960.0);

        // Smooth and interpolate signal
        for (let nodeIndex = 0; nodeIndex < numPoseNodes; nodeIndex++) {
            const smoothedY = this._yHistoryList[nodeIndex].getSmoothedHeadVal(kSmoothTimeWindow, kSmoothTimeSigma);
            if (smoothedY !== null) {
                this._smoothedYHistoryList[nodeIndex].addNewVal(smoothedY, refFrameTime);
            }
        }

        // Arm Step Counting
        let wristNodeIndexes = [kLeftWristNodeIndex, kRightWristNodeIndex];
        let armStepCounters = [this._leftArmStepCounter, this._rightArmStepCounter];
        for (let i = 0; i < wristNodeIndexes.length; i++) {
            const wristNodeIndex = wristNodeIndexes[i];
            const diff = this._smoothedYHistoryList[wristNodeIndex].getValDiff(prevRefFrameTime, refFrameTime);

            if (diff !== null) {
                const step = armStepCounters[i].addNewDiff(diff, refFrameTime, tempFixedPixelPerInch);

                if (step !== null) {
                    const totalDiffThreshold = tempFixedPixelPerInch * kArmStepTotalDiffThresholdInInches;
                    const isLessStrict = step.isLessStrict(
                        totalDiffThreshold,
                        kMinArmStepTotalDuration,
                        kMaxArmStepTotalDuration
                    );

                    if (isLessStrict) {
                        this._armStepCount += 1;
                        this._armSteps.unshift(step);
                    }
                }
            }
        }

        // Analyze diff for step
        let diffs = [];
        for (const nodeIndex of kNodeIndexesForStepCounting) {
            const valDiff = this._smoothedYHistoryList[nodeIndex].getValDiff(prevRefFrameTime, refFrameTime);
            if (valDiff !== null) {
                diffs.push(valDiff);
            }
        }

        if (diffs.length > 0) {
            const sumDiff = diffs.reduce((a, b) => a + b, 0);
            const avgDiff = sumDiff / diffs.length;

            const step = this._stepCounter.addNewDiff(avgDiff, refFrameTime, tempFixedPixelPerInch);

            const shouldUseLooseCheck =
                this._curArmStepsPerSecond >= kMinArmStepsPerSecondToTriggerLooseCheck - 1e-9 &&
                this._curOkStepsPerSecond >= kMinOkStepsPerSecondToTriggerLooseCheck - 1e-9;

            if (step !== null) {
                const segDiffThreshold = tempFixedPixelPerInch * kStepThresholdInInches;
                const shouldCount =
                    shouldUseLooseCheck || step.isStrict(segDiffThreshold, kMinStepSegDuration, kMaxStepSegDuration);

                if (shouldCount) {
                    this._stepCount += 1;
                    this._countedSteps.unshift(step);
                    this._totalDistance += step.distance(tempFixedPixelPerInch);
                }

                // Update ok steps
                const okStepTotalDiffThreshold = tempFixedPixelPerInch * kOkStepTotalDiffThresholdInInches;
                if (step.isLessStrict(okStepTotalDiffThreshold, kMinOkStepTotalDuration, kMaxOkStepTotalDuration)) {
                    this._okSteps.unshift(step);
                }
            }
        }

        // Clean up steps
        popOutdatedSteps(this._countedSteps, frameTime, kTimeWindowForSpeedMeasurements);
        popOutdatedSteps(this._armSteps, frameTime, kTimeWindowForArmStepsSpeed);
        popOutdatedSteps(this._okSteps, frameTime, kTimeWindowForOkStepsSpeed);

        this._curStepsPerSecond = this._countedSteps.length / kTimeWindowForSpeedMeasurements;
        this._curArmStepsPerSecond = this._armSteps.length / kTimeWindowForArmStepsSpeed;
        this._curOkStepsPerSecond = this._okSteps.length / kTimeWindowForOkStepsSpeed;

        // Compute smoothed cadence/speed
        const windowCenterFrameTime = this._curFrameTime - 0.5 * kTimeWindowForSpeedMeasurements;
        let weightedCadenceSum = 0;
        let weightedDistanceSum = 0;
        for (const step of this._countedSteps) {
            const weight =
                1.0 - Math.abs(step.frameTime - windowCenterFrameTime) / (0.5 * kTimeWindowForSpeedMeasurements);
            weightedCadenceSum += weight;
            weightedDistanceSum += weight * step.distance();
        }
        this._smoothedStepsPerSecond = weightedCadenceSum / (0.5 * kTimeWindowForSpeedMeasurements);
        this._smoothedMeterPerSecond = weightedDistanceSum / (0.5 * kTimeWindowForSpeedMeasurements);
    }

    /**
     * Accepts pose detection input.
     *
     * @param {Pose[]} poses
     * @param {number} frameTime
     * @memberof RunningDetector
     */
    handleDetection(poses, frameTime) {
        const pose = this.pickPose(poses);

        this._lastPlayerPose = new PlayerPose(pose, frameTime);
        this._isLastPoseAnalyzed = false;
    }

    /**
     * Picks a best pose among all detected poses.
     *
     * @param {Pose[]} poses
     * @returns
     * @memberof RunningDetector
     */
    pickPose(poses) {
        return pickCenterPose(poses, this._frameWidth);
    }
}
