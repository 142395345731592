import { getSetting } from './utils/Settings';

function defaultActivityId() {
    return getSetting('dev.enableJumpingJack') ? ActivityId.JumpingJack : ActivityId.TreadMill;
}

export const ActivityId = {
    TreadMill: 'TreadMill',
    JumpingJack: 'JumpingJack',
    JumpRope: 'JumpRope',
};

export function resolveActivityId({ activityId }) {
    if (ActivityId.hasOwnProperty(activityId)) {
        return ActivityId[activityId];
    } else {
        return defaultActivityId();
    }
}

export function getActivityName({ activityId }) {
    switch (activityId) {
        case ActivityId.TreadMill:
            return 'Jog In Place';
        case ActivityId.JumpingJack:
            return 'Jumping Jack';
        case ActivityId.JumpRope:
            return 'Jump Rope';
        default:
            return undefined;
    }
}

export function getAnalyticsName({ activityId }) {
    switch (activityId) {
        case ActivityId.TreadMill:
            return 'jogging';
        case ActivityId.JumpRope:
            return 'jump_rope';
        case ActivityId.JumpingJack:
            return 'jumping_jack';
        default:
            return undefined;
    }
}
