import { useCallback, useEffect, useMemo, useState } from 'react';
import useSound from 'use-sound';
import bgm001 from '../audio/bgm/bgm-001.mp3';
import bgm002 from '../audio/bgm/bgm-002.mp3';
import bgm003 from '../audio/bgm/bgm-003.mp3';
import bgm004 from '../audio/bgm/bgm-004.mp3';
import bgm005 from '../audio/bgm/bgm-005.mp3';
import bgm006 from '../audio/bgm/bgm-006.mp3';
import { getSetting } from '../utils/Settings';

const volume = 0.75;
const enableBGMSetting = getSetting('dev.enableBGM');

export function useBgm() {
    const [play001, { stop: stop001, pause: pause001, isPlaying: isPlaying001 }] = useSound(bgm001, {
        html5: true,
        volume,
    });
    const [play002, { stop: stop002, pause: pause002, isPlaying: isPlaying002 }] = useSound(bgm002, {
        html5: true,
        volume,
    });
    const [play003, { stop: stop003, pause: pause003, isPlaying: isPlaying003 }] = useSound(bgm003, {
        html5: true,
        volume,
    });
    const [play004, { stop: stop004, pause: pause004, isPlaying: isPlaying004 }] = useSound(bgm004, {
        html5: true,
        volume,
    });
    const [play005, { stop: stop005, pause: pause005, isPlaying: isPlaying005 }] = useSound(bgm005, {
        html5: true,
        volume,
    });
    const [play006, { stop: stop006, pause: pause006, isPlaying: isPlaying006 }] = useSound(bgm006, {
        html5: true,
        volume,
    });

    const [trackId, setTrackId] = useState(null);

    const play = useCallback(
        (_id) => {
            switch (_id) {
                case 1:
                    return play001();
                case 2:
                    return play002();
                case 3:
                    return play003();
                case 4:
                    return play004();
                case 5:
                    return play005();
                case 6:
                    return play006();
                default:
                    return;
            }
        },
        [play001, play002, play003, play004, play005, play006]
    );

    const genId = () => {
        return Math.floor(Math.random() * 6 + 1);
    };

    const stop = useCallback(() => {
        stop001();
        stop002();
        stop003();
        stop004();
        stop005();
        stop006();
        setTrackId(null);
    }, [stop001, stop002, stop003, stop004, stop005, stop006]);

    const isPlayingBgm = useMemo(() => {
        switch (trackId) {
            case 1:
                return isPlaying001;
            case 2:
                return isPlaying002;
            case 3:
                return isPlaying003;
            case 4:
                return isPlaying004;
            case 5:
                return isPlaying005;
            case 6:
                return isPlaying006;
            default:
                return false;
        }
    }, [trackId, isPlaying001, isPlaying002, isPlaying003, isPlaying004, isPlaying005, isPlaying006]);

    return useMemo(() => {
        return {
            playBgm: () => {
                if (enableBGMSetting) {
                    const id = genId();
                    setTrackId(id);
                    console.log(`[Bgm] play track ${id}`);
                    play(id);
                }
            },
            stopBgm: () => {
                if (enableBGMSetting) {
                    console.log(`[Bgm] stop`);
                    stop();
                }
            },
            isPlayingBgm,
        };
        // eslint-disable-next-line
    }, [isPlayingBgm, play, stop]);
}
