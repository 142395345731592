import Confetti from '../utils/Confetti';
import { useEffect } from 'react';

export default function CelebrationController({ enabled, goalProgressPercent, goal }) {
    useEffect(() => {
        if (enabled && goalProgressPercent >= 100 && !goal.progress100celebrated) {
            Confetti.shared().start(1000);
            goal.progress100celebrated = true;
            console.log('Celebration played');
        }
        // eslint-disable-next-line
    }, [enabled, goal, goalProgressPercent]);
    return <div></div>;
}
