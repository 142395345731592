import { useState, useMemo, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
    getAllTimeStatsRef,
    getThisMonthStatsRef,
    getThisWeekStatsRef,
    getTodayStatsRef,
    UserStats,
} from '../../services/session';
import MinAspectArea from '../MinAspectArea';
import PageInfoBox from '../PageInfoBox';
import Segmented from '../Segmented';
import SummaryStats from '../SummaryStats';
import './Summary.scss';
import { isNil, filter, last } from 'lodash';
import { getExistData } from '../../services/firebase';
import analyticsManager from '../../analytics/AnalyticsManager';
import HomeFooter from '../HomeFooter';
import HomeActivitySelector from '../HomeActivitySelector';
import { ActivityId } from '../../ActivityId';

export default function Summary({
    user,
    userUpdater,
    currentUserSessions,
    appUser,
    pageController,
    pageControllerState,
}) {
    const history = useHistory();

    const [selectedIndex, setSelectedIndex] = useState(0);
    const [stats, setStats] = useState(new UserStats());

    const activityId = useMemo(() => {
        return pageControllerState.selectedActivityId;
    }, [pageControllerState.selectedActivityId]);

    useEffect(() => {
        const userId = appUser.userId;
        if (isNil(userId)) {
            setStats(new UserStats());
            return;
        }
        if (selectedIndex === 0) {
            let stats;
            if (currentUserSessions.length > 0) {
                let sessions = currentUserSessions.map((x) => x.data());
                switch (activityId) {
                    case ActivityId.TreadMill:
                        sessions = filter(
                            sessions,
                            (s) => isNil(s.activityId) || s.activityId === ActivityId.TreadMill
                        );
                        break;
                    default:
                        sessions = filter(sessions, (s) => s.activityId === activityId);
                        break;
                }
                sessions = sessions.sort((a, b) => {
                    return a.startTime.seconds - b.startTime.seconds;
                });
                const lastSession = last(sessions);
                stats = isNil(lastSession) ? new UserStats() : UserStats.fromSession(lastSession);
            } else {
                stats = new UserStats();
            }
            setStats(stats);
            return;
        }
        let statsRef;
        switch (selectedIndex) {
            case 1:
                statsRef = getTodayStatsRef(userId);
                break;
            case 2:
                statsRef = getThisWeekStatsRef(userId);
                break;
            case 3:
                statsRef = getThisMonthStatsRef(userId);
                break;
            default:
                statsRef = getAllTimeStatsRef(userId);
                break;
        }
        async function fetch() {
            const response = await statsRef
                .get()
                .then(getExistData)
                .then(
                    (json) => UserStats.fromJSON(json),
                    () => new UserStats()
                );
            setStats(response);
        }
        console.log('fetch summary');
        fetch();
    }, [selectedIndex, appUser, currentUserSessions, activityId]);

    // XXX (Philip): Hack to update user all-time stat whenever the summary screen is shown.
    useEffect(() => {
        async function fetch() {
            const userId = appUser.userId;
            const stats = await getAllTimeStatsRef(userId)
                .get()
                .then(getExistData)
                .then(
                    (json) => UserStats.fromJSON(json),
                    () => new UserStats()
                );
            analyticsManager().updateUserAllTimeStats(stats.allTimeAnalyticsProps);
        } 
        fetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="MainContainer">
            <div className="ContentContainer">
                <MinAspectArea summaryBg={true} className={pageControllerState.selectedActivityId}>
                    <div style={{ display: 'grid' }}>
                        <div style={{ gridRow: 1, gridColumn: 1, alignSelf: 'start', justifySelf: 'start' }}>
                            <PageInfoBox>
                                <HomeActivitySelector
                                    pageController={pageController}
                                    pageControllerState={pageControllerState}
                                />
                                <div
                                    style={{
                                        marginTop: '75px',
                                        height: '36px',
                                        fontSize: '35px',
                                        letterSpacing: '-0.32px',
                                        lineHeight: '1.03',
                                        textShadow: '0 2px 4px rgba(0, 0, 0, 0.5)',
                                    }}
                                >
                                    Summary
                                </div>
                                <div
                                    style={{
                                        marginTop: '31px',
                                    }}
                                >
                                    <Segmented
                                        className="SummaryMode"
                                        values={['Last session', 'Today', 'This week', 'This month', 'All time']}
                                        onChange={(index) => setSelectedIndex(index)}
                                    />
                                </div>
                                <div
                                    style={{
                                        marginTop: '-1px',
                                    }}
                                >
                                    <SummaryStats stats={stats} activityId={pageControllerState.selectedActivityId} />
                                </div>
                                <div
                                    style={{
                                        marginTop: '198px',
                                        marginLeft: '-3px',
                                        display: 'flex',
                                    }}
                                ></div>
                            </PageInfoBox>
                        </div>
                    </div>
                </MinAspectArea>
            </div>
            <HomeFooter />
        </div>
    );
}
