import './ProgressRing.scss';

export default function ProgressRing({
    progress, // expected values: 0 - 0.1
    style, // css style
}) {
    const offsetVal = Math.PI * 2 * 63 * (1.0 - Math.max(Math.min(progress || 0.0, 1.0), 0.0));
    const colorRingStyle = {
        fill: 'none',
        strokeDashoffset: offsetVal,
    };
    return (
        <div className="ProgressRing" style={style || {}}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 140 140">
                <linearGradient id="colors" x1="0" y1="1" x2="1" y2="0">
                    <stop offset="0%" stopColor="#1294ff"></stop>
                    <stop offset="100%" stopColor="#00fffb"></stop>
                </linearGradient>

                <path className="grey" d="M70,6 A63,63 0 1,1 69.9,6" style={{ fill: 'none' }} />
                <path className="colored" d="M70,6 A63,63 0 1,1 69.9,6" style={colorRingStyle} stroke="url(#colors)" />
            </svg>
        </div>
    );
}
