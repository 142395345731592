import './GroupTable.scss';
import GroupTableBody from './GroupTableBody';
import GroupTableHeader from './GroupTableHeader';
import { SidebarSelection } from './SideBar';
import Segmented from './Segmented';
import { availableActivities } from './HomeActivitySelector';
import { useEffect, useState } from 'react';
import { getActivityName } from '../ActivityId';
import { map } from 'lodash';

const GroupHeaderType = {
    RANK: 'RANK',
    NAME: 'NAME',
    CALORIES: 'CALORIES',
    TIME: 'TIME',
    STEP: 'STEP',
    DISTANCE: 'DISTANCE',
    OUTPUT: 'OUTPUT',
    PACE: 'PACE',
    SPEED: 'SPEED',
    COMBO: 'COMBO',
    JUMP: 'JUMP',
    JPM: 'JPM,',
    JOG_SESSION_COUNT: 'JOG_SESSION_COUNT',
    JOG_DURATION: 'JOG_DURATION',
    JUMP_ROPE_SESSION_COUNT: 'JUMP_ROPE_SESSION_COUNT',
    JUMP_ROPE_DURATION: 'JUMP_ROPE_DURATION',
    JUMPING_JACK_SESSION_COUNT: 'JUMPING_JACK_SESSION_COUNT',
    JUMPING_JACK_DURATION: 'JUMPING_JACK_DURATION',
};

const GroupSortingOrder = {
    ASCENDING: 'ASCENDING',
    DESCENDING: 'DESCENDING',
};

export const overallTab = 'Overall';

function GroupTable({
    groupList,
    groupTableSorting,
    setGroupTableSorting,
    pageControllerState,
    pageController,
    groupTableFilterState,
    setGroupTableFilterState,
}) {
    const availableTabs = [overallTab, ...availableActivities];
    const [selectedTabIndex, setSelectedTabIndex] = useState(0);

    useEffect(() => {
        setGroupTableSorting({});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedTabIndex]);

    if (
        pageControllerState.sidebarSelection === SidebarSelection.GROUPS &&
        pageControllerState.displayGroup &&
        groupList
    ) {
        return (
            <div className="groupDetails">
                <GroupTableHeader
                    group={pageControllerState.displayGroup}
                    pageController={pageController}
                    groupTableFilterState={groupTableFilterState}
                    setGroupTableFilterState={setGroupTableFilterState}
                />
                <Segmented
                    className="TabSelector"
                    values={map(availableTabs, (tab) => getTabName(tab))}
                    onChange={(index) => setSelectedTabIndex(index)}
                    selectedIndex={selectedTabIndex}
                />
                <GroupTableBody
                    groupList={groupList}
                    groupTableSorting={groupTableSorting}
                    setGroupTableSorting={setGroupTableSorting}
                    selectedTab={availableTabs[selectedTabIndex]}
                />
            </div>
        );
    } else {
        return <div></div>;
    }
}

function getTabName(tab) {
    switch (tab) {
        case overallTab:
            return 'Overall';
        default:
            return getActivityName({ activityId: tab });
    }
}

export { GroupTable, GroupHeaderType, GroupSortingOrder };
