import classNames from 'classnames';
import { PresetGoals, GoalType, GoalSetting } from './Goal';
import { DialogBoxResult, DialogBoxAction } from './DialogBox';
import { useMemo, useReducer } from 'react';
import './DialogBox.scss';
import { ActivityId } from '../ActivityId';

function TypeSelector({ selectedType, stateChange, activityId }) {
    const clickHandler = (type) => {
        return (event) => {
            event.stopPropagation();
            stateChange(Actions.CHANGE_TYPE(type));
        };
    };
    let availableTypes;
    switch (activityId) {
        case ActivityId.TreadMill:
            availableTypes = ['TIME', 'STEPS', 'DISTANCE', 'CALORIES'];
            break;
        case ActivityId.JumpRope:
        case ActivityId.JumpingJack:
            availableTypes = ['TIME', 'JUMPS', 'CALORIES'];
            break;
        default:
            availableTypes = [];
            break;
    }
    return (
        <div className="type-selector">
            {availableTypes.map((type) => {
                return (
                    <div
                        key={type}
                        className={classNames('cell', { selected: selectedType === type })}
                        onClick={clickHandler(type)}
                    >
                        {type}
                    </div>
                );
            })}
        </div>
    );
}

function Description({ selectedType }) {
    var text = '';
    switch (selectedType) {
        case 'DISTANCE':
            text = 'Select a target distance and we’ll motivate you to the finish line.';
            break;
        case 'TIME':
            text = 'Select a target duration and we’ll motivate you to the finish line.';
            break;
        case 'CALORIES':
            text = 'Set a target calories burned and we’ll motivate you to the finish line.';
            break;
        case 'STEPS':
            text = 'Select a target steps and we’ll motivate you to the finish line.';
            break;
        case 'JUMPS':
            text = 'Select a target jumps and we’ll motivate you to the finish line.';
            break;
        default:
            break;
    }
    return <div className="description">{text}</div>;
}

function GoalSelectTable({ selectedType, goalIndex, colCount = 2, stateChange }) {
    const clickHandler = (idx) => {
        return () => {
            stateChange(Actions.CHANGE_GOAL_INDEX(idx));
        };
    };

    const cell = (idx) => {
        return (
            <div
                key={idx}
                className={classNames('goal-cell', { selected: goalIndex === idx })}
                onClick={clickHandler(idx)}
            >
                {(() => {
                    console.warn(`${JSON.stringify(PresetGoals[selectedType][idx])}`);
                    return PresetGoals[selectedType][idx].selectCellString();
                })()}
            </div>
        );
    };

    var rows = [];
    for (var i = 0; i < PresetGoals[selectedType].length; i += colCount) {
        const row = (
            <div className="goal-row" key={`row-${i}`}>
                {cell(i)}
                {cell(i + 1)}
            </div>
        );
        rows.push(row);
    }

    return <div className="goal-table">{rows}</div>;
}

function CaloriesGoals({ selectedType, goalIndex, calories, stateChange, submitHandler }) {
    const clickHandler = (idx) => {
        return (event) => {
            event.stopPropagation();
            stateChange(Actions.CHANGE_GOAL_INDEX(idx));
        };
    };

    const changeHandler = (event) => {
        const isValid = [...event.target.value].reduce((acc, val) => acc && !isNaN(parseInt(val)), true);
        if (isValid) {
            const inputVal = parseInt(event.target.value);
            if (event.target.value === '' || inputVal < 10000) {
                stateChange(Actions.CHANGE_CALORIES_INPUT(inputVal));

                for (var i = 0; i < PresetGoals[selectedType].length; i++) {
                    // to match input value with preset goals' value
                    const goalVal = parseInt(PresetGoals[selectedType][i].presentingCalories);
                    if (inputVal === goalVal) {
                        stateChange(Actions.CHANGE_GOAL_INDEX(i));
                        return;
                    }
                }
                stateChange(Actions.CHANGE_GOAL_INDEX(-1));
            }
        }
    };

    return (
        goalIndex < PresetGoals[selectedType].length && (
            <div className="calories-form">
                <form onSubmit={submitHandler}>
                    <input onChange={changeHandler} type="text" className="input" value={calories}></input>
                </form>

                <div className="kcal-label">KCAL</div>
                <div className="choices">
                    {PresetGoals[selectedType].map((goal, idx) => {
                        return (
                            <div
                                onClick={clickHandler(idx)}
                                key={`calories-${idx}`}
                                className={classNames('calories-icon', goal.caloriesIcon(), {
                                    selected: goalIndex === idx,
                                })}
                            />
                        );
                    })}
                </div>
            </div>
        )
    );
}

/* Reducer's actions */
const Actions = {
    CHANGE_TYPE: (selectedType) => {
        return { actionType: 'CHANGE_TYPE', param: selectedType };
    },
    CHANGE_GOAL_INDEX: (idx) => {
        return { actionType: 'CHANGE_GOAL_INDEX', param: parseInt(idx) };
    },
    CHANGE_CALORIES_INPUT: (input) => {
        return { actionType: 'CHANGE_CALORIES_INPUT', param: parseInt(input) };
    },
};

/* Reducer */
const reducer = (state, action) => {
    var newState = { ...state };
    switch (action.actionType) {
        case 'CHANGE_TYPE':
            newState = { ...state, selectedType: action.param };
            if (state.selectedType !== newState.selectedType) {
                newState = { ...newState, goalIndex: 0 };
                if (newState.selectedType === 'CALORIES') {
                    const newCalories = PresetGoals[newState.selectedType][newState.goalIndex].presentingCalories;
                    newState = { ...newState, calories: newCalories };
                }
            }
            return newState;

        case 'CHANGE_GOAL_INDEX':
            newState = { ...state, goalIndex: action.param };
            if (newState.selectedType === 'CALORIES' && newState.goalIndex >= 0) {
                const newCalories = PresetGoals[newState.selectedType][newState.goalIndex].presentingCalories;
                newState = { ...newState, calories: newCalories };
            }
            return newState;

        case 'CHANGE_CALORIES_INPUT':
            return { ...state, calories: isNaN(action.param) ? '' : action.param, goalIndex: -1 };

        default:
            return state;
    }
};

/* Reducer: initial state */
const initialState = {
    selectedType: 'TIME',
    goalIndex: 0,
    calories: '',
};

export default function SelectGoalTypeDialogBox({ activityId, onDismiss }) {
    const [state, stateChange] = useReducer(reducer, initialState);

    const selectedGoal = useMemo(() => {
        switch (state.selectedType) {
            case 'DISTANCE':
            case 'TIME':
            case 'STEPS':
            case 'JUMPS':
                return PresetGoals[state.selectedType][state.goalIndex];
            case 'CALORIES':
                const intVal = parseInt(state.calories);
                return !isNaN(intVal)
                    ? new GoalSetting({ type: state.selectedType, presentingCalories: intVal })
                    : null;
            default:
                return null;
        }
    }, [state]);

    const dismissHandler = ({ dismissAction }) => {
        return (event) => {
            event.stopPropagation();
            dismissAction && onDismiss && onDismiss(new DialogBoxResult({ action: dismissAction }));
        };
    };

    const submitHandler = (event) => {
        event && event.stopPropagation();
        selectedGoal !== null &&
            onDismiss &&
            onDismiss(
                new DialogBoxResult({
                    value: selectedGoal,
                    action: DialogBoxAction.NEXT,
                })
            );
    };

    const backHandler = () => {
        onDismiss &&
            onDismiss(
                new DialogBoxResult({
                    action: DialogBoxAction.BACK,
                })
            );
    };

    return (
        <div className="dialog-layer">
            <div
                className="dialog-background goal-setting"
                onClick={dismissHandler({ dismissAction: DialogBoxAction.BACKGROUND })}
            >
                <div className="dialog-content goal-setting" onClick={dismissHandler({ dismissAction: null })}>
                    <div className="select-goal-dialog">
                        <TypeSelector {...state} stateChange={stateChange} activityId={activityId} />

                        <div className="title">Set Goal</div>

                        <Description {...state} stateChange={stateChange} />

                        {(state.selectedType === 'TIME' ||
                            state.selectedType === 'DISTANCE' ||
                            state.selectedType === 'STEPS' ||
                            state.selectedType === 'JUMPS') && <GoalSelectTable {...state} stateChange={stateChange} />}

                        {state.selectedType === 'CALORIES' && (
                            <CaloriesGoals {...state} stateChange={stateChange} submitHandler={submitHandler} />
                        )}

                        <div className="goal-button-area">
                            {/* back button */}
                            <div className="back button" onClick={backHandler}>
                                <span>BACK</span>
                            </div>
                            {/* confirm target button */}
                            <div
                                className={classNames('confirmTarget', 'button', { disabled: selectedGoal === null })}
                                onClick={submitHandler}
                            >
                                <span>START NOW</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
