import './GroupList.scss';
import { SidebarSelection } from './SideBar';
import GroupListView from './GroupListView';
import { CreateGroupButton } from './OpenDialogButton';
import { PageControllerAction } from './PageController';
import submit from '../utils/SubmitInputs';
import { useState } from 'react';
import { DialogBoxController, DIALOG_REQUIREMENTS } from './DialogBoxController';

export const GroupListType = {
    MANAGE: 'manage',
    JOINED: 'joined',
};

function SearchInputBox({ searchValue, setSearchValue }) {
    const inputHandler = (event) => {
        setSearchValue(event.target.value);
    };
    return (
        <div className="search-input-layer">
            <div className="search-icon" />
            <input type="input" placeholder={'Search for groups'} value={searchValue} onChange={inputHandler}></input>
        </div>
    );
}

function Header({ searchValue, setSearchValue }) {
    return (
        <div className="group-list-header">
            <div className="title-area">
                <div className="icon group-icon"></div>
                <div className="text">Groups</div>
            </div>
            <SearchInputBox searchValue={searchValue} setSearchValue={setSearchValue} />
        </div>
    );
}

function sortedData({ data, pinnedTop, filterText }) {
    const sortFn = (a, b) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
    };
    if (data?.length && data?.length === 0) {
        return [];
    }
    const pinnedIndex = data.findIndex((gp) => gp.name === pinnedTop?.name);
    if (pinnedIndex >= 0) {
        return [pinnedTop].concat(
            data
                .slice(0, pinnedIndex)
                .concat(data.slice(pinnedIndex + 1))
                .sort(sortFn)
        );
    }
    return data.sort(sortFn).filter((elem) => elem.name.toLowerCase().indexOf(filterText) !== -1);
}

export function ScrollableArea({ children }) {
    return <div className="scrollable-area">{children}</div>;
}

export default function GroupList(props) {
    const [searchValue, setSearchValue] = useState('');

    var requiredDialogs = [DIALOG_REQUIREMENTS.GROUP_NAME];
    if (!props.appUser?.name) {
        requiredDialogs.push(DIALOG_REQUIREMENTS.USER_NAME);
    }
    const onDialogComplete = (results) => {
        submit({
            ...props,
            inputUserName: results[DIALOG_REQUIREMENTS.USER_NAME],
            inputGroupName: results[DIALOG_REQUIREMENTS.GROUP_NAME],
        }).then((createdGroup) => {
            props.pageController(PageControllerAction.SHOW_NEW_GROUP_BALLOON(createdGroup.id));
            props.pageController(PageControllerAction.REFRESH_GROUP_LIST());
        });
    };

    if (
        props.pageControllerState.sidebarSelection === SidebarSelection.GROUPS &&
        props.managedTeams &&
        props.joinedTeams
    ) {
        return (
            <div className="group-list" style={props.style}>
                <Header searchValue={searchValue} setSearchValue={setSearchValue} />
                <div className="separator" />
                <DialogBoxController
                    DialogButton={CreateGroupButton}
                    requirements={requiredDialogs}
                    onComplete={onDialogComplete}
                    classnames={'button-create-group'}
                />
                <ScrollableArea>
                    <GroupListView
                        {...props}
                        type={GroupListType.MANAGE}
                        data={sortedData({
                            data: props.managedTeams,
                            pinnedTop: props.pageControllerState.newManagedGroup,
                            filterText: searchValue.toLowerCase(),
                        })}
                    />
                    <div className="separator" />
                    <GroupListView
                        {...props}
                        type={GroupListType.JOINED}
                        data={sortedData({ data: props.joinedTeams, filterText: searchValue.toLowerCase() })}
                    />
                </ScrollableArea>
            </div>
        );
    } else {
        return <div></div>;
    }
}
