import './JumpRopeSecondaryDashboard.scss';

export default function JumpRopeSecondaryDashboard({ stats }) {
    const jpm = Intl.NumberFormat('en-US', { maximumFractionDigits: 1 }).format(stats.currentJPM || 0);
    const currentCombo = stats.currentCombo || 0;
    const maxCombo = stats.maxCombo || 0;
    return (
        <div className="JumpRopeSecondaryDashboard">
            <JumpRopeSecondaryMetric value={jpm} title="JPM" />
            <JumpRopeSecondaryMetric value={currentCombo} title="Combo" />
            <JumpRopeSecondaryMetric value={maxCombo} title="Best Combo" />
        </div>
    );
}

function JumpRopeSecondaryMetric({ title, value }) {
    return (
        <div className="JumpRopeSecondaryMetric">
            <div className="value">{value}</div>
            <div className="title">{title}</div>
        </div>
    );
}
