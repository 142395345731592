import { kPoseNodeDetected, PoseNode } from '../detectors/Pose';
import { gaussian } from '../pose/BasicUtils';

export class SignalItem {
    /**
     * Creates an instance of SignalItem.
     * @param {number} val
     * @param {number} frameTime
     * @memberof SignalItem
     */
    constructor(val, frameTime) {
        this.val = val;
        this.frameTime = frameTime;
    }
}

export class SignalHistory {
    /**
     * Creates an instance of SignalHistory.
     * @param {number} maxHistoryTimeLength
     * @memberof SignalHistory
     */
    constructor(maxHistoryTimeLength) {
        this._maxHistoryTimeLength = maxHistoryTimeLength;

        /** @type {SignalItem[]} */
        this._items = [];

        /** @type {number} */
        this._curFrameTime = 0;
    }

    /**
     * Add new item to the history.
     *
     * @param {SignalItem} item
     * @memberof SignalHistory
     */
    addNewItem(item) {
        if (this._items.length > 0 && item.frameTime <= this._items[0].frameTime) {
            // No need to add because the item is not newer.
            return;
        }
        this._items.unshift(item);
    }

    /**
     * A convenience method for adding a Y value of a PoseNode
     *
     * @param {PoseNode} node
     * @param {number} frameTime
     * @memberof SignalHistory
     */
    addPoseNodeY(node, frameTime) {
        if (node.validLevel === kPoseNodeDetected) {
            this.addNewVal(node.y, frameTime);
        }
    }

    /**
     * A convenience method for adding a val with frameTime.
     *
     * @param {number} val
     * @param {number} frameTime
     * @memberof SignalHistory
     */
    addNewVal(val, frameTime) {
        this.addNewItem(new SignalItem(val, frameTime));
    }

    /**
     * Update the frameTime value.
     *
     * @param {number} frameTime
     * @memberof SignalHistory
     */
    updateCurrentFrameTime(frameTime) {
        this._curFrameTime = frameTime;
        this._cleanup();
    }

    /**
     * Clean up the history and remove out-dated items.
     *
     * @memberof SignalHistory
     */
    _cleanup() {
        // If the last element is out-dated, we pop it out.
        while (
            this._items.length > 0 &&
            this._items[this._items.length - 1].frameTime < this._curFrameTime - this._maxHistoryTimeLength
        ) {
            this._items.pop();
        }
    }

    /**
     * Returns the average value of the history
     * @returns {number}
     * @memberof SignalHistory
     */
    avgValue() {
        let sum = 0;
        for (const item of this._items) {
            sum += item.val;
        }
        return sum / (this._items.length + 1e-9);
    }

    /**
     * Compute a smoothed value based on a window at the head of the history (latest).
     *
     * @param {number} headTimeWindow
     * @param {number} timeSigma
     * @returns {number} the smoothed value or null
     * @memberof SignalHistory
     */
    getSmoothedHeadVal(headTimeWindow, timeSigma) {
        const refFrameTime = this._curFrameTime - headTimeWindow * 0.5;
        let weightSum = 0;
        let weightedValueSum = 0;
        for (const item of this._items) {
            if (item.frameTime < this._curFrameTime - headTimeWindow) {
                break;
            }

            const timeDiff = Math.abs(refFrameTime - item.frameTime);
            const weight = gaussian(timeDiff, timeSigma);
            weightSum += weight;
            weightedValueSum += weight * item.val;
        }

        if (weightSum > 0) {
            return weightedValueSum / weightSum;
        }

        return null;
    }

    /**
     * Compute the diff v2 - v1 for v2 at frameTime2 and v1 at frameTime1
     * If there's no sample at any of the frameTimes, return null.
     *
     * @param {double} frameTime1
     * @param {double} frameTime2
     * @returns {double} The value diff
     * @memberof SignalHistory
     */
    getValDiff(frameTime1, frameTime2) {
        // Seek for val1
        let val1 = 0;
        for (const item of this._items) {
            if (Math.abs(item.frameTime - frameTime1) < 1e-9) {
                val1 = item.val;
                break;
            } else if (item.frameTime < frameTime1) {
                return null;
            }
        }

        // Seek for val2
        let val2 = 0;
        for (const item of this._items) {
            if (Math.abs(item.frameTime - frameTime2) < 1e-9) {
                val2 = item.val;
                break;
            } else if (item.frameTime < frameTime2) {
                return null;
            }
        }

        return val2 - val1;
    }
}
