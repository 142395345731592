export class PlayerPose {
    /**
     * Creates an instance of PlayerPose.
     *
     * @param {Pose} pose
     * @param {number} frameTime
     * @memberof PlayerPose
     */
    constructor(pose, frameTime) {
        this.pose = pose;
        this.frameTime = frameTime;
    }

    /**
     * Whether the pose is detected or not.
     *
     * @returns {boolean}
     * @memberof PlayerPose
     */
    detected() {
        return !!this.pose;
    }
}
