import { getUserRef } from './user';
import firebase from 'firebase/app';
import { omitBy, isNil, isEmpty, max } from 'lodash';
import moment from 'moment';
import { ActivityId } from '../ActivityId';
import TimeRangeFilterType from '../components/TimeRangeFilterType';

export const DayIdFormat = 'YYYY-MM-DD';
export const MonthIdFormat = 'YYYY-MM';

function getUserSessionsRef(userId) {
    return getUserRef(userId).collection('sessions');
}

export function getSessionRef(userId, sessionId) {
    return getUserSessionsRef(userId).doc(sessionId);
}

// dayId: YYYY-MM-DD
export function getDailyStatsRef(userId, dayId) {
    return getUserRef(userId).collection('dailyStats').doc(dayId);
}

export function getTodayStatsRef(userId) {
    return getDailyStatsRef(userId, moment().format(DayIdFormat));
}

// startOfWeekDayId: YYYY-MM-DD
export function getWeeklyStatsRef(userId, startOfWeekDayId) {
    return getUserRef(userId).collection('weeklyStats').doc(startOfWeekDayId);
}

export function getThisWeekStatsRef(userId) {
    return getWeeklyStatsRef(userId, moment().startOf('week').format(DayIdFormat));
}

// monthId: YYYY-MM
export function getMonthlyStatsRef(userId, monthId) {
    return getUserRef(userId).collection('monthlyStats').doc(monthId);
}

export function getThisMonthStatsRef(userId) {
    return getMonthlyStatsRef(userId, moment().format(MonthIdFormat));
}

export function getAllTimeStatsRef(userId) {
    return getUserRef(userId).collection('allTimeStats').doc('default');
}

export function saveUserStats(userId, sessionDataBefore, sessionDataAfter, currentUserStats, incrementSessionCount) {
    const pairs = {
        [TimeRangeFilterType.TODAY]: getTodayStatsRef(userId),
        [TimeRangeFilterType.THIS_WEEK]: getThisWeekStatsRef(userId),
        [TimeRangeFilterType.THIS_MONTH]: getThisMonthStatsRef(userId),
        [TimeRangeFilterType.ALL_TIME]: getAllTimeStatsRef(userId),
    };
    const lastUpdateTime = firebase.firestore.Timestamp.now();
    const promises = Object.entries(pairs).map(([key, ref]) => {
        const userStats = currentUserStats[key] || new UserStats();
        const statsUpdate = new UserStatsUpdate(sessionDataBefore, sessionDataAfter, userStats, incrementSessionCount);
        const json = statsUpdate.toJSON();
        if (isEmpty(json)) {
            return Promise.resolve();
        }
        return ref.set({ ...json, lastUpdateTime }, { merge: true });
    });
    return Promise.all(promises);
}

class UserStatsUpdate {
    static FieldType = {
        INCREMENTAL: 'INCREMENTAL',
        MAX: 'MAX',
    };
    constructor(before, after, userStats, incrementSessionCount) {
        const prefix = UserStats.getActivityPrefix(before.activityId);
        let fields;
        switch (before.activityId) {
            case ActivityId.TreadMill:
                fields = {
                    duration: UserStatsUpdate.FieldType.INCREMENTAL,
                    energy: UserStatsUpdate.FieldType.INCREMENTAL,
                    output: UserStatsUpdate.FieldType.INCREMENTAL,
                    steps: UserStatsUpdate.FieldType.INCREMENTAL,
                    distance: UserStatsUpdate.FieldType.INCREMENTAL,
                };
                break;
            case ActivityId.JumpRope:
                fields = {
                    duration: UserStatsUpdate.FieldType.INCREMENTAL,
                    energy: UserStatsUpdate.FieldType.INCREMENTAL,
                    output: UserStatsUpdate.FieldType.INCREMENTAL,
                    jumps: UserStatsUpdate.FieldType.INCREMENTAL,
                    maxCombo: UserStatsUpdate.FieldType.MAX,
                };
                break;
            case ActivityId.JumpingJack:
                fields = {
                    duration: UserStatsUpdate.FieldType.INCREMENTAL,
                    energy: UserStatsUpdate.FieldType.INCREMENTAL,
                    output: UserStatsUpdate.FieldType.INCREMENTAL,
                    jumps: UserStatsUpdate.FieldType.INCREMENTAL,
                    maxCombo: UserStatsUpdate.FieldType.MAX,
                };
                break;
            default:
                fields = {};
                break;
        }
        for (const [field, type] of Object.entries(fields)) {
            const change = after[field] - before[field];
            if (change >= 0) {
                let value;
                switch (type) {
                    case UserStatsUpdate.FieldType.INCREMENTAL:
                        value = firebase.firestore.FieldValue.increment(change);
                        break;
                    case UserStatsUpdate.FieldType.MAX:
                        value = max([before[field], after[field], userStats[prefix][field]]);
                        break;
                    default:
                        continue;
                }
                if (isNil(prefix) || isEmpty(prefix)) {
                    this[field] = value;
                } else {
                    if (isNil(this[prefix])) {
                        this[prefix] = {};
                    }
                    this[prefix][field] = value;
                }
            }
        }
        if (incrementSessionCount) {
            const value = firebase.firestore.FieldValue.increment(1);
            if (isNil(prefix) || isEmpty(prefix)) {
                this.sessionCount = value;
            } else {
                if (isNil(this[prefix])) {
                    this[prefix] = {};
                }
                this[prefix].sessionCount = value;
            }
        }
    }

    toJSON() {
        return omitBy(this, (x) => isNil(x));
    }
}

export class UserStats {
    static lastUpdateTimeFallback = new Date(2021, 1, 1);
    constructor({
        duration = 0,
        energy = 0,
        output = 0,
        steps = 0,
        distance = 0,
        sessionCount = 0,
        lastUpdateTime = UserStats.lastUpdateTimeFallback,
        jumpRope = new JumpRopeUserStats(),
        jumpingJack = new JumpingJackUserStats(),
    } = {}) {
        this.duration = duration; // seconds
        this.energy = energy;
        this.output = output;
        this.steps = steps;
        this.distance = distance; // miles
        this.sessionCount = sessionCount;
        this.lastUpdateTime = lastUpdateTime;
        this.jumpRope = jumpRope;
        this.jumpingJack = jumpingJack;
    }

    static fromJSON(json) {
        const jumpRopeJSON = json[UserStats.getActivityPrefix(ActivityId.JumpRope)];
        const jumpingJackJSON = json[UserStats.getActivityPrefix(ActivityId.JumpingJack)];
        return new UserStats({
            duration: json.duration,
            energy: json.energy,
            output: json.output,
            steps: json.steps,
            distance: json.distance,
            sessionCount: json.sessionCount || 0,
            lastUpdateTime: json.lastUpdateTime?.toDate() || UserStats.lastUpdateTimeFallback,
            jumpRope: isNil(jumpRopeJSON) ? new JumpRopeUserStats() : JumpRopeUserStats.fromJSON(jumpRopeJSON),
            jumpingJack: isNil(jumpingJackJSON)
                ? new JumpingJackUserStats()
                : JumpingJackUserStats.fromJSON(jumpingJackJSON),
        });
    }
    static fromSession(session) {
        const activityId = session.activityId || ActivityId.TreadMill;
        const lastUpdateTime = session.startTime?.toDate()
            ? session.startTime?.toDate() + session.duration
            : UserStats.lastUpdateTimeFallback;
        switch (activityId) {
            case ActivityId.TreadMill:
                return new UserStats({
                    duration: session.duration,
                    energy: session.energy,
                    output: session.output,
                    steps: session.steps,
                    distance: session.distance,
                    sessionCount: 1,
                    lastUpdateTime,
                });
            case ActivityId.JumpRope:
                return new UserStats({ lastUpdateTime, jumpRope: JumpRopeUserStats.fromSession(session) });
            case ActivityId.JumpingJack:
                return new UserStats({ lastUpdateTime, jumpingJack: JumpingJackUserStats.fromSession(session) });
            default:
                return new UserStats();
        }
    }

    get speed() {
        if (this.duration <= 0) {
            return 0;
        }
        const durationInHour = this.duration / (60 * 60);
        return this.distance / durationInHour;
    }

    get pace() {
        if (this.distance <= 0) {
            return 0;
        }
        return this.duration / 60 / this.distance;
    }

    leaderboardScore(activityId) {
        switch (activityId) {
            case ActivityId.TreadMill:
                return this.steps;
            case ActivityId.JumpRope:
                return this.jumpRope.jumps;
            case ActivityId.JumpingJack:
                return this.jumpingJack.jumps;
            default:
                return 0;
        }
    }

    sessionAnalyticsProps(activityId) {
        switch (activityId) {
            case ActivityId.TreadMill:
                return {
                    stats_duration: this.duration,
                    stats_steps: this.steps,
                    stats_energy: this.energy,
                    stats_distance: this.distance,
                    stats_speed: (this.distance * 3600) / Math.max(1, this.duration),
                    stats_cadence: (this.steps * 60) / Math.max(1, this.duration),
                };
            case ActivityId.JumpRope:
                return {
                    stats_duration: this.jumpRope.duration,
                    stats_energy: this.jumpRope.energy,
                    stats_jumps: this.jumpRope.jumps,
                    stats_max_combo: this.jumpRope.maxCombo,
                    stats_jpm: this.jumpRope.jpm,
                };
            case ActivityId.JumpingJack:
                return {
                    stats_duration: this.jumpingJack.duration,
                    stats_energy: this.jumpingJack.energy,
                    stats_jumps: this.jumpingJack.jumps,
                    stats_max_combo: this.jumpingJack.maxCombo,
                    stats_jpm: this.jumpingJack.jpm,
                };
            default:
                return {};
        }
    }

    get allTimeAnalyticsProps() {
        return {
            // Jog In Place
            total_jogging_duration: this.duration,
            total_jogging_energy: this.energy,
            total_jogging_output: this.output,
            total_jogging_steps: this.steps,
            total_jogging_distance: this.distance,
            // Jump Rope
            total_jump_rope_duration: this.jumpRope.duration,
            total_jump_rope_energy: this.jumpRope.energy,
            total_jump_rope_jumps: this.jumpRope.jumps,
            jump_rope_max_combo: this.jumpRope.maxCombo,
            // Jumping Jack
            total_jumping_jack_duration: this.jumpingJack.duration,
            total_jumping_jack_energy: this.jumpingJack.energy,
            total_jumping_jack_jumps: this.jumpingJack.jumps,
            jumping_jack_max_combo: this.jumpingJack.maxCombo,
        };
    }

    get aggregatedDuration() {
        return this.duration + this.jumpRope.duration + this.jumpingJack.duration;
    }

    get aggregatedEnergy() {
        return this.energy + this.jumpRope.energy + this.jumpingJack.energy;
    }

    static getActivityPrefix(activityId) {
        switch (activityId) {
            case ActivityId.JumpRope:
                return 'jumpRope';
            case ActivityId.JumpingJack:
                return 'jumpingJack';
            default:
                return undefined;
        }
    }
}

export class JumpRopeUserStats {
    constructor(duration = 0, energy = 0, output = 0, jumps = 0, maxCombo = 0, sessionCount = 0) {
        this.duration = duration; // seconds
        this.energy = energy;
        this.output = output;
        this.jumps = jumps;
        this.maxCombo = maxCombo || 0;
        this.sessionCount = sessionCount || 0;
    }

    static fromJSON(json) {
        return new JumpRopeUserStats(
            json.duration,
            json.energy,
            json.output,
            json.jumps,
            json.maxCombo,
            json.sessionCount
        );
    }
    static fromSession(session) {
        return new JumpRopeUserStats(
            session.duration,
            session.energy,
            session.output,
            session.jumps,
            session.maxCombo,
            1
        );
    }

    get jpm() {
        if (this.duration > 0) {
            return this.jumps / (this.duration / 60);
        } else {
            return 0;
        }
    }
}

export class JumpingJackUserStats {
    constructor(duration = 0, energy = 0, output = 0, jumps = 0, maxCombo = 0, sessionCount = 0) {
        this.duration = duration; // seconds
        this.energy = energy;
        this.output = output;
        this.jumps = jumps;
        this.maxCombo = maxCombo || 0;
        this.sessionCount = sessionCount || 0;
    }

    static fromJSON(json) {
        return new JumpingJackUserStats(
            json.duration,
            json.energy,
            json.output,
            json.jumps,
            json.maxCombo,
            json.sessionCount
        );
    }
    static fromSession(session) {
        return new JumpingJackUserStats(
            session.duration,
            session.energy,
            session.output,
            session.jumps,
            session.maxCombo,
            1
        );
    }

    get jpm() {
        if (this.duration > 0) {
            return this.jumps / (this.duration / 60);
        } else {
            return 0;
        }
    }
}

export class Session {
    updateWithEngineStats(stats) {
        throw new Error(`Subclass should have its own implementation`);
    }
    get leaderboardScore() {
        throw new Error(`Subclass should have its own implementation`);
    }
}

export class JogSession {
    constructor(duration = 0, energy = 0, output = 0, steps = 0, distance = 0) {
        this.activityId = ActivityId.TreadMill;
        this.duration = duration;
        this.energy = energy;
        this.output = output;
        this.steps = steps;
        this.distance = distance;
    }

    updateWithEngineStats(stats) {
        if (stats.totalTime) {
            this.duration = stats.totalTime;
        }
        if (stats.totalEnergy) {
            this.energy = stats.totalEnergy;
        }
        if (stats.totalOutput) {
            this.output = stats.totalOutput;
        }
        if (stats.totalStepCount) {
            this.steps = stats.totalStepCount;
        }
        if (stats.totalDistance) {
            this.distance = stats.totalDistance;
        }
    }

    get leaderboardScore() {
        return this.steps;
    }
}

export class JumpRopeSession {
    constructor(duration = 0, energy = 0, output = 0, jumps = 0, maxCombo = 0) {
        this.activityId = ActivityId.JumpRope;
        this.duration = duration;
        this.energy = energy;
        this.output = output;
        this.jumps = jumps;
        this.maxCombo = maxCombo;
    }

    updateWithEngineStats(stats) {
        if (stats.totalTime) {
            this.duration = stats.totalTime;
        }
        if (stats.totalEnergy) {
            this.energy = stats.totalEnergy;
        }
        if (stats.totalOutput) {
            this.output = stats.totalOutput;
        }
        if (stats.totalJumpCount) {
            this.jumps = stats.totalJumpCount;
        }
        if (stats.maxCombo) {
            this.maxCombo = stats.maxCombo;
        }
    }

    get leaderboardScore() {
        return this.jumps;
    }
}
export class JumpingJackSession {
    constructor(duration = 0, energy = 0, output = 0, jumps = 0, maxCombo = 0) {
        this.activityId = ActivityId.JumpingJack;
        this.duration = duration;
        this.energy = energy;
        this.output = output;
        this.jumps = jumps;
        this.maxCombo = maxCombo;
    }

    updateWithEngineStats(stats) {
        console.warn(JSON.stringify(stats));
        if (stats.totalTime) {
            this.duration = stats.totalTime;
        }
        if (stats.totalEnergy) {
            this.energy = stats.totalEnergy;
        }
        if (stats.totalOutput) {
            this.output = stats.totalOutput;
        }
        if (stats.totalJumpCount) {
            this.jumps = stats.totalJumpCount;
        }
        if (stats.maxCombo) {
            this.maxCombo = stats.maxCombo;
        }
    }

    get leaderboardScore() {
        return this.jumps;
    }
}
