import GameEngine from './GameEngine';

import PoseDetector from '../detectors/PoseDetector';
import { Pose } from '../detectors/Pose';
import { RunningDetector, isPoseQualifiedForRunningDetection } from '../running/RunningDetector';
import { max, isNil } from 'lodash';

const kProcessFps = 30;
const kProcessTimeGap = 1.0 / kProcessFps;

const kSpeedUIRefreshFps = 2;

const kInitialAvatarCaptureDelay = 1000; // 1000ms.
const kAvatarCaptureMaxDelay = 10000; // We will capture one avatar every 10s.
const kAvatarCaptureDelayMultiplier = 1.5; // After a successful detection, we will increase our delay by this factor.

class JumpRopeGameEngine extends GameEngine {
    /**
     * Creates an instance of JumpRopeGameEngine with a loaded PoseDetector.
     * @param {HTMLDivElement} container
     * @param {PoseDetector} detector
     * @memberof JumpRopeGameEngine
     */
    constructor(container, detector) {
        super(container, detector);

        // Frame Time

        /** @type {number} */
        this._lastProcessTime = 0;

        // Running Detector

        /** @type {RunningDetector} */
        this._runningDetector = new RunningDetector();

        // Avatar Capture

        /** @type {Boolean} */
        this._avatarCaptureDelay = kInitialAvatarCaptureDelay;

        /** @type {number} */
        this._nextAvatarTimestamp = Date.now() + kInitialAvatarCaptureDelay;

        // Stats

        /** @type {number} */
        this._lastSpeedUIRefreshTimestamp = 0;

        this._valuesSnapshot = {
            jumpCount: 0,
            energy: 0,
            combo: 0,
            maxCombo: 0,
            lastJumpFrameTime: undefined,
        };
    }

    _needsExtraInfo() {
        const currTime = Date.now();
        if (currTime >= this._nextAvatarTimestamp) {
            this._nextAvatarTimestamp = currTime + this._avatarCaptureDelay;
            return true;
        }
    }

    /**
     * Updates the game state at the given time.
     *
     * @param {number} time
     * @memberof JumpRopeGameEngine
     */
    _tick(time) {
        this._runningDetector.config(this._debugCanvas.width, this._debugCanvas.height);

        const frameTime = time * 0.001;
        if (frameTime < this._lastProcessTime + kProcessTimeGap) {
            // Don't process too fast.
            return;
        }

        this._lastProcessTime = frameTime;

        // Analyze
        this._runningDetector.tick(frameTime);

        // Update stats
        const jumpCount = this._runningDetector.stepCount();
        const jumpsPerSecond = this._runningDetector.smoothedStepsPerSecond();
        const jumpsPerMinute = jumpsPerSecond * 60;
        let met;
        if (jumpsPerMinute < 100) {
            met = 8.8;
        } else if (jumpsPerMinute < 120) {
            met = 11.8;
        } else {
            met = 12.3;
        }
        const weight = 60; // kg
        const kcalPerMinute = (3.5 * met * weight) / 200; // Ref: https://www.dailycaloriescalculator.com/calories-burned-jumping-rope
        const minJumpsPerSecond = 1;
        const kcalPerJump = jumpsPerSecond >= minJumpsPerSecond ? kcalPerMinute / jumpsPerMinute : 0;
        const kjPerKcal = 4.184;

        const jumpCountDiff = max([0, jumpCount - this._valuesSnapshot.jumpCount]);
        const energyDiff = jumpCountDiff * kcalPerJump;
        const energy = this._valuesSnapshot.energy + energyDiff;

        let combo;
        if (
            isNil(this._valuesSnapshot.lastJumpFrameTime) ||
            (frameTime - this._valuesSnapshot.lastJumpFrameTime > 1 && jumpCountDiff === 0)
        ) {
            combo = jumpCountDiff;
        } else {
            combo = this._valuesSnapshot.combo + jumpCountDiff;
        }
        const maxCombo = max([this._valuesSnapshot.maxCombo, combo]);

        this._emit({
            stats: {
                totalJumpCount: jumpCount,
                totalEnergy: energy, // kcal
                totalOutput: energy * kjPerKcal, // kJ
                currentCombo: combo,
                maxCombo: maxCombo,
            },
        });

        this._valuesSnapshot.jumpCount = jumpCount;
        this._valuesSnapshot.energy = energy;
        this._valuesSnapshot.combo = combo;
        this._valuesSnapshot.maxCombo = maxCombo;
        if (jumpCountDiff > 0) {
            this._valuesSnapshot.lastJumpFrameTime = frameTime;
        }

        const shouldRefreshSpeedUI = frameTime >= this._lastSpeedUIRefreshTimestamp + 1.0 / kSpeedUIRefreshFps;
        if (shouldRefreshSpeedUI) {
            const currentValues = {
                currentJPM: jumpsPerMinute,
            };

            this._emit({
                stats: {
                    ...currentValues,
                },
            });

            this._lastSpeedUIRefreshTimestamp = frameTime;
        }
    }

    /**
     * Handles pose detections.
     *
     * @param {Pose[]} poses
     * @param {number} time
     * @memberof JumpRopeGameEngine
     */
    _handleDetection(poses, time) {
        const frameTime = time * 0.001;

        this._runningDetector.handleDetection(poses, frameTime);
    }

    /**
     * Returns true if we think the warning message should be shown based on the latest pose detection.
     *
     * @param {Pose} poses
     * @returns
     * @memberof JumpRopeGameEngine
     */
    _shouldShowDetectionWarning(poses) {
        const pose = this._runningDetector.pickPose(poses);
        const isPoseQualified = isPoseQualifiedForRunningDetection(pose);
        return !isPoseQualified;
    }

    /**
     * This is invoked when _needsExtraInfo() returns before, and the detector actually returned some extra info.
     *
     * @param {any} extraInfo
     * @memberof JumpRopeGameEngine
     */
    _handleExtraInfo(extraInfo) {
        const oldDelay = this._avatarCaptureDelay;
        this._avatarCaptureDelay = Math.min(
            kAvatarCaptureMaxDelay,
            Math.floor(this._avatarCaptureDelay * kAvatarCaptureDelayMultiplier)
        );
        this._nextAvatarTimestamp += this._avatarCaptureDelay - oldDelay;
        this._emit({
            avatar: extraInfo.avatar,
        });
    }

    /**
     * Creates a new JumpRopeGameEngine that attaches to the given container.
     *
     * @static
     * @returns
     * @memberof JumpRopeGameEngine
     */
    static async create({ dispatcher }) {
        // Technically we should use the PoseDetector, but we will skip that for now.
        const detector = await PoseDetector.create();
        await detector.warmup();
        return new JumpRopeGameEngine(detector, dispatcher);
    }
}

export default JumpRopeGameEngine;
