import { useEffect, useMemo, useState, useRef } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useCollection } from 'react-firebase-hooks/firestore';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { SpinnerCircular } from 'spinners-react';
import './App.scss';
import Activity from './components/pages/Activity';
import SettingsPage from './components/pages/SettingsPage';
import SetupPage from './components/pages/SetupPage';
import { auth, db, fetchGroupMemberList } from './services/firebase';
import useDependencyTracker from './utils/DependencyTracker';
import { useSettings, allSettings } from './utils/Settings';
import analyticsManager from './analytics/AnalyticsManager';
import GroupHome from './components/pages/GroupHome';
import { fetchGroupList } from './services/firebase';
import { PageController, PageControllerAction } from './components/PageController';
import Invitation from './components/pages/Invitation';
import LandscapeWatch from './components/LandscapeWatch';
import Div100vh from 'react-div-100vh';
import { useBgm } from './utils/BgmManager';

auth()
    .setPersistence(auth.Auth.Persistence.LOCAL)
    .then(() => {
        auth().signInAnonymously();
    });
const userCollection = db.collection('users');

export default function App() {
    const [settings, settingsUpdater] = useSettings();
    const [user] = useAuthState(auth());
    const [groupList, setGroupList] = useState(null);
    const [managedTeams, setManagedTeams] = useState(null);
    const [joinedTeams, setJoinedTeams] = useState(null);
    const [dependency, dependencyReporter] = useDependencyTracker(['user', 'appUser', 'joinedTeams', 'managedTeams']);

    // Page display controller
    const [pageControllerState, pageController] = PageController();
    useEffect(() => {
        pageController(PageControllerAction.STOP_BACKGROUND_MUSIC);
        // eslint-disable-next-line
    }, []);

    // fetch joined teams and managed teams
    useEffect(() => {
        if (user && db && pageControllerState.refreshGroupList) {
            fetchGroupList(user.uid).then((values) => {
                const { joined, managed } = values;
                // Uncomment to debug
                // console.log('fetch joined', joined);
                // console.log('fetch managed', managed);
                setManagedTeams(managed);
                setJoinedTeams(joined);

                // no need to set up dependency on refreshGroupListCallback
                if (pageControllerState.refreshGroupListCallback) {
                    pageControllerState.refreshGroupListCallback({ joinedTeams: joined, managedTeams: managed });
                }
                pageController(PageControllerAction.REFRESH_GROUP_LIST_DONE);
                pageController(PageControllerAction.DISMISS_SPINNER);
            });
        }
        // eslint-disable-next-line
    }, [pageControllerState.refreshGroupList, user, db]);

    // loading spinner vs dependency
    useEffect(() => {
        if (dependency.outstanding === 0) {
            pageController(PageControllerAction.DISMISS_SPINNER);
        }
        // eslint-disable-next-line
    }, [dependency]);

    // The following is code adopted from 'clipper'

    useEffect(() => {
        if (user) {
            console.log('Logged in: ', user.uid, user);
            // Logged-in user

            // Make sure that this user really exists on the backend, if possible.
            const docRef = userCollection.doc(user.uid);
            docRef
                .set({}, { merge: true })
                .then(() => {
                    return docRef.get();
                })
                .then((snapshot) => {
                    analyticsManager().trackLoginUser({ ...snapshot.data(), id: user.uid });
                });
        }
        dependencyReporter({ user: !user });
    }, [user, dependencyReporter]);

    const userId = useMemo(() => {
        return user ? user.uid : null;
    }, [user]);

    // Create a callback with which we can update the user data.
    const userUpdater = useMemo(() => {
        if (userId) {
            return (change) => {
                if (change.name && change.name !== '') {
                    analyticsManager().trackNewUserName({ ...change, id: userId });
                }
                return userCollection.doc(userId).set(change, { merge: true });
            };
        } else {
            return null;
        }
    }, [userId]);

    const [usersSnapshot] = useCollection(userCollection);
    const userDB = useMemo(() => {
        const ret = {};
        if (usersSnapshot) {
            for (const doc of usersSnapshot.docs) {
                ret[doc.id] = { avatar: doc.get('avatar'), name: doc.get('name') };
            }
        }
        return ret;
    }, [usersSnapshot]);

    const [
        currentUserSessionsSnapshot,
        currentUserSessionsSnapshotLoading,
        currentUserSessionsSnapshotError,
    ] = useCollection(user ? userCollection.doc(user.uid).collection('sessions') : null);

    const currentUserSessions = useMemo(() => {
        if (currentUserSessionsSnapshot) {
            return currentUserSessionsSnapshot.docs;
        } else {
            return null;
        }
    }, [currentUserSessionsSnapshot]);

    useEffect(() => {
        if (currentUserSessions || currentUserSessionsSnapshotError) {
            dependencyReporter({ userSessions: false });
        } else {
            dependencyReporter({ userSessions: true });
        }
    }, [currentUserSessions, currentUserSessionsSnapshotError, dependencyReporter]);

    // NOTE: Since userDB will update itself at an interval of 5 seconds.
    // we cannot use the following commented code
    // otherwise, appUser would be updated every 5 seconds
    //
    // const appUser = useMemo(() => {
    //     return user ? { ...userDB[user.uid], userId: user.uid } : null;
    // }, [user, userDB]);

    const [appUserAvatar, appUserName, appUserId] = useMemo(() => {
        return [
            user ? userDB[user.uid]?.avatar : undefined,
            user ? userDB[user.uid]?.name : undefined,
            user ? user?.uid : undefined,
        ];
    }, [user, userDB]);

    // fetch member list, when selected
    useEffect(() => {
        if (pageControllerState.displayGroup && pageControllerState.groupTableTimeFilter) {
            console.log(
                `loading team id = ${pageControllerState.displayGroup.id}, timeFilter: ${pageControllerState.groupTableTimeFilter}`
            );
            fetchGroupMemberList(pageControllerState.displayGroup.id, pageControllerState.groupTableTimeFilter).then(
                (list) => {
                    setGroupList(list);
                    pageController(PageControllerAction.DISMISS_SPINNER);
                }
            );
        }
        // eslint-disable-next-line
    }, [pageControllerState.displayGroup, pageControllerState.groupTableTimeFilter, appUserName]);

    // NOTE: The dependencies are strings
    // Although userDB keeps updating periodically, appUser objects would not be updated accordingly.
    const appUser = useMemo(() => {
        return { avatar: appUserAvatar, name: appUserName, userId: appUserId };
    }, [appUserAvatar, appUserName, appUserId]);

    useEffect(() => {
        dependencyReporter({ appUser: !appUser });
    }, [appUser, dependencyReporter]);

    useEffect(() => {
        dependencyReporter({ joinedTeams: !joinedTeams });
    }, [joinedTeams, dependencyReporter]);

    useEffect(() => {
        dependencyReporter({ managedTeams: !managedTeams });
    }, [managedTeams, dependencyReporter]);

    // background music control
    const { playBgm, stopBgm, isPlayingBgm } = useBgm();
    useEffect(() => {
        if (pageControllerState.backgroundMusic && pageControllerState.enabledSound) {
            !isPlayingBgm && playBgm();
        } else {
            stopBgm();
        }
    }, [pageControllerState.backgroundMusic, pageControllerState.enabledSound, isPlayingBgm, playBgm, stopBgm]);

    useEffect(() => {
        analyticsManager().setIsDev(settings.activity.isDev);
    }, [settings.activity.isDev]);

    // For dev, un-comment to inspect why the loading cannot stop
    // useEffect(() => {
    //     console.log('dependency', dependency);
    // }, [dependency]);

    if (isMobileOnly) {
        return (
            <div className="ScreenSizeAlert">
                <div className="Title">This site is currently designed for large screens only.</div>
                <div className="Subtitle">Try accessing it using a computer or tablet with a camera.</div>
            </div>
        );
    }

    return (
        <Router>
            <Switch>
                <Route
                    path="/activity/:activityId?"
                    render={(props) => (
                        <LandscapeWatch>
                            <Activity
                                {...props}
                                userId={userId}
                                userDB={userDB}
                                group={pageControllerState.selectedTeamToStart}
                                settings={settings}
                                userUpdater={userUpdater}
                                dependencyReporter={dependencyReporter}
                                pageController={pageController}
                                pageControllerState={pageControllerState}
                            />
                        </LandscapeWatch>
                    )}
                />
                <Route
                    path="/summary"
                    render={(props) => (
                        <GroupHome
                            {...props}
                            appUser={appUser}
                            userUpdater={userUpdater}
                            currentUserSessions={currentUserSessions}
                            groupList={groupList}
                            managedTeams={managedTeams}
                            joinedTeams={joinedTeams}
                            dependency={dependency}
                            dependencyReporter={dependencyReporter}
                            pageController={pageController}
                            pageControllerState={pageControllerState}
                            path="/summary"
                        />
                    )}
                />
                <Route
                    path="/settings"
                    render={(props) => (
                        <SettingsPage {...props} value={settings} setter={settingsUpdater} spec={allSettings} />
                    )}
                />
                <Route
                    path="/invite"
                    render={(props) => (
                        <Invitation
                            {...props}
                            appUser={appUser}
                            userUpdater={userUpdater}
                            currentUserSessions={currentUserSessions}
                            groupList={groupList}
                            managedTeams={managedTeams}
                            joinedTeams={joinedTeams}
                            dependency={dependency}
                            dependencyReporter={dependencyReporter}
                            pageController={pageController}
                            pageControllerState={pageControllerState}
                        />
                    )}
                />
                <Route
                    path="/setup/:activityId?"
                    render={(props) => (
                        <Div100vh>
                            <LandscapeWatch>
                                <SetupPage
                                    {...props}
                                    appUser={appUser}
                                    userUpdater={userUpdater}
                                    currentUserSessions={currentUserSessions}
                                    groupList={groupList}
                                    managedTeams={managedTeams}
                                    joinedTeams={joinedTeams}
                                    dependency={dependency}
                                    dependencyReporter={dependencyReporter}
                                    pageController={pageController}
                                    pageControllerState={pageControllerState}
                                    group={pageControllerState.selectedTeamToStart}
                                    settings={settings}
                                />
                            </LandscapeWatch>
                        </Div100vh>
                    )}
                />
                <Route
                    path="/"
                    render={(props) => (
                        <LandscapeWatch>
                            <GroupHome
                                {...props}
                                appUser={appUser}
                                userUpdater={userUpdater}
                                currentUserSessions={currentUserSessions}
                                groupList={groupList}
                                managedTeams={managedTeams}
                                joinedTeams={joinedTeams}
                                dependency={dependency}
                                dependencyReporter={dependencyReporter}
                                pageController={pageController}
                                pageControllerState={pageControllerState}
                                path="/"
                            />
                        </LandscapeWatch>
                    )}
                />
            </Switch>
            <div
                style={{
                    position: 'absolute',
                    left: '0px',
                    top: '0px',
                    width: '100%',
                    height: '100%',
                    display: pageControllerState.displaySpinner ? 'block' : 'none',
                    zIndex: 100,
                }}
            >
                <SpinnerCircular
                    style={{
                        position: 'fixed',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                    }}
                />
            </div>
        </Router>
    );
}
