import './PageInfoBox.scss';

export default function PageInfoBox({ children }) {
    return (
        <div className="PageInfoBox">
            <div className="Title">
                <span style={{ color: 'rgba(255, 255, 255, 0.51)' }}>PROJECT: </span>
                <span>GROUP TREAD</span>
            </div>
            {children}
        </div>
    );
}
