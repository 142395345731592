// import { useReducer } from 'react';
import { Aggregate, ExponentialAverage } from '../../utils/Aggregate';

export class GameMetrics {
    constructor({ name, startTime }) {
        this._name = name;
        this._startTime = startTime;
        this._totalPauseTime = 0;
        this._currentPause = undefined;
        this._beginningFPS = {
            FIRST_5_SEC: null,
            FIRST_10_SEC: null,
            FIRST_20_SEC: null,
            FIRST_30_SEC: null,
            FIRST_60_SEC: null,
        };
        this._stepCountStats = {
            totalStepCount: 0,
            SECONDS_UNTIL_1_STEPS: null,
            SECONDS_UNTIL_5_STEPS: null,
            SECONDS_UNTIL_10_STEPS: null,
            SECONDS_UNTIL_20_STEPS: null,
            SECONDS_UNTIL_50_STEPS: null,
        };
        this._aggregate = new Aggregate();
        this._expAvg = new ExponentialAverage(30.0);

        this._noDetectionCount = 0;

        this._is_game_completed = false;
    }

    append({ fps, totalStepCount, noDetection }) {
        if (this._is_game_completed) {
            return;
        }

        if (fps) {
            this._aggregate.append(fps);

            this._expAvg.append(fps);

            if (this.elapsedSeconds() >= 5 && !this._beginningFPS.FIRST_5_SEC) {
                this._beginningFPS.FIRST_5_SEC = fps;
            }
            if (this.elapsedSeconds() >= 10 && !this._beginningFPS.FIRST_10_SEC) {
                this._beginningFPS.FIRST_10_SEC = fps;
            }
            if (this.elapsedSeconds() >= 20 && !this._beginningFPS.FIRST_20_SEC) {
                this._beginningFPS.FIRST_20_SEC = fps;
            }
            if (this.elapsedSeconds() >= 30 && !this._beginningFPS.FIRST_30_SEC) {
                this._beginningFPS.FIRST_30_SEC = fps;
            }
            if (this.elapsedSeconds() >= 60 && !this._beginningFPS.FIRST_60_SEC) {
                this._beginningFPS.FIRST_60_SEC = fps;
            }
        }

        if (totalStepCount) {
            this._stepCountStats.totalStepCount = totalStepCount;

            if (totalStepCount >= 1 && !this._stepCountStats.SECONDS_UNTIL_1_STEPS) {
                this._stepCountStats.SECONDS_UNTIL_1_STEPS = this.elapsedSeconds();
            }
            if (totalStepCount >= 5 && !this._stepCountStats.SECONDS_UNTIL_5_STEPS) {
                this._stepCountStats.SECONDS_UNTIL_5_STEPS = this.elapsedSeconds();
            }
            if (totalStepCount >= 10 && !this._stepCountStats.SECONDS_UNTIL_10_STEPS) {
                this._stepCountStats.SECONDS_UNTIL_10_STEPS = this.elapsedSeconds();
            }
            if (totalStepCount >= 20 && !this._stepCountStats.SECONDS_UNTIL_20_STEPS) {
                this._stepCountStats.SECONDS_UNTIL_20_STEPS = this.elapsedSeconds();
            }
            if (totalStepCount >= 50 && !this._stepCountStats.SECONDS_UNTIL_50_STEPS) {
                this._stepCountStats.SECONDS_UNTIL_50_STEPS = this.elapsedSeconds();
            }
        }

        if (noDetection) {
            this._noDetectionCount++;
        }
    }

    gameCompleted() {
        if (this._currentPause) {
            this.resume();
        }
        this._is_game_completed = true;
    }

    pause() {
        this._currentPause = Date.now();
    }

    resume() {
        if (this._currentPause) {
            this._totalPauseTime += Date.now() - this._currentPause;
            this._currentPause = undefined;
        }
    }

    elapsedSeconds() {
        return parseInt((Date.now() - this._startTime - this._totalPauseTime) / 1000);
    }

    get() {
        return {
            game_stats_fps_first_5_second: this._beginningFPS.FIRST_5_SEC
                ? parseFloat(this._beginningFPS.FIRST_5_SEC.toFixed(2))
                : undefined,
            game_stats_fps_first_10_second: this._beginningFPS.FIRST_10_SEC
                ? parseFloat(this._beginningFPS.FIRST_10_SEC.toFixed(2))
                : undefined,
            game_stats_fps_first_20_second: this._beginningFPS.FIRST_20_SEC
                ? parseFloat(this._beginningFPS.FIRST_20_SEC.toFixed(2))
                : undefined,
            game_stats_fps_first_30_second: this._beginningFPS.FIRST_30_SEC
                ? parseFloat(this._beginningFPS.FIRST_30_SEC.toFixed(2))
                : undefined,
            game_stats_fps_first_60_second: this._beginningFPS.FIRST_60_SEC
                ? parseFloat(this._beginningFPS.FIRST_60_SEC.toFixed(2))
                : undefined,
            game_stats_fps_avg: this._aggregate.avg() ? parseFloat(this._aggregate.avg().toFixed(2)) : undefined,
            game_stats_fps_min: this._aggregate.min() ? parseFloat(this._aggregate.min().toFixed(2)) : undefined,
            game_stats_fps_max: this._aggregate.max() ? parseFloat(this._aggregate.max().toFixed(2)) : undefined,
            game_stats_fps_exp_avg: this._expAvg.value() ? parseFloat(this._expAvg.value().toFixed(2)) : undefined,
            game_stats_frame_count: this._aggregate._count,

            // derived from step count
            game_stats_total_step_count: this._stepCountStats.totalStepCount,
            game_stats_seconds_first_1_steps: this._stepCountStats.SECONDS_UNTIL_1_STEPS,
            game_stats_seconds_first_5_steps: this._stepCountStats.SECONDS_UNTIL_5_STEPS,
            game_stats_seconds_first_10_steps: this._stepCountStats.SECONDS_UNTIL_10_STEPS,
            game_stats_seconds_first_20_steps: this._stepCountStats.SECONDS_UNTIL_20_STEPS,
            game_stats_seconds_first_50_steps: this._stepCountStats.SECONDS_UNTIL_50_STEPS,

            // elapsed time
            game_stats_elapsed_seconds: this.elapsedSeconds(),

            // no detection count
            game_stats_no_detection_count: this._noDetectionCount,

            // game status
            game_stats_is_game_completed: this._is_game_completed,
        };
    }
}
