import { useLocation, useHistory } from 'react-router-dom';
import { useEffect, useMemo } from 'react';
import { joinNewGroup } from '../../services/firebase';
import { PageControllerAction } from '../PageController';
import analyticsManager from '../../analytics/AnalyticsManager';
import AnalyticsEvents from '../../analytics/AnalyticsEvents';

export default function Invitation(props) {
    const location = useLocation();
    const history = useHistory();

    // check if the URL contains invitation
    const inviteGroupId = useMemo(() => {
        var pathArray = location.pathname.split('/');
        switch (pathArray[1]) {
            case 'invite':
                return pathArray[2];
            default:
                return null;
        }
    }, [location]);

    const dependencySatisfied = useMemo(() => {
        return props.dependency.outstanding === 0;
    }, [props.dependency]);

    // processing an invitation link
    useEffect(() => {
        if (dependencySatisfied) {
            if (!inviteGroupId) {
                console.warn('Invitation group ID is missing. Navigate to home');
                history.push('/');
                return;
            }

            console.log(`Received invitation to group ${inviteGroupId}`);

            const allTeams = [...props.joinedTeams, ...props.managedTeams];
            const inviteTeam = allTeams.filter((currentTeam) => {
                return currentTeam.id === inviteGroupId;
            })[0];

            if (!inviteTeam) {
                console.log(`joining team ${inviteGroupId} begins`);
                joinNewGroup(props.appUser?.userId, inviteGroupId)
                    .then(() => {
                        console.log(`joining team ${inviteGroupId} success`);
                        analyticsManager().trackEvent(AnalyticsEvents.RECV_INVITATION, {
                            group_id: inviteGroupId,
                            status: 'success',
                            user_id: props.appUser?.userId,
                            user_name: props.appUser?.name || 'undefined',
                        });

                        props.pageController(
                            PageControllerAction.REFRESH_GROUP_LIST(({ joinedTeams, managedTeams }) => {
                                const allTeams = [...(joinedTeams || []), ...(managedTeams || [])];
                                const inviteTeam = allTeams.filter((currentTeam) => {
                                    return currentTeam.id === inviteGroupId;
                                })[0];

                                analyticsManager().trackJoinedGroup(inviteGroupId);
                                analyticsManager().updateGroupProperties(inviteGroupId, {
                                    member_count: inviteTeam.memberCount,
                                });
                                props.pageController(PageControllerAction.SELECT_TEAM_TO_START(inviteTeam));
                                props.pageController(PageControllerAction.SHOW_JOIN_GROUP_BALLOON);
                                history.push('/');
                            })
                        );
                    })
                    .catch((error) => {
                        analyticsManager().trackEvent(AnalyticsEvents.RECV_INVITATION, {
                            group_id: inviteGroupId,
                            status: 'already_joined',
                            user_id: props.appUser?.userId,
                            user_name: props.appUser?.name || 'undefined',
                        });
                        console.warn('Already joined this team, error is caught. Navigate to home');
                        console.error(error);
                        history.push('/');
                        props.pageController(PageControllerAction.SELECT_TEAM_TO_START(inviteTeam));
                        props.pageController(PageControllerAction.SHOW_JOIN_GROUP_BALLOON);
                    });
            } else {
                analyticsManager().trackEvent(AnalyticsEvents.RECV_INVITATION, {
                    group_id: inviteGroupId,
                    status: 'already_joined',
                    user_id: props.appUser?.userId,
                    user_name: props.appUser?.name || 'undefined',
                });

                console.warn('Already joined this team, no error is caught. Navigate to home');
                history.push('/');
                props.pageController(PageControllerAction.SELECT_TEAM_TO_START(inviteTeam));
                props.pageController(PageControllerAction.SHOW_JOIN_GROUP_BALLOON);
            }
        }
        // eslint-disable-next-line
    }, [dependencySatisfied, inviteGroupId]);

    return <div></div>;
}
