import classNames from 'classnames';
import './MinAspectArea.scss';

export default function MinAspectArea({
    children,
    className,
    fullScreen = false,
    defaultBg = false,
    summaryBg = false,
}) {
    return (
        <div className={classNames('MinAspectArea', className, { BackgroundColor: defaultBg || summaryBg })}>
            <div
                className={classNames({
                    FullContent: fullScreen,
                    Content: !fullScreen,
                    DefaultBackground: defaultBg,
                    SummaryBackground: summaryBg,
                })}
            >
                {children}
            </div>
        </div>
    );
}
