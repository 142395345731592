import GameEngine from './GameEngine';

import PoseDetector from '../detectors/PoseDetector';
import { Pose } from '../detectors/Pose';
import { JumpingJackDetector, isPoseQualifiedForRunningDetection } from '../exerciseRep/JumpingJackDetector';
import { isNil, max, last, takeRight, first, min } from 'lodash';

const kProcessFps = 30;
const kProcessTimeGap = 1.0 / kProcessFps;

const kStepsPerSecondThresholdForLooseCheck = 2.0;
const kEnableLooseCheck = false;
const kSpeedUIRefreshFps = 2;
const kWattsOutputConversionRate = 0.5;

const kInitialAvatarCaptureDelay = 1000; // 1000ms.
const kAvatarCaptureMaxDelay = 10000; // We will capture one avatar every 10s.
const kAvatarCaptureDelayMultiplier = 1.5; // After a successful detection, we will increase our delay by this factor.

class ExerciseRepGameEngine extends GameEngine {
    /**
     * Creates an instance of ExerciseRepGameEngine with a loaded PoseDetector.
     * @param {HTMLDivElement} container
     * @param {PoseDetector} detector
     * @memberof ExerciseRepGameEngine
     */
    constructor(container, detector) {
        super(container, detector);

        // Frame Time

        /** @type {number} */
        this._lastProcessTime = 0;

        // Running Detector

        /** @type {JumpingJackDetector} */
        this._repDetector = new JumpingJackDetector();

        // Avatar Capture

        /** @type {Boolean} */
        this._avatarCaptureDelay = kInitialAvatarCaptureDelay;

        /** @type {number} */
        this._nextAvatarTimestamp = Date.now() + kInitialAvatarCaptureDelay;

        // Stats
        this._valuesSnapshot = {
            jumpCount: 0,
            energy: 0,
            combo: 0,
            maxCombo: 0,
            lastJumpFrameTime: undefined,
            lastSpeedUIRefreshTimestamp: 0,
        };
        this._jumpCountHistory = [];
    }

    _needsExtraInfo() {
        const currTime = Date.now();
        if (currTime >= this._nextAvatarTimestamp) {
            this._nextAvatarTimestamp = currTime + this._avatarCaptureDelay;
            return true;
        }
    }

    /**
     * Updates the game state at the given time.
     *
     * @param {number} time
     * @memberof ExerciseRepGameEngine
     */
    _tick(time) {
        this._repDetector.config(this._debugCanvas.width, this._debugCanvas.height);

        const frameTime = time * 0.001;
        if (frameTime < this._lastProcessTime + kProcessTimeGap) {
            // Don't process too fast.
            return;
        }

        this._lastProcessTime = frameTime;
        // Analyze
        this._repDetector.tick(frameTime);

        // Update stats
        const jumpCount = this._repDetector.totalJumpCount();
        const energy = jumpCount * 0.19;
        const kjPerKcal = 4.184;
        const jumpCountDiff = max([0, jumpCount - this._valuesSnapshot.jumpCount]);

        let combo;
        if (
            isNil(this._valuesSnapshot.lastJumpFrameTime) ||
            (frameTime - this._valuesSnapshot.lastJumpFrameTime > 2 && jumpCountDiff === 0)
        ) {
            combo = jumpCountDiff;
        } else {
            combo = this._valuesSnapshot.combo + jumpCountDiff;
        }
        const maxCombo = max([this._valuesSnapshot.maxCombo, combo]);

        this._emit({
            stats: {
                totalJumpCount: jumpCount,
                totalEnergy: energy, // kcal
                totalOutput: energy * kjPerKcal, // kJ
                currentCombo: combo,
                maxCombo: maxCombo,
            },
        });

        this._valuesSnapshot.jumpCount = jumpCount;
        this._valuesSnapshot.energy = energy;
        this._valuesSnapshot.combo = combo;
        this._valuesSnapshot.maxCombo = maxCombo;
        if (jumpCountDiff > 0) {
            this._valuesSnapshot.lastJumpFrameTime = frameTime;
        }

        const historySize = 5;
        const lastHistory = last(this._jumpCountHistory);
        if (isNil(lastHistory?.frameTime) || frameTime - lastHistory.frameTime >= 1) {
            this._jumpCountHistory = takeRight(this._jumpCountHistory, historySize - 1);
            this._jumpCountHistory.push({ jumpCount, frameTime });
        }

        const shouldRefreshSpeedUI =
            frameTime >= this._valuesSnapshot.lastSpeedUIRefreshTimestamp + 1.0 / kSpeedUIRefreshFps;
        if (shouldRefreshSpeedUI) {
            const firstHistory = first(this._jumpCountHistory);
            const lastHistory = last(this._jumpCountHistory);
            const countDiff = lastHistory.jumpCount - firstHistory.jumpCount;
            const timeDiff = lastHistory.frameTime - firstHistory.frameTime;
            const currentValues = {
                currentJPM: timeDiff === 0 ? 0 : (countDiff / timeDiff) * 60,
            };

            this._emit({
                stats: {
                    ...currentValues,
                },
            });

            this._valuesSnapshot.lastSpeedUIRefreshTimestamp = frameTime;
        }
    }

    /**
     * Handles pose detections.
     *
     * @param {Pose[]} poses
     * @param {number} time
     * @memberof ExerciseRepGameEngine
     */
    _handleDetection(poses, time) {
        const frameTime = time * 0.001;

        this._repDetector.handleDetection(poses, frameTime);
    }

    /**
     * Returns true if we think the warning message should be shown based on the latest pose detection.
     *
     * @param {Pose} poses
     * @returns
     * @memberof ExerciseRepGameEngine
     */
    _shouldShowDetectionWarning(poses) {
        const pose = this._repDetector.pickPose(poses);
        const isPoseQualified = isPoseQualifiedForRunningDetection(pose);
        return !isPoseQualified;
    }

    /**
     * This is invoked when _needsExtraInfo() returns before, and the detector actually returned some extra info.
     *
     * @param {any} extraInfo
     * @memberof ExerciseRepGameEngine
     */
    _handleExtraInfo(extraInfo) {
        const oldDelay = this._avatarCaptureDelay;
        this._avatarCaptureDelay = Math.min(
            kAvatarCaptureMaxDelay,
            Math.floor(this._avatarCaptureDelay * kAvatarCaptureDelayMultiplier)
        );
        this._nextAvatarTimestamp += this._avatarCaptureDelay - oldDelay;
        this._emit({
            avatar: extraInfo.avatar,
        });
    }

    /**
     * Creates a new ExerciseRepGameEngine that attaches to the given container.
     *
     * @static
     * @returns
     * @memberof ExerciseRepGameEngine
     */
    static async create({ dispatcher }) {
        // Technically we should use the PoseDetector, but we will skip that for now.
        const detector = await PoseDetector.create();
        await detector.warmup();
        return new ExerciseRepGameEngine(detector, dispatcher);
    }
}

export default ExerciseRepGameEngine;
