import { useEffect, useMemo, useState } from 'react';
import './GroupSelection.scss';
import { Balloon, BalloonType } from './Balloon';
import { PageControllerAction } from './PageController';

function SelectItem({ pageController, currentTeam, setOpenList, selected }) {
    const onClickHandler = (team) => {
        return () => {
            pageController(PageControllerAction.SELECT_TEAM_TO_START(team));
            setOpenList(false);
        };
    };
    return (
        <div className="team-display" onClick={onClickHandler(currentTeam)}>
            <div className="icon group-icon" />
            <div className="name">{currentTeam.name}</div>
            {selected ? <div className="icon checked-dark" /> : <div></div>}
        </div>
    );
}

export function GroupSelection(props) {
    const { teamsForStartRun, pageController, pageControllerState } = props;
    const [openList, setOpenList] = useState(false);

    const showJoinGroupBalloon = useMemo(() => {
        return !!props.pageControllerState.joinGroupBalloon;
    }, [props.pageControllerState.joinGroupBalloon]);

    const sortedTeams = useMemo(() => {
        return teamsForStartRun
            ? [...teamsForStartRun].sort((a, b) => {
                  if (a < b) return -1;
                  if (a > b) return 1;
                  return 0;
              })
            : null;
    }, [teamsForStartRun]);

    useEffect(() => {
        if (sortedTeams && sortedTeams.length > 0) {
            if (!pageControllerState.selectedTeamToStart) {
                pageController(PageControllerAction.SELECT_TEAM_TO_START(sortedTeams[0]));
            }
        }
        // eslint-disable-next-line
    }, [sortedTeams, pageControllerState.selectedTeamToStart]);

    const reorderedTeams = useMemo(() => {
        if (pageControllerState.selectedTeamToStart && sortedTeams) {
            if (sortedTeams.length === 1) {
                return sortedTeams;
            }
            const teamsWithoutSelectedTeam = sortedTeams.filter(
                (gp) => pageControllerState.selectedTeamToStart.id !== gp.id
            );
            return [pageControllerState.selectedTeamToStart, ...teamsWithoutSelectedTeam];
        } else {
            return null;
        }
    }, [pageControllerState.selectedTeamToStart, sortedTeams]);

    const entireAreaStyle = useMemo(() => {
        return teamsForStartRun && teamsForStartRun.length > 0 ? {} : { display: 'none' };
    }, [teamsForStartRun]);

    const openListStyle = useMemo(() => {
        return openList ? {} : { display: 'none' };
    }, [openList]);

    const primaryListClickHandler = () => {
        setOpenList(!openList);
    };

    const newGroupBalloonProps = {
        style: {
            marginLeft: '30px',
            marginTop: '16px',
        },
        type: BalloonType.NORTH,
        title: 'You have joined new group.',
        content: 'Swtich between groups by using the drop down menu.',
        onClickHandler: () => {
            props.pageController(PageControllerAction.DISMISS_JOIN_GROUP_BALLOON);
        },
    };

    return (
        <div className="group-selection" style={entireAreaStyle}>
            <div className="team-display-area" onClick={primaryListClickHandler}>
                <div className="team-display">
                    <div className="icon group-icon" />
                    <div className="name">{pageControllerState.selectedTeamToStart?.name || ''}</div>
                    <div className="icon arrow-down-dark" />
                </div>
            </div>

            <div className="team-display-area" style={openListStyle}>
                {reorderedTeams &&
                    reorderedTeams.map((currentTeam) => (
                        <SelectItem
                            key={currentTeam.id}
                            pageController={props.pageController}
                            selected={currentTeam.id === pageControllerState.selectedTeamToStart?.id}
                            currentTeam={currentTeam}
                            setOpenList={setOpenList}
                        />
                    ))}
            </div>

            {showJoinGroupBalloon && <Balloon {...newGroupBalloonProps} />}
        </div>
    );
}
