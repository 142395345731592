import './GroupTableFilter.scss';
import classNames from 'classnames';
import { useState, useMemo } from 'react';
import TimeRangeFilterType from './TimeRangeFilterType';

export default function GroupTableFilter({ groupTableFilterState, filterChangeHandler, theme = '' }) {
    const [openFilter, setOpenFilter] = useState(false); // expected value: boolean

    function openMenuHandler(event) {
        event.stopPropagation();
        setOpenFilter(!openFilter);
    }

    function selectFilterHandler(value) {
        return (event) => {
            event.stopPropagation();
            setOpenFilter(false);
            filterChangeHandler && filterChangeHandler(value);
        };
    }

    return (
        <div className={classNames('group-table-filter', theme)}>
            <div className="selected-filter" onClick={openMenuHandler}>
                <div className="filter-text">{groupTableFilterState}</div>
                <div className="icon arrow-down-light" />
            </div>

            <div className={classNames('filter-area', { hidden: !openFilter })}>
                {TimeRangeFilterType.allCases.map((f) => {
                    return (
                        <div className="selected-filter" key={f}>
                            <div className="filter-text" onClick={selectFilterHandler(f)}>
                                {f}
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}
