// A settings page.

// Styles
import './SettingsPage.scss';

function FieldSet({ value, spec, setter, keys }) {
    // First we need the description.
    // After that, we need to list out the bare fields.
    // After that, we list the subsets.
    const prefix = keys.length === 0 ? '' : keys.join('.') + '.';
    // We are going to list out the fields.
    const fieldComponents = [];
    for (const [key, field] of Object.entries(spec.fields)) {
        fieldComponents.push(field.getInput(setter));
    }
    const subsetComponents = [];
    for (const [key, subset] of Object.entries(spec.subsets)) {
        subsetComponents.push(
            <FieldSet key={prefix + key} value={value[key]} spec={subset} setter={setter} keys={keys.concat([key])} />
        );
    }

    const level = keys.length;
    return (
        <div className={`settings level-${level}`}>
            <span className="heading">{spec.description}</span>
            {fieldComponents}
            {subsetComponents}
        </div>
    );
}

export default function Settings({ value, setter, spec }) {
    return (
        <div>
            <form>
                <FieldSet value={value} spec={spec} setter={setter} keys={[]} />
            </form>
        </div>
    );
}
