import BasicMetricsDashboard, { MetricsType } from './BasicMetricsDashboard';
import GoalDashboard from './GoalDashboard';
import './JumpRopeDashboard.scss';
import JumpRopeSecondaryDashboard from './JumpRopeSecondaryDashboard';

export default function JumpRopeDashboard({ stats, goal, goalProgressPercent }) {
    return (
        <div className="JumpRopeDashboard">
            <GoalDashboard goal={goal} goalProgressPercent={goalProgressPercent} />
            <div className="BasicMetrics">
                <BasicMetricsDashboard activityState={stats} metricsType={MetricsType.JUMPS} />
                <BasicMetricsDashboard activityState={stats} metricsType={MetricsType.TIME} />
                <BasicMetricsDashboard activityState={stats} metricsType={MetricsType.CALORIES} />
            </div>

            <JumpRopeSecondaryDashboard stats={stats} />
        </div>
    );
}
