import CopyInviteLinkButton from './CopyInviteLinkButton';
import './GroupTableHeader.scss';
import { PageControllerAction } from './PageController';
import { NotificationType } from './Notification';
import createInviteLink from '../utils/InviteLink';
import copyToClipboard from '../utils/Clipboard';
import GroupTableFilter from './GroupTableFilter';

export default function GroupTableHeader({ group, pageController, groupTableFilterState, setGroupTableFilterState }) {
    const inviteClickHandler = () => {
        copyToClipboard(createInviteLink(group.id));
        pageController(PageControllerAction.SET_NOTIFICATION(NotificationType.INVITE_LINK));
    };

    return (
        <div className="group-table-header">
            <div className="group-metadata">
                <div className="group-name">{group.name}</div>
                <div className="group-members">Members: {group.memberCount}</div>
            </div>
            <GroupTableFilter
                groupTableFilterState={groupTableFilterState}
                filterChangeHandler={(value) => {
                    setGroupTableFilterState(value);
                    pageController(PageControllerAction.SELECT_GROUP_TIME_FILTER(value));
                }}
            />
            <CopyInviteLinkButton onClickHandler={inviteClickHandler} />
        </div>
    );
}
