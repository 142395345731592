import './GroupTableBody.scss';
import { GroupHeaderType, GroupSortingOrder, overallTab } from './GroupTable';
import GroupTableSortingHeader from './GroupTableSortingHeader';
import { ActivityId } from '../ActivityId';
import { map, get, isNil } from 'lodash';
import { useMemo } from 'react';
import { UserStats } from '../services/session';

function timeString(seconds) {
    const totalMinutes = Math.floor(seconds / 60);
    const totalRemainderSeconds = Math.floor(seconds) % 60;
    return totalMinutes.toFixed(0) + ':' + (totalRemainderSeconds < 10 ? '0' : '') + totalRemainderSeconds.toFixed(0);
}

function decimalString(val, decimals) {
    return Intl.NumberFormat('en-US', { minimumFractionDigits: 0, maximumFractionDigits: decimals }).format(val);
}

function nameString(val, maxLength = 10) {
    return val;
}

function GroupDetailsRow({ rowData, selectedTab, showRank }) {
    let columns;
    switch (selectedTab) {
        case overallTab:
            columns = [
                {
                    type: GroupHeaderType.TIME,
                    value: timeString(rowData.stats.aggregatedDuration),
                },
                {
                    type: GroupHeaderType.CALORIES,
                    value: decimalString(rowData.stats.aggregatedEnergy, 0),
                },
                {
                    type: GroupHeaderType.JOG_DURATION,
                    value: timeString(rowData.stats.duration),
                },
                {
                    type: GroupHeaderType.JOG_SESSION_COUNT,
                    value: decimalString(rowData.stats.sessionCount, 0),
                },
                {
                    type: GroupHeaderType.JUMP_ROPE_DURATION,
                    value: timeString(rowData.stats.jumpRope.duration),
                },
                {
                    type: GroupHeaderType.JUMP_ROPE_SESSION_COUNT,
                    value: decimalString(rowData.stats.jumpRope.sessionCount, 0),
                },
                {
                    type: GroupHeaderType.JUMPING_JACK_DURATION,
                    value: timeString(rowData.stats.jumpingJack.duration),
                },
                {
                    type: GroupHeaderType.JUMPING_JACK_SESSION_COUNT,
                    value: decimalString(rowData.stats.jumpingJack.sessionCount, 0),
                },
            ];
            break;
        case ActivityId.TreadMill:
            columns = [
                {
                    type: GroupHeaderType.CALORIES,
                    value: decimalString(rowData.stats.energy, 0),
                },
                {
                    type: GroupHeaderType.TIME,
                    value: timeString(rowData.stats.duration),
                },
                {
                    type: GroupHeaderType.STEP,
                    value: decimalString(rowData.stats.steps, 0),
                },
                {
                    type: GroupHeaderType.DISTANCE,
                    value: decimalString(rowData.stats.distance, 1),
                },
                {
                    type: GroupHeaderType.OUTPUT,
                    value: decimalString(rowData.stats.output, 1),
                },
                {
                    type: GroupHeaderType.PACE,
                    value: timeString(rowData.stats.pace),
                },
                {
                    type: GroupHeaderType.SPEED,
                    value: decimalString(rowData.stats.speed, 1),
                },
            ];
            break;
        case ActivityId.JumpRope:
        case ActivityId.JumpingJack:
            const prefix = UserStats.getActivityPrefix(selectedTab);
            columns = [
                {
                    type: GroupHeaderType.JUMP,
                    value: decimalString(rowData.stats[prefix].jumps, 0),
                },
                {
                    type: GroupHeaderType.COMBO,
                    value: decimalString(rowData.stats[prefix].maxCombo, 0),
                },
                {
                    type: GroupHeaderType.TIME,
                    value: timeString(rowData.stats[prefix].duration),
                },
                {
                    type: GroupHeaderType.CALORIES,
                    value: decimalString(rowData.stats[prefix].energy, 1),
                },
                {
                    type: GroupHeaderType.JPM,
                    value: decimalString(rowData.stats[prefix].jpm, 1),
                },
            ];
            break;
        default:
            columns = [];
            break;
    }
    return (
        <tr className="row" key={`row-rank-${rowData.rank}`}>
            {showRank && (
                <td className="cell RANK" key="RANK">
                    <span>{rowData.rank}</span>
                </td>
            )}
            <td
                className="AVATAR"
                key="AVATAR"
                style={{
                    backgroundImage: `url(${rowData.avatar})`,
                }}
            ></td>
            <td className="cell NAME" key="NAME">
                {nameString(rowData.name)}
            </td>
            {map(columns, (column) => {
                return (
                    <td className={'cell ' + column.type} key={column.type}>
                        <span>{column.value}</span>
                    </td>
                );
            })}
        </tr>
    );
}

export default function GroupTableBody({ groupList, groupTableSorting, setGroupTableSorting, selectedTab }) {
    const rankedGroupList = useMemo(() => {
        const sorted = groupList.sort((a, b) => {
            switch (selectedTab) {
                case ActivityId.TreadMill:
                    return b.stats.steps - a.stats.steps;
                case ActivityId.JumpRope:
                    return b.stats.jumpRope.jumps - a.stats.jumpRope.jumps;
                case ActivityId.JumpingJack:
                    return b.stats.jumpingJack.jumps - a.stats.jumpingJack.jumps;
                case overallTab:
                    return b.stats.aggregatedEnergy - a.stats.aggregatedEnergy;
                default:
                    return ('' + a.name).localeCompare(b.name);
            }
        });
        for (const [index, elements] of sorted.entries()) {
            elements.rank = index + 1;
        }
        return sorted;
    }, [groupList, selectedTab]);

    let showRank;
    let columnKeypathMap;
    switch (selectedTab) {
        case ActivityId.TreadMill:
            showRank = true;
            columnKeypathMap = {
                [GroupHeaderType.RANK]: 'rank',
                [GroupHeaderType.CALORIES]: 'stats.energy',
                [GroupHeaderType.DISTANCE]: 'stats.distance',
                [GroupHeaderType.TIME]: 'stats.duration',
                [GroupHeaderType.STEP]: 'stats.steps',
                [GroupHeaderType.OUTPUT]: 'stats.output',
                [GroupHeaderType.PACE]: 'stats.pace',
                [GroupHeaderType.SPEED]: 'stats.speed',
            };
            break;
        case ActivityId.JumpRope:
            showRank = true;
            columnKeypathMap = {
                [GroupHeaderType.RANK]: 'rank',
                [GroupHeaderType.JUMP]: 'stats.jumpRope.jumps',
                [GroupHeaderType.COMBO]: 'stats.jumpRope.maxCombo',
                [GroupHeaderType.TIME]: 'stats.jumpRope.duration',
                [GroupHeaderType.CALORIES]: 'stats.jumpRope.energy',
                [GroupHeaderType.JPM]: 'stats.jumpRope.jpm',
            };
            break;
        case ActivityId.JumpingJack:
            showRank = true;
            columnKeypathMap = {
                [GroupHeaderType.RANK]: 'rank',
                [GroupHeaderType.JUMP]: 'stats.jumpingJack.jumps',
                [GroupHeaderType.COMBO]: 'stats.jumpingJack.maxCombo',
                [GroupHeaderType.TIME]: 'stats.jumpingJack.duration',
                [GroupHeaderType.CALORIES]: 'stats.jumpingJack.energy',
                [GroupHeaderType.JPM]: 'stats.jumpingJack.jpm',
            };
            break;
        case overallTab:
            showRank = false;
            columnKeypathMap = {
                [GroupHeaderType.TIME]: 'stats.aggregateDuation',
                [GroupHeaderType.CALORIES]: 'stats.aggregatedEnergy',
                [GroupHeaderType.JOG_DURATION]: 'stats.duration',
                [GroupHeaderType.JOG_SESSION_COUNT]: 'stats.sessionCount',
                [GroupHeaderType.JUMP_ROPE_DURATION]: 'stats.jumpRope.duration',
                [GroupHeaderType.JUMP_ROPE_SESSION_COUNT]: 'stats.jumpRope.sessionCount',
                [GroupHeaderType.JUMPING_JACK_DURATION]: 'stats.jumpingJack.duration',
                [GroupHeaderType.JUMPING_JACK_SESSION_COUNT]: 'stats.jumpingJack.sessionCount',
            };
            break;
        default:
            showRank = false;
            columnKeypathMap = {};
            break;
    }

    const sortedList = rankedGroupList.sort((a, b) => {
        const defaultSort = a.rank - b.rank;
        if (isNil(groupTableSorting?.type)) {
            return defaultSort;
        }
        switch (groupTableSorting.type) {
            case GroupHeaderType.NAME:
                switch (groupTableSorting.status) {
                    case GroupSortingOrder.ASCENDING:
                        return ('' + a.name).localeCompare(b.name);
                    case GroupSortingOrder.DESCENDING:
                        return ('' + b.name).localeCompare(a.name);
                    default:
                        return defaultSort;
                }

            default:
                const keypath = columnKeypathMap[groupTableSorting.type];
                if (isNil(keypath)) {
                    return defaultSort;
                }
                switch (groupTableSorting.status) {
                    case GroupSortingOrder.ASCENDING:
                        return get(a, keypath, 0) - get(b, keypath, 0);
                    case GroupSortingOrder.DESCENDING:
                        return get(b, keypath, 0) - get(a, keypath, 0);
                    default:
                        return defaultSort;
                }
        }
    });
    return (
        <div className="table-layer">
            <table>
                <GroupTableSortingHeader
                    groupTableSorting={groupTableSorting}
                    setGroupTableSorting={setGroupTableSorting}
                    selectedTab={selectedTab}
                />
                <tbody className="group-table-body">
                    {sortedList.map((rowData) => GroupDetailsRow({ rowData, selectedTab, showRank }))}
                </tbody>
            </table>
        </div>
    );
}
