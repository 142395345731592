import firebase from 'firebase/app';
import 'firebase/analytics';
import 'firebase/auth';
import 'firebase/firestore';
import { isNil, mapValues, reduce } from 'lodash';
import { GroupRole, createGroupAsync, joinGroupAsync, getGroupRef, Group } from './group';
import { getUserGroupsAsync, getUserRef } from './user';
import TimeRangeFilterType from '../components/TimeRangeFilterType';
import { getAllTimeStatsRef, UserStats, getTodayStatsRef, getThisWeekStatsRef, getThisMonthStatsRef } from './session';

const config = {
    apiKey: 'AIzaSyChhXOuikO3AjKDc-fH5ap1qSNSzdXukM8',
    authDomain: 'moving-platform-dev.firebaseapp.com',
    projectId: 'moving-platform-dev',
    storageBucket: 'moving-platform-dev.appspot.com',
    messagingSenderId: '31377743670',
    appId: '1:31377743670:web:450b9743da3c85de279d97',
    measurementId: 'G-S0GC69ZRWV',
};

firebase.initializeApp(config);
firebase.analytics();

export const auth = firebase.auth;
export const db = firebase.firestore();
db.settings({ timestampInSnapshot: true });

var dataCache = {
    GROUP_MEMBER_LISTS: [],
    JOINED_GROUPS: [],
    MANAGED_GROUPS: [],
};

export async function fetchGroupMemberList(teamId, timeFilter) {
    const groupData = await getGroupRef(teamId).get().then(getExistData);
    const group = Group.fromJSON(groupData);
    const memberIds = Object.keys(group.members);
    const promises = [];
    const output = {};
    for (const memberId of memberIds) {
        output[memberId] = {};
        const getUser = getUserRef(memberId)
            .get()
            .then(getData)
            .then((user) => (output[memberId].user = user));
        promises.push(getUser);

        let statsRef;
        switch (timeFilter) {
            case TimeRangeFilterType.ALL_TIME:
                statsRef = getAllTimeStatsRef(memberId);
                break;
            case TimeRangeFilterType.THIS_MONTH:
                statsRef = getThisMonthStatsRef(memberId);
                break;
            case TimeRangeFilterType.THIS_WEEK:
                statsRef = getThisWeekStatsRef(memberId);
                break;
            case TimeRangeFilterType.TODAY:
                statsRef = getTodayStatsRef(memberId);
                break;
            default:
                output[memberId].stats = {};
                continue;
        }
        const getStats = statsRef
            .get()
            .then(getExistData)
            .then(
                (stats) => {
                    output[memberId].stats = UserStats.fromJSON(stats);
                },
                () => {
                    output[memberId].stats = new UserStats();
                }
            );
        promises.push(getStats);
    }
    await Promise.all(promises);
    const unsorted = reduce(
        output,
        (result, value, key) => {
            const rowData = {
                userId: key,
                avatar: value.user.avatar,
                name: value.user.name,
                stats: value.stats,
            };
            result.push(rowData);
            return result;
        },
        []
    );
    return unsorted;
}

export function fetchGroupList(userId) {
    console.log(`Calling fetchGroupList, user Id = ${userId}`);

    if (dataCache.MANAGED_GROUPS.length === 0 || dataCache.JOINED_GROUPS.length === 0) {
        var result = { managed: [], joined: [] };
        return getUserGroupsAsync(userId)
            .then((groups) => {
                const managed = groups.filter(
                    (gp) => gp.members[userId] && gp.members[userId].role === GroupRole.ADMIN
                );
                const joined = groups.filter(
                    (gp) => gp.members[userId] && gp.members[userId].role === GroupRole.MEMBER
                );
                dataCache.MANAGED_GROUPS = managed;
                dataCache.JOINED_GROUPS = joined;
                result = {
                    managed,
                    joined,
                };
            })
            .catch((error) => {
                console.error('Caught error in fetchGroupList!');
                console.error(error);
            })
            .then(() => {
                return result;
            });
    }
    return new Promise((fulfill, reject) => {
        fulfill({
            managed: dataCache.MANAGED_GROUPS,
            joined: dataCache.JOINED_GROUPS,
        });
    });
}

export async function createNewGroup(userId, groupName) {
    const group = await createGroupAsync(userId, groupName);
    dataCache.MANAGED_GROUPS.unshift(group);
    return group;
}

export function joinNewGroup(uid, gid) {
    return joinGroupAsync(uid, gid);
}

export const ServiceError = {
    SNAPSHOT_NOT_EXIST: new Error('Snapshot not exist'),
};

export function getExistData(snapshot) {
    const data = snapshot.data();
    if (isNil(data)) {
        throw ServiceError.SNAPSHOT_NOT_EXIST;
    }
    return data;
}

export function getData(snapshot) {
    return snapshot.data();
}
