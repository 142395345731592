const { Pose, kChestNodeIndex, kPoseNodeDetected } = require('../detectors/Pose');
const { gaussian } = require('../pose/BasicUtils');

/**
 * Pick the frame-center pose from a list of poses.
 *
 * @param {Pose[]} poses
 * @param {number} frameWidth
 * @returns {Pose}
 */
export function pickCenterPose(poses, frameWidth) {
    const centerX = frameWidth * 0.5;
    let bestScore = -1;
    let bestPose = null;
    for (const pose of poses) {
        const chest = pose.nodes[kChestNodeIndex];
        if (chest.validLevel === kPoseNodeDetected) {
            const toCenterDistance = Math.abs(centerX - chest.x);
            const toCenterDistanceSigma = frameWidth * 0.2;
            const distanceScore = gaussian(toCenterDistance, toCenterDistanceSigma);

            if (distanceScore > bestScore) {
                bestScore = distanceScore;
                bestPose = pose;
            }
        }
    }

    return bestPose;
}
