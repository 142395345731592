import { GroupDialogBox, UsernameDialogBox, SelectGoalModeDialogBox, DialogBoxAction } from './DialogBox';
import SelectGoalTypeDialogBox from './SelectGoalTypeDialogBox';
import { useEffect, useMemo, useState } from 'react';

export const DIALOG_REQUIREMENTS = {
    GROUP_NAME: 'GROUP_NAME',
    USER_NAME: 'USER_NAME',
    SELECT_GOAL_MODE: 'SELECT_GOAL_MODE',
    SELECT_GOAL_TYPE: 'SELECT_GOAL_TYPE',
};

export function DialogBoxController({ DialogButton, onComplete, requirements = [], classnames, buttonStyle, activityId }) {
    const [inputs, setInputs] = useState({});
    const [step, setStep] = useState(null);

    const resetState = () => {
        // reset all states
        setInputs([]);
        setStep(null);
    };

    useEffect(() => {
        if (step === null) {
            resetState();
        } else if (step === requirements.length) {
            // conditions satisfied to submit
            onComplete && onComplete(inputs);
            // reset all states
            resetState();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [step]);

    const nextStep = (values) => {
        var myInputs = { ...inputs };
        values.forEach((value, idx) => {
            if (step + idx < requirements.length) {
                myInputs[requirements[step + idx]] = value;
            }
        });
        setInputs(myInputs);
        setStep(step + values.length);
    };

    const goBackOneStep = () => {
        inputs[step] = undefined;
        setInputs(inputs);
        setStep(step - 1 >= 0 ? step - 1 : null);
    };

    const quit = () => {
        setStep(null);
    };

    const latestResult = useMemo(() => {
        return inputs.length > 0 ? inputs[inputs.length - 1] : null;
    }, [inputs]);

    const dialogDismissed = (result) => {
        switch (result.action) {
            case DialogBoxAction.BACKGROUND:
            case DialogBoxAction.CLOSE_BUTTON:
                quit();
                break;

            case DialogBoxAction.NEXT:
                nextStep([result.value]);
                break;

            case DialogBoxAction.FREEFORM_WORKOUT:
                nextStep([
                    result.value,
                    null, // skip the next step, i.e., the goal type selection dialog
                ]);
                break;

            case DialogBoxAction.TARGETED_WORKOUT:
                nextStep([result.value]);
                break;

            case DialogBoxAction.BACK:
                goBackOneStep();
                break;

            default:
                break;
        }
    };
    return (
        <div className={classnames}>
            <DialogButton
                style={buttonStyle || {}}
                onClickHandler={() => {
                    setStep(0);
                }}
            />
            {requirements[step] === DIALOG_REQUIREMENTS.GROUP_NAME && (
                <GroupDialogBox
                    initialInput={inputs[DIALOG_REQUIREMENTS.GROUP_NAME] || ''}
                    onDismiss={dialogDismissed}
                />
            )}
            {requirements[step] === DIALOG_REQUIREMENTS.USER_NAME && (
                <UsernameDialogBox
                    initialInput={inputs[DIALOG_REQUIREMENTS.USER_NAME] || ''}
                    onDismiss={dialogDismissed}
                />
            )}
            {requirements[step] === DIALOG_REQUIREMENTS.SELECT_GOAL_MODE && (
                <SelectGoalModeDialogBox onDismiss={dialogDismissed} />
            )}

            {requirements[step] === DIALOG_REQUIREMENTS.SELECT_GOAL_TYPE && (
                <SelectGoalTypeDialogBox activityId={activityId} onDismiss={dialogDismissed} />
            )}
        </div>
    );
}
