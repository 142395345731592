import { PageController, PageControllerAction } from '../components/PageController';
import { createNewGroup } from '../services/firebase';
import analyticsManager from '../analytics/AnalyticsManager';

export default async function submit({
    pageController,
    appUser,
    userUpdater,
    inputUserName,
    inputGroupName,
    startWithCreateGroup,
}) {
    if (inputUserName && inputUserName !== appUser?.name) {
        console.log(`utils/SubmitInput: process input username: ${inputUserName}`);
        userUpdater({ name: inputUserName });
    }
    if (inputGroupName) {
        console.log(`utils/SubmitInput: process input group name: ${inputGroupName}`);
        pageController(PageControllerAction.SHOW_SPINNER);
        const group = await createNewGroup(appUser?.userId, inputGroupName);
        analyticsManager().trackCreateGroup({
            creator_id: appUser?.userId,
            creator_name: appUser?.name,
            group_id: group.id,
            group_name: group.name,
        });
        analyticsManager().trackJoinedGroup(group.id);
        analyticsManager().updateGroupProperties(group.id, {
            group_id: group.id,
            group_name: group.name,
            creator_id: appUser?.userId,
            creator_name: appUser?.Name,
            member_count: group.memberCount,
        });
        pageController(PageControllerAction.DISMISS_SPINNER);
        startWithCreateGroup && pageController(PageControllerAction.SELECT_TEAM_TO_START(group));
        return group;
    }
    return {};
}
