import './GroupList.scss';
import InviteButton from './InviteButton';
import { NotificationType, NotificationAnimationPhase } from './Notification';
import { Balloon, BalloonType } from './Balloon';
import { useEffect, useMemo, useRef } from 'react';
import { GroupListType } from './GroupList';
import { PageControllerAction } from './PageController';
import createInviteLink from '../utils/InviteLink';
import copyToClipboard from '../utils/Clipboard';
import analyticsManager from '../analytics/AnalyticsManager';

export default function GroupListRow(props) {
    const divClassName = 'group-row' + (props.selected ? ' selected' : '');
    const ref = useRef();

    useEffect(() => {
        ref?.current && ref.current.scrollIntoView();
    }, [ref]);

    const inviteClickHandler = () => {
        copyToClipboard(createInviteLink(props.row.id));
        props.pageController(PageControllerAction.SET_NOTIFICATION(NotificationType.INVITE_LINK));
    };

    const newGroupBalloonProps = {
        style: {
            marginTop: '-40px',
            marginLeft: '280px',
        },
        type: BalloonType.WEST,
        title: 'New group has been created.',
        content: 'To invite new members, copy and share the invitation link.',
        onClickHandler: () => {
            props.pageController(PageControllerAction.DISMISS_NEW_GROUP_BALLOON);
        },
    };

    const showNewGroupBalloon = useMemo(() => {
        return props.pageControllerState.newGroupBalloon === props.row.id;
        // eslint-disable-next-line
    }, [props.pageControllerState.newGroupBalloon]);

    return (
        <div
            ref={props.selected ? ref : null}
            key={`group-${props.row.id}-${props.type}`}
            className={divClassName}
            onClick={(event) => {
                event.stopPropagation();

                if (props.pageControllerState.displayGroup?.id === props.row.id) {
                    props.pageController(PageControllerAction.DISMISS_MEMBER_LIST);
                } else {
                    const isOwner = props.managedTeams.filter((gp) => gp.id === props.row.id).length > 0;
                    analyticsManager().trackVisitGroup(
                        props.pageControllerState.displayGroup?.id || 'undefined',
                        props.pageControllerState.displayGroup?.name || 'undefined',
                        isOwner
                    );
                    props.pageController(PageControllerAction.SHOW_MEMBER_LIST(props.row));
                    props.pageController(PageControllerAction.SHOW_SPINNER);
                }
            }}
        >
            <div className="group-metadata">
                <div className="group-name">{props.row.name}</div>
                <div className="group-member-count">Members: {props.row.memberCount}</div>
                {showNewGroupBalloon && <Balloon {...newGroupBalloonProps} />}
            </div>
            {props.type === GroupListType.MANAGE && <InviteButton onClickHandler={inviteClickHandler} />}
        </div>
    );
}
