import './SideBarProfileButton.scss';
import { split, map, reduce } from 'lodash';
import { isNil, isEmpty } from 'lodash';

export default function SideBarProfileButton({ appUser, onClick }) {
    return (
        <div className="SideBarProfileButton" onClick={onClick}>
            <div className="Monogram">{getMonogram(appUser?.name)}</div>
            <div className="OnlineDot" />
        </div>
    );
}

function getMonogram(name) {
    if (isNil(name)) {
        return '';
    }
    const removeMultiSpace = name.replace(/ /g, '');
    return reduce(
        map(split(removeMultiSpace, ' ', 1), (x) => x.substring(0, 1)),
        (result, e) => result + e,
        ''
    );
}
