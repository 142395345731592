import GameEngine from './GameEngine';

import PoseDetector from '../detectors/PoseDetector';
import { Pose } from '../detectors/Pose';
import { RunningDetector, isPoseQualifiedForRunningDetection } from '../running/RunningDetector';

const kProcessFps = 30;
const kProcessTimeGap = 1.0 / kProcessFps;

const kStepsPerSecondThresholdForLooseCheck = 2.0;
const kEnableLooseCheck = false;
const kSpeedUIRefreshFps = 2;
const kWattsOutputConversionRate = 0.5;

const kInitialAvatarCaptureDelay = 1000; // 1000ms.
const kAvatarCaptureMaxDelay = 10000; // We will capture one avatar every 10s.
const kAvatarCaptureDelayMultiplier = 1.5; // After a successful detection, we will increase our delay by this factor.

class TreadmillGameEngine extends GameEngine {
    /**
     * Creates an instance of TreadmillGameEngine with a loaded PoseDetector.
     * @param {HTMLDivElement} container
     * @param {PoseDetector} detector
     * @memberof TreadmillGameEngine
     */
    constructor(container, detector) {
        super(container, detector);

        // Frame Time

        /** @type {number} */
        this._lastProcessTime = 0;

        // Running Detector

        /** @type {RunningDetector} */
        this._runningDetector = new RunningDetector();

        // Avatar Capture

        /** @type {Boolean} */
        this._avatarCaptureDelay = kInitialAvatarCaptureDelay;

        /** @type {number} */
        this._nextAvatarTimestamp = Date.now() + kInitialAvatarCaptureDelay;

        // Stats

        /** @type {number} */
        this._lastSpeedUIRefreshTimestamp = 0;

        this._maxValues = {
            // maxCadence: 0.0,
            maxSpeed: 0.0,
            maxOutput: 0.0,
        };

        this._minValues = {
            minPace: Number.MAX_VALUE,
        };
    }

    _needsExtraInfo() {
        const currTime = Date.now();
        if (currTime >= this._nextAvatarTimestamp) {
            this._nextAvatarTimestamp = currTime + this._avatarCaptureDelay;
            return true;
        }
    }

    /**
     * Updates the game state at the given time.
     *
     * @param {number} time
     * @memberof TreadmillGameEngine
     */
    _tick(time) {
        this._runningDetector.config(this._debugCanvas.width, this._debugCanvas.height);

        const frameTime = time * 0.001;
        if (frameTime < this._lastProcessTime + kProcessTimeGap) {
            // Don't process too fast.
            return;
        }

        this._lastProcessTime = frameTime;

        // Analyze
        this._runningDetector.tick(frameTime);

        // Update stats
        const totalDistance = this._runningDetector.totalDistance();
        const stepCount = this._runningDetector.stepCount();
        const stepsPerSecond = this._runningDetector.smoothedStepsPerSecond();
        const metersPerSecond = this._runningDetector.smoothedMeterPerSecond();
        const caloriesPerStep = (11.0 / 127.0) * 0.5 * 1000;
        const caloriesPerSecond = stepsPerSecond * caloriesPerStep;
        const joulesPerCalories = 4.184;
        const watts = caloriesPerSecond * joulesPerCalories * kWattsOutputConversionRate;
        const metersToMiles = 0.000621371;
        const meterPerSecondToMPH = 3.6;
        const timePerMeter = 1.0 / metersPerSecond;
        const timePerMiles = timePerMeter / metersToMiles;
        const timePerKm = timePerMeter * 1000;

        this._emit({
            stats: {
                totalStepCount: stepCount,
                totalDistance: totalDistance * metersToMiles,
                totalEnergy: stepCount * caloriesPerStep * 0.001, // kCal
                totalOutput: stepCount * caloriesPerStep * joulesPerCalories * kWattsOutputConversionRate * 0.001, // kj
            },
        });

        const shouldRefreshSpeedUI = frameTime >= this._lastSpeedUIRefreshTimestamp + 1.0 / kSpeedUIRefreshFps;
        if (shouldRefreshSpeedUI) {
            const currentValues = {
                // currentCadence: stepsPerSecond * 60.0,
                currentSpeed: metersPerSecond * meterPerSecondToMPH,
                currentOutput: watts,
                currentPace: timePerMiles,
            };

            this._maxValues = {
                // maxCadence: Math.max(this._maxValues.maxCadence, currentValues.currentCadence),
                maxSpeed: Math.max(this._maxValues.maxSpeed, currentValues.currentSpeed),
                maxOutput: Math.max(this._maxValues.maxOutput, currentValues.currentOutput),
            };

            this._minValues = {
                minPace: Math.min(this._minValues.minPace, currentValues.currentPace),
            };

            this._emit({
                stats: {
                    ...currentValues,
                    ...this._maxValues,
                    ...this._minValues,
                },
            });

            this._lastSpeedUIRefreshTimestamp = frameTime;
        }
    }

    /**
     * Handles pose detections.
     *
     * @param {Pose[]} poses
     * @param {number} time
     * @memberof TreadmillGameEngine
     */
    _handleDetection(poses, time) {
        const frameTime = time * 0.001;

        this._runningDetector.handleDetection(poses, frameTime);
    }

    /**
     * Returns true if we think the warning message should be shown based on the latest pose detection.
     *
     * @param {Pose} poses
     * @returns
     * @memberof TreadmillGameEngine
     */
    _shouldShowDetectionWarning(poses) {
        const pose = this._runningDetector.pickPose(poses);
        const isPoseQualified = isPoseQualifiedForRunningDetection(pose);
        return !isPoseQualified;
    }

    /**
     * This is invoked when _needsExtraInfo() returns before, and the detector actually returned some extra info.
     *
     * @param {any} extraInfo
     * @memberof TreadmillGameEngine
     */
    _handleExtraInfo(extraInfo) {
        const oldDelay = this._avatarCaptureDelay;
        this._avatarCaptureDelay = Math.min(
            kAvatarCaptureMaxDelay,
            Math.floor(this._avatarCaptureDelay * kAvatarCaptureDelayMultiplier)
        );
        this._nextAvatarTimestamp += this._avatarCaptureDelay - oldDelay;
        this._emit({
            avatar: extraInfo.avatar,
        });
    }

    /**
     * Creates a new TreadmillGameEngine that attaches to the given container.
     *
     * @static
     * @returns
     * @memberof TreadmillGameEngine
     */
    static async create({ dispatcher }) {
        // Technically we should use the PoseDetector, but we will skip that for now.
        const detector = await PoseDetector.create();
        await detector.warmup();
        return new TreadmillGameEngine(detector, dispatcher);
    }
}

export default TreadmillGameEngine;
