import '../styles/common.scss';

export default function CopyInviteLinkButton({ onClickHandler, style }) {
    const onClick = (event) => {
        event.stopPropagation();
        if (onClickHandler) {
            return onClickHandler(event);
        }
    };
    return (
        <div className="copy-invite-link-button" style={style} onClick={onClick}>
            <div className="icon link-icon" />
            <div className="text">Copy Invitation Link</div>
        </div>
    );
}
