export const numPoseNodes = 18;
const numPoseLimbs = 17;
const pafSampleNumberForComputingLimbConfidence = 10;
const nodeConfidenceAcceptThreshold = 0.1;
const PAFScoreAcceptThreshold = 0.05;

export const numHeatMapsChannel = numPoseNodes + 1;
export const numPafsChannels = numPoseLimbs * 2;

const limbToPAFIndexes = [
    [12, 13],
    [20, 21],
    [14, 15],
    [16, 17],
    [22, 23],
    [24, 25],
    [0, 1],
    [2, 3],
    [4, 5],
    [6, 7],
    [8, 9],
    [10, 11],
    [28, 29],
    [30, 31],
    [34, 35],
    [32, 33],
    [36, 37],
    [18, 19], // extra, no use
    [26, 27], // extra, no use
];

const limbToNodeIndexes = [
    [1, 2],
    [1, 5],
    [2, 3],
    [3, 4],
    [5, 6],
    [6, 7],
    [1, 8],
    [8, 9],
    [9, 10],
    [1, 11],
    [11, 12],
    [12, 13],
    [1, 0],
    [0, 14],
    [14, 16],
    [0, 15],
    [15, 17],
    [2, 16], // extra, no use
    [5, 17], // extra, no use
];

export const kPoseNodeInvalid = 0;
export const kPoseNodeGuessed = 1;
export const kPoseNodeDetected = 2;

export class PoseNode {
    /**
     *
     * @param {number} x
     * @param {number} y
     * @param {number} confidence
     * @param {number} uniqueId
     * @param {number} validLevel
     */
    constructor(x, y, confidence, uniqueId, validLevel) {
        this.x = x;
        this.y = y;
        this.confidence = confidence;
        this.uniqueId = uniqueId;
        this.validLevel = validLevel;
    }

    /**
     * Returns the distance between this node and other.
     * @param {PoseNode} other
     * @returns {number}
     * @memberof PoseNode
     */
    distTo(other) {
        const dx = other.x - this.x;
        const dy = other.y - this.y;
        return Math.sqrt(dx * dx + dy * dy);
    }

    /**
     * Extracts pose nodes from various heatmaps.
     * @param {cv.Mat} heatMapsMat
     * @returns {Array}
     */
    static extractFromHeatMaps(heatMapsMat) {
        /** @type {PoseNode[][]} */
        const nodeLists = [];
        /** @type {PoseNode[]} */
        const allNodes = [];
        for (let i = 0; i < numPoseNodes; ++i) {
            nodeLists.push([]);
        }
        const numRows = heatMapsMat.rows;
        const numCols = heatMapsMat.cols;
        const numChannels = heatMapsMat.channels();
        const hmYOffset = heatMapsMat.cols * heatMapsMat.channels();
        const hmXOffset = heatMapsMat.channels();
        const xBound = heatMapsMat.cols - 1;
        const yBound = heatMapsMat.rows - 1;
        let data = heatMapsMat.data32F;
        let offset = 0;
        for (let y = 0; y < numRows; ++y) {
            for (let x = 0; x < numCols; ++x) {
                const nextOffset = offset + numChannels;
                for (let c = 0; c < numPoseNodes; ++c, ++offset) {
                    const currConfidence = data[offset];
                    if (
                        currConfidence < nodeConfidenceAcceptThreshold ||
                        (y < yBound && currConfidence < data[offset + hmYOffset]) ||
                        (y > 0 && currConfidence < data[offset - hmYOffset]) ||
                        (x < xBound && currConfidence < data[offset + hmXOffset]) ||
                        (x > 0 && currConfidence < data[offset - hmXOffset])
                    ) {
                        continue;
                    }
                    const node = new PoseNode(x, y, currConfidence, allNodes.length, kPoseNodeDetected);
                    nodeLists[c].push(node);
                    allNodes.push(node);
                }
                offset = nextOffset;
            }
        }
        return [nodeLists, allNodes];
    }
}

class PoseLimbCandidate {
    /**
     *
     * @param {number} nodeIA
     * @param {number} nodeIB
     * @param {number} confidence
     * @param {number} nodeIdA
     * @param {number} nodeIdB
     * @param {number} confA
     * @param {number} confB
     */
    constructor(nodeIA, nodeIB, confidence, nodeIdA, nodeIdB, confA, confB) {
        this.nodeIA = nodeIA;
        this.nodeIB = nodeIB;
        this.confidence = confidence;
        this.nodeIdA = nodeIdA;
        this.nodeIdB = nodeIdB;
        this.confA = confA;
        this.confB = confB;
    }

    /**
     * Extracts limbs from the given PAF map.
     * @param {cv.Mat} pafsMat
     * @param {PoseNode[][]} nodeLists
     * @returns {PoseLimbCandidate[][]}
     */
    static extractFromPAFsAndNodes(pafsMat, nodeLists) {
        /** @type {PoseLimbCandidate[][]} */
        const candidateLists = [];
        const midNum = pafSampleNumberForComputingLimbConfidence - 1;
        const midNumDiv = 1.0 / midNum;
        const minAcceptScoreCount = Math.round(midNum * 0.8);
        const fieldHeight = pafsMat.rows;
        for (let l = 0; l < numPoseLimbs; ++l) {
            const limbXPAFChannel = 2 * l;
            const limbYPAFChannel = 2 * l + 1;
            const nodeIndexA = limbToNodeIndexes[l][0];
            const nodeIndexB = limbToNodeIndexes[l][1];
            const nodeListA = nodeLists[nodeIndexA];
            const nodeListB = nodeLists[nodeIndexB];

            /** @type {PoseLimbCandidate[]} */
            const candidates = [];
            for (let i = 0; i < nodeListA.length; ++i) {
                const nodeA = nodeListA[i];
                const ax = nodeA.x;
                const ay = nodeA.y;
                let bx, by, vx, vy, uvx, uvy, norm, mx, my, svx, svy;
                let score, scoreSum, scoreAvg, scoreWithDistPrior;
                /** @type {TypedArray} */
                let pafPtr;
                let acceptScoreCount = 0;
                for (let j = 0; j < nodeListB.length; ++j) {
                    // For each limb type, try all combination of possible nodes (of correct type),
                    // and see how it aligns with the vectors in PAF map. Score will be computed to
                    // thresholding invalid limbs.

                    const nodeB = nodeListB[j];
                    bx = nodeB.x;
                    by = nodeB.y;
                    vx = bx - ax;
                    vy = by - ay;
                    norm = Math.sqrt(vx * vx + vy * vy);

                    // Failure case when 2 body parts overlaps
                    if (norm === 0) {
                        continue;
                    }

                    // Unit vector
                    uvx = vx / norm;
                    uvy = vy / norm;

                    // Step vector
                    svx = vx * midNumDiv;
                    svy = vy * midNumDiv;

                    // Interpolate vector
                    mx = ax;
                    my = ay;

                    scoreSum = 0;
                    acceptScoreCount = 0;

                    // Sampling the points between two nodes, and check their corresponding
                    // PAF map values, to compute the score.
                    for (let m = 0; m <= midNum; ++m) {
                        pafPtr = pafsMat.floatPtr(Math.round(my), Math.round(mx));
                        score = pafPtr[limbXPAFChannel] * uvx + pafPtr[limbYPAFChannel] * uvy;

                        scoreSum += score;
                        mx += svx;
                        my += svy;

                        if (score > PAFScoreAcceptThreshold) {
                            acceptScoreCount++;
                        }
                    }
                    scoreAvg = scoreSum / (1 + midNum);
                    scoreWithDistPrior = scoreAvg + Math.min(0, (0.5 * fieldHeight) / norm - 1);

                    if (scoreWithDistPrior > 0 && acceptScoreCount > minAcceptScoreCount) {
                        // Good overall score and enough accepted score count.
                        const candidate = new PoseLimbCandidate(
                            i,
                            j,
                            scoreWithDistPrior,
                            nodeA.uniqueId,
                            nodeB.uniqueId,
                            nodeA.confidence,
                            nodeB.confidence
                        );
                        candidates.push(candidate);
                    }
                }
            }
            // Sort and put high-confident limbs first.
            candidates.sort((l1, l2) => {
                return l2.confidence - l1.confidence;
            });

            // Select best limbs by non-max suppression.
            const matchedNodeIndexAs = {};
            const matchedNodeIndexBs = {};
            const selectedLimbCandidates = [];
            for (const candidate of candidates) {
                if (!matchedNodeIndexAs[candidate.nodeIA] && !matchedNodeIndexBs[candidate.nodeIB]) {
                    selectedLimbCandidates.push(candidate);
                    matchedNodeIndexAs[candidate.nodeIA] = true;
                    matchedNodeIndexBs[candidate.nodeIB] = true;
                }
            }

            candidateLists.push(selectedLimbCandidates);
        }

        return candidateLists;
    }
}

// A raw pose is just a list of node indices, together with number of valid nodes and the total confidence.
class RawPose {
    constructor(nodeIndex, nodeId, confidence) {
        const nodeIds = (this.nodeIds = []);
        for (let i = 0; i < numPoseNodes; ++i) {
            nodeIds.push(-1);
        }
        nodeIds[nodeIndex] = nodeId;
        this.count = 1; // Number of valid nodes.
        this.confidence = confidence;
    }

    /**
     * Registers a new node at nodeIndex with ID nodeId.
     * The confidence should include the confidence of the PAF.
     * @param {number} nodeIndex
     * @param {number} nodeId
     * @param {number} confidence
     * @memberof RawPose
     */
    _register(nodeIndex, nodeId, confidence) {
        this.nodeIds[nodeIndex] = nodeId;
        this.confidence += confidence;
        this.count += 1;
    }

    /**
     * Converts a list of limb candidates into raw poses.
     * Each raw pose is an array of node indices, followed by
     * @param {PoseLimbCandidate[][]} limbCandidateLists
     * @returns {RawPose[]}
     */
    static createFromLimbCandidates(limbCandidateLists) {
        /** @type {RawPose[]} */
        const rawPoses = [];
        // MERGE limbs to get people.
        for (let l = 0; l < numPoseLimbs; ++l) {
            const nodeIndexA = limbToNodeIndexes[l][0];
            const nodeIndexB = limbToNodeIndexes[l][1];
            for (const candidate of limbCandidateLists[l]) {
                let existingPose = null;
                for (const currPose of rawPoses) {
                    if (currPose.nodeIds[nodeIndexA] === candidate.nodeIdA) {
                        existingPose = currPose;
                        break;
                    }
                }
                if (existingPose === null) {
                    existingPose = new RawPose(nodeIndexA, candidate.nodeIdA, candidate.confA);
                    rawPoses.push(existingPose);
                }
                existingPose._register(nodeIndexB, candidate.nodeIdB, candidate.confidence + candidate.confB);
            }
        }
        // ERASE bad raw poses.
        return rawPoses.filter((pose) => {
            return pose.count > 4 && pose.confidence > 0.4 * pose.count;
        });
    }
}

const invalidPoseNode = new PoseNode(-1, -1, 0, -1, kPoseNodeInvalid);
const kMinimumValidNodeCount = 4;
const kLimbRangeForPixelPerInchComputation = 13;
const kLimbRefMultiplier = 1;
const limbRefSize = [
    7.5 * kLimbRefMultiplier,
    7.5 * kLimbRefMultiplier,
    12.3 * kLimbRefMultiplier,
    10.2 * kLimbRefMultiplier,
    12.3 * kLimbRefMultiplier,
    10.2 * kLimbRefMultiplier,
    25.3 * kLimbRefMultiplier,
    18.3 * kLimbRefMultiplier,
    17.3 * kLimbRefMultiplier,
    25.3 * kLimbRefMultiplier,
    18.3 * kLimbRefMultiplier,
    17.3 * kLimbRefMultiplier,
    9 * kLimbRefMultiplier,
    2.2 * kLimbRefMultiplier,
    3 * kLimbRefMultiplier,
    2.2 * kLimbRefMultiplier,
    3 * kLimbRefMultiplier,
];

class Box {
    /**
     * Creates an instance of Box.
     * @param {number} x
     * @param {number} y
     * @param {number} w
     * @param {number} h
     * @memberof Box
     */
    constructor(x, y, w, h) {
        this.x = x;
        this.y = y;
        this.w = w;
        this.h = h;
    }

    /**
     * Takes the union of this box and the other.
     *
     * @param {*} other
     * @returns {Box}
     * @memberof Box
     */
    union(other) {
        const minX = Math.min(this.x, other.x);
        const minY = Math.min(this.y, other.y);
        const maxX = Math.max(this.x + this.w, other.x + other.w);
        const maxY = Math.max(this.y + this.h, other.y + other.h);
        return new Box(minX, minY, maxX - minX, maxY - minY);
    }

    /**
     * Gathers some important x and y coordinates for computing bounding boxes.
     *
     * @param {number[]} xs
     * @param {number[]} ys
     * @memberof Box
     */
    gatherCoordinates(xs, ys) {
        xs.push(this.x);
        xs.push(this.x + this.w);
        ys.push(this.y);
        ys.push(this.y + this.h);
    }

    /**
     * Whether this box contains the given point or not.
     *
     * @param {number} x
     * @param {number} y
     * @returns {boolean}
     * @memberof Box
     */
    contains(x, y) {
        return x >= this.x && x < this.x + this.w && y >= this.y && y < this.y + this.h;
    }

    /**
     * Returns a bounding box surrounding all the points.
     *
     * @static
     * @param {Point[]} points
     * @memberof Box
     */
    static getBoundingBoxForPoints(...points) {
        let minX = points[0].x;
        let minY = points[0].y;
        let maxX = minX;
        let maxY = minY;
        for (const point of points) {
            if (point.x < minX) minX = point.x;
            if (point.y < minY) minY = point.y;
            if (point.x > maxX) maxX = point.x;
            if (point.y > maxY) maxY = point.y;
        }
        return new Box(minX, minY, maxX - minX, maxY - minY);
    }

    /**
     * Gets a axis-aligned bounding box for all the coordinates.
     *
     * @static
     * @param {number[]} xs
     * @param {number[]} ys
     * @returns {Box}
     * @memberof Box
     */
    static getBoundingBoxForCoordinates(xs, ys) {
        let minX = Math.min(...xs);
        let maxX = Math.max(...xs);
        let minY = Math.min(...ys);
        let maxY = Math.max(...ys);
        return new Box(minX, minY, maxX - minX, maxY - minY);
    }
}

class Point {
    /**
     * Creates an instance of Pt.
     * @param {number} x
     * @param {number} y
     * @memberof Pt
     */
    constructor(x, y) {
        this.x = x;
        this.y = y;
    }

    /**
     * Returns this + other.
     *
     * @param {Point} other
     * @memberof Point
     */
    add(other) {
        return new Point(this.x + other.x, this.y + other.y);
    }

    /**
     * Returns this - other.
     *
     * @param {Point} other
     * @memberof Point
     */
    sub(other) {
        return new Point(this.x - other.x, this.y - other.y);
    }

    /**
     * Returns the "length" / norm of the point.
     *
     * @returns {number}
     * @memberof Point
     */
    norm() {
        return Math.sqrt(this.x * this.x + this.y * this.y);
    }

    /**
     * Normalizes the vector.
     *
     * @memberof Point
     */
    normalize() {
        const norm = this.norm();
        this.x /= norm;
        this.y /= norm;
    }

    /**
     * Computes the dot product with other.
     *
     * @param {Point} other
     * @returns {number}
     * @memberof Point
     */
    dot(other) {
        return this.x * other.x + this.y * other.y;
    }

    /**
     * Returns a Point that is scaled by factor.
     *
     * @param {number} factor
     * @returns {Point}
     * @memberof Point
     */
    scaled(factor) {
        return new Point(this.x * factor, this.y * factor);
    }

    /**
     * Returns the sum of the given points.
     *
     * @static
     * @param {Point[]} points
     * @returns {Point}
     * @memberof Point
     */
    static sum(...points) {
        let x = 0,
            y = 0;
        for (const point of points) {
            x += point.x;
            y += point.y;
        }
        return new Point(x, y);
    }
}
const nodeIndexesForComputeEstBox = [0, 1, 2, 5, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17];
const nodeRefY = [
    0.08,
    0.19,
    0.19,
    0.33,
    0.42,
    0.19,
    0.33,
    0.42,
    0.5,
    0.72,
    0.93,
    0.5,
    0.72,
    0.93,
    0.06,
    0.06,
    0.07,
    0.07,
];

const bodyTopMarginInInch = 4;
const bodyLeftRightMarginInInch = 4;
const bodyBottomMarginInInch = 5;
const noseToHeadTopInInch = 6;
const noseToHeadBottomInInch = 4;
const headWidthInInch = 8;

const ankleToGroundDisInInch = 5;
const shoesWidthInInch = 6;

const handToForearmRatio = 3;

const extendSizeInInchForExtendedBox = 3;

const limbThicknessForMaskInInch = 5;

export const kNoseNodeIndex = 0;
export const kChestNodeIndex = 1;
export const kRightShoulderNodeIndex = 2;
export const kRightElbowNodeIndex = 3;
export const kRightWristNodeIndex = 4;
export const kLeftShoulderNodeIndex = 5;
export const kLeftElbowNodeIndex = 6;
export const kLeftWristNodeIndex = 7;
export const kRightHipNodeIndex = 8;
export const kRightKneeNodeIndex = 9;
export const kRightAnkleNodeIndex = 10;
export const kLeftHipNodeIndex = 11;
export const kLeftKneeNodeIndex = 12;
export const kLeftAnkleNodeIndex = 13;
export const kRightEyeNodeIndex = 14;
export const kLeftEyeNodeIndex = 15;
export const kRightEarNodeIndex = 16;
export const kLeftEarNodeIndex = 17;

const kNeckLimbIndex = 12;

export class Pose {
    /**
     *
     * @param {PoseNode[]} nodes
     */
    constructor(nodes) {
        /** @type {PoseNode[]} */
        this.nodes = nodes;

        /** @type {Boolean} */
        this.populated = false;
        /** @type {number} */
        this.pixelsPerInch = 0.0;
        /** @type {Box} */
        this.estBox = null;
        /** @type {Box} */
        this.rawBox = null;
        /** @type {Box} */
        this.headBox = null;
        /** @type {Point[]} */
        this.bodyBox = null;
        /** @type {Box} */
        this.leftShoeBox = null;
        /** @type {Box} */
        this.rightShoeBox = null;
        /** @type {Box} */
        this.leftHandBox = null;
        /** @type {Box} */
        this.rightHandBox = null;
        /** @type {Point[]} */
        this.leftForearmLimb = null;
        /** @type {Point[]} */
        this.rightForearmLimb = null;
        /** @type {Box} */
        this.extBox = null;
    }

    populate() {
        this.pixelsPerInch = this._computePixelsPerInch();
        this.rawBox = this._computeRawBox();
        this.estBox = this._computeEstBox();
        this.headBox = this._computeHeadBox();
        this.bodyBox = this._computeBodyBox();

        const [leftShoeBox, rightShoeBox] = this._computeShoeBoxes();
        this.leftShoeBox = leftShoeBox;
        this.rightShoeBox = rightShoeBox;

        const [leftHandBox, leftForearmLimb, rightHandBox, rightForearmLimb] = this._computeHandBoxesAndForearmLimbs();
        this.leftHandBox = leftHandBox;
        this.rightHandBox = rightHandBox;

        this.leftForearmLimb = leftForearmLimb;
        this.rightForearmLimb = rightForearmLimb;

        this.extBox = this._computeExtendedBox();

        this.populated = true;
    }

    /**
     * Returns the pixel length, with respect the actual inches in world.
     *
     * @param {number} lengthInInch
     * @returns {number}
     * @memberof Pose
     */
    _pixelsForLength(lengthInInch) {
        return lengthInInch * this.pixelsPerInch;
    }

    /**
     * Returns the estimated y position of a specific node.
     *
     * @param {number} nodeIndex
     * @return {number}
     * @memberof Pose
     */
    estY(nodeIndex) {
        return this.estBox.y + nodeRefY[nodeIndex] * this.estBox.h;
    }

    /**
     * Fixes the chest node if it is invalid.
     * @return {PoseNode}
     * @memberof Pose
     */
    _getFixedChestNode() {
        const chestNode = this.nodes[kChestNodeIndex];
        if (chestNode.validLevel !== kPoseNodeInvalid) {
            return chestNode;
        }

        chestNode.x = this.rawBox.x + 0.5 * this.rawBox.w;
        chestNode.y = this.estY(kChestNodeIndex);
        chestNode.validLevel = kPoseNodeGuessed;
        return chestNode;
    }

    /**
     * Computes the hip-center of the pose, optionally guessing.
     *
     * @param {*} shouldGuess
     * @returns {Point}
     * @memberof Pose
     */
    computeHipCenter(shouldGuess) {
        const ret = new Point(-1, -1);
        const leftHipNode = this.nodes[kLeftHipNodeIndex];
        const rightHipNode = this.nodes[kRightHipNodeIndex];
        let hipCenterXSum = 0,
            hipCenterYSum = 0,
            count = 0;
        if (leftHipNode.validLevel === kPoseNodeDetected) {
            hipCenterXSum += leftHipNode.x;
            hipCenterYSum += leftHipNode.y;
            ++count;
        }

        if (rightHipNode.validLevel === kPoseNodeDetected) {
            hipCenterXSum += rightHipNode.x;
            hipCenterYSum += rightHipNode.y;
            ++count;
        }

        if (count > 0) {
            ret.x = hipCenterXSum / count;
            ret.y = hipCenterYSum / count;
            return ret;
        } else if (shouldGuess) {
            const chestNode = this._getFixedChestNode();
            ret.x = chestNode.x;
            ret.y = this.estY(kLeftHipNodeIndex);
        }

        return ret;
    }

    _getFixedNoseNode() {
        const noseNode = this.nodes[kNoseNodeIndex];
        if (noseNode.validLevel !== kPoseNodeInvalid) {
            return noseNode;
        }

        const chestNode = this._getFixedChestNode();
        const hipCenter = this.computeHipCenter(false);

        if (hipCenter.x >= 0) {
            // If there is any valid hip center
            const backboneVecX = chestNode.x - hipCenter.x;
            const backboneVecY = chestNode.y - hipCenter.y;
            const backboneLength = Math.sqrt(backboneVecX * backboneVecX + backboneVecY * backboneVecY);
            const neckPixelLength = this._pixelsForLength(limbRefSize[kNeckLimbIndex]);

            noseNode.x = chestNode.x + (backboneVecX / backboneLength) * neckPixelLength;
            noseNode.y = chestNode.y + (backboneVecY / backboneLength) * neckPixelLength;
            noseNode.validLevel = kPoseNodeGuessed;
            return noseNode;
        }

        // Use Chest to guess.
        noseNode.x = chestNode.x;
        noseNode.y = this.estY(kNoseNodeIndex);
        noseNode.validLevel = kPoseNodeGuessed;
        return noseNode;
    }

    /**
     * Computes the pixels per inch.
     * @returns {number}
     * @memberof Pose
     */
    _computePixelsPerInch() {
        const pairs = this._pixelPerInchValuesOfLimbs();

        let sumVal = 0;
        let count = 0;
        // Average [1/4 - 1/2] in the sorted values:
        // - Exclude largest outliers.
        // - Trust large values because it's more like the real length.
        let fromIndex = pairs.length >> 2;
        let toIndex = pairs.length >> 1;
        for (let index = fromIndex; index < toIndex; ++index) {
            sumVal += pairs[index][0];
            ++count;
        }

        return sumVal / (count + 1e-9);
    }

    /**
     * @return {Box}
     * @memberof Pose
     */
    _computeRawBox() {
        let minX = 1e9,
            minY = 1e9,
            maxX = -1e9,
            maxY = -1e9;
        for (const node of this.nodes) {
            if (node.validLevel === kPoseNodeDetected) {
                minX = Math.min(minX, node.x);
                minY = Math.min(minY, node.y);
                maxX = Math.max(maxX, node.x);
                maxY = Math.max(maxY, node.y);
            }
        }
        return new Box(minX, minY, maxX - minX, maxY - minY);
    }

    /**
     * Why EstBox? because we may want to guess the region of the whole body
     * when we only have upper half / lower half body detected
     * @return {Box}
     * @memberof Pose
     */
    _computeEstBox() {
        // We want a linear regression mode.
        // offset + height * ref = actual
        // The height refers to the height of the person on screen.
        // offset refers to the leg location.
        // For a particular pose node, we assume it should be at a ratio with the height.
        // Since we basically have a bunch of reference points and actual ys, we can solve
        // using a least square method.
        // The solution is basically
        // height = (num * sum(ar) - sum(a) sum(r)) / (num * sum(r^2) - sum(r)^2)
        // offset = (sum(a) - sum(r) * height) / num.
        let num = 0;
        let sumActual = 0;
        let sumRef = 0;
        let sumActualRef = 0;
        let sumRefSqr = 0;
        for (const nodeIndex of nodeIndexesForComputeEstBox) {
            const node = this.nodes[nodeIndex];
            if (node.validLevel === kPoseNodeDetected) {
                ++num;
                const actual = node.y;
                const ref = nodeRefY[nodeIndex];
                sumActual += actual;
                sumRef += ref;
                sumActualRef += actual * ref;
                sumRefSqr += ref * ref;
            }
        }
        const estHeight = (num * sumActualRef - sumActual * sumRef) / (num * sumRefSqr - sumRef * sumRef);
        const estMinY = (sumActual - sumRef * estHeight) / num;

        // Final Box.
        const xMargin = this._pixelsForLength(3);
        const x = this.rawBox.x - xMargin;
        let y = estMinY;
        const w = this.rawBox.w + 2 * xMargin;
        let h = estHeight;

        if (h < 0) {
            y += h;
            h = -h;
        }

        // Let's see if this is too big. If it is, we will just not trust this estimation.
        if (this.rawBox.h * 4 < h) {
            // Use the original box.
            return this.rawBox;
        }
        // Otherwise, let's take the union of rawBox and our new Box.
        return this.rawBox.union(new Box(x, y, w, h));
    }

    /**
     * @return {Box}
     * @memberof Pose
     */
    _computeHeadBox() {
        const noseNode = this._getFixedNoseNode();

        // Compute Head Box
        const topMargin = this._pixelsForLength(noseToHeadTopInInch);
        const bottomMargin = this._pixelsForLength(noseToHeadBottomInInch);
        const xMargin = this._pixelsForLength(headWidthInInch * 0.5);

        return new Box(noseNode.x - xMargin, noseNode.y - topMargin, 2 * xMargin, topMargin + bottomMargin);
    }

    /**
     * @return {Point[]}
     * @memberof Pose
     */
    _computeBodyBox() {
        const chestNode = this._getFixedChestNode();
        const leftHipNode = this.nodes[kLeftHipNodeIndex];
        const rightHipNode = this.nodes[kRightHipNodeIndex];
        const hipCenterPoint = this.computeHipCenter(true);
        const chestPoint = new Point(chestNode.x, chestNode.y);
        let leftRefPoint = hipCenterPoint;
        let rightRefPoint = hipCenterPoint;

        if (leftHipNode.validLevel === kPoseNodeDetected) {
            leftRefPoint = new Point(leftHipNode.x, leftHipNode.y);
        }

        if (rightHipNode.validLevel === kPoseNodeDetected) {
            rightRefPoint = new Point(rightHipNode.x, rightHipNode.y);
        }

        const backboneVec = chestPoint.sub(hipCenterPoint);
        backboneVec.normalize(); // normalization.
        const rBackboneVec = new Point(backboneVec.y, -backboneVec.x); // rotate 90 deg, clockwise.
        const leftSize = Math.abs(leftRefPoint.sub(hipCenterPoint).dot(rBackboneVec));
        const rightSize = Math.abs(rightRefPoint.sub(hipCenterPoint).dot(rBackboneVec));
        const topMargin = this._pixelsForLength(bodyTopMarginInInch); // dis from chest node to top bound
        const leftMargin = this._pixelsForLength(bodyLeftRightMarginInInch); // dis from left hip to left bound
        const rightMargin = leftMargin;
        const bottomMargin = this._pixelsForLength(bodyBottomMarginInInch); // dis from hip center to bottom bound
        const leftExpandVec = rBackboneVec.scaled(-(leftSize + leftMargin));
        const rightExpandVec = rBackboneVec.scaled(rightSize + rightMargin);
        const topExpandVec = backboneVec.scaled(topMargin);
        const bottomExpandVec = backboneVec.scaled(-bottomMargin);

        return [
            Point.sum(chestPoint, leftExpandVec, topExpandVec),
            Point.sum(chestPoint, rightExpandVec, topExpandVec),
            Point.sum(hipCenterPoint, rightExpandVec, bottomExpandVec),
            Point.sum(hipCenterPoint, leftExpandVec, bottomExpandVec),
        ];
    }

    /**
     * Returns the shoe box around the ankle point.
     *
     * @param {number} x
     * @param {number} y
     * @returns {Box}
     * @memberof Pose
     */
    _getShoeBoxFromAnklePoint(x, y) {
        const sx = x - (this.pixelsPerInch * shoesWidthInInch) / 2;
        const sy = y;
        const sw = this.pixelsPerInch * shoesWidthInInch;
        const sh = this.pixelsPerInch * ankleToGroundDisInInch;
        return new Box(sx, sy, sw, sh);
    }

    /**
     * Gets a "valid" left ankle to work with.
     *
     * @returns {PoseNode}
     * @memberof Pose
     */
    _getFixedLeftAnkleNode() {
        const leftAnkleNode = this.nodes[kLeftAnkleNodeIndex];
        if (leftAnkleNode.validLevel !== kPoseNodeInvalid) {
            return leftAnkleNode;
        }

        const hipCenter = this.computeHipCenter(true);
        leftAnkleNode.x = hipCenter.x;
        leftAnkleNode.y = this.estY(kLeftAnkleNodeIndex);
        leftAnkleNode.validLevel = kPoseNodeGuessed;
        return leftAnkleNode;
    }

    _getFixedRightAnkleNode() {
        const rightAnkleNode = this.nodes[kRightAnkleNodeIndex];
        if (rightAnkleNode.validLevel !== kPoseNodeInvalid) {
            return rightAnkleNode;
        }

        const hipCenter = this.computeHipCenter(true);
        rightAnkleNode.x = hipCenter.x;
        rightAnkleNode.y = this.estY(kRightAnkleNodeIndex);
        rightAnkleNode.validLevel = kPoseNodeGuessed;
        return rightAnkleNode;
    }

    /**
     * @return {Box[]}
     * @memberof Pose
     */
    _computeShoeBoxes() {
        const leftAnkleNode = this._getFixedLeftAnkleNode();
        const rightAnkleNode = this._getFixedRightAnkleNode();
        const leftShoeBox = this._getShoeBoxFromAnklePoint(leftAnkleNode.x, leftAnkleNode.y);
        const rightShoeBox = this._getShoeBoxFromAnklePoint(rightAnkleNode.x, rightAnkleNode.y);
        return [leftShoeBox, rightShoeBox];
    }

    /**
     * Computes the handbox and forearm line.
     *
     * @static
     * @param {PoseNode} waistNode
     * @param {PoseNode} elbowNode
     * @param {number} pixelsPerInch
     * @returns {Array}
     * @memberof Pose
     */
    static _computeHandBoxAndForearmLine(waistNode, elbowNode, pixelsPerInch) {
        if (waistNode.validLevel !== kPoseNodeInvalid && elbowNode.validLevel !== kPoseNodeInvalid) {
            const handRadius = waistNode.distTo(elbowNode) / handToForearmRatio;
            const handCenterX = interpolateNumbers(elbowNode.x, waistNode.x, 1.0 + 1.0 / handToForearmRatio);
            const handCenterY = interpolateNumbers(elbowNode.y, waistNode.y, 1.0 + 1.0 / handToForearmRatio);

            const handBox = new Box(
                handCenterX - handRadius,
                handCenterY - handRadius,
                handRadius * 2 + 1,
                handRadius * 2 + 1
            );
            const forearmLimb = [new Point(elbowNode.x, elbowNode.y), new Point(waistNode.x, waistNode.y)];

            return [handBox, forearmLimb];
        }

        // Otherwise, just use default values.
        const handBox = new Box(0, 0, 0, 0);
        const forearmLimb = [new Point(0, 0), new Point(0, 0)];

        return [handBox, forearmLimb];
    }
    /**
     * @return {Array}
     * @memberof Pose
     */
    _computeHandBoxesAndForearmLimbs() {
        const leftWaistNode = this.nodes[kLeftWristNodeIndex];
        const leftElbowNode = this.nodes[kLeftElbowNodeIndex];

        const rightWaistNode = this.nodes[kRightWristNodeIndex];
        const rightElbowNode = this.nodes[kRightElbowNodeIndex];

        return Pose._computeHandBoxAndForearmLine(leftWaistNode, leftElbowNode, this.pixelsPerInch).concat(
            Pose._computeHandBoxAndForearmLine(rightWaistNode, rightElbowNode, this.pixelsPerInch)
        );
    }

    /**
     * @return {Box}
     * @memberof Pose
     */
    _computeExtendedBox() {
        /** @type {number[]} */
        const xs = [];
        /** @type {number[]} */
        const ys = [];
        const rawBox = this.rawBox;
        const extSize = this._pixelsForLength(extendSizeInInchForExtendedBox);
        xs.push(rawBox.x - extSize, rawBox.x + rawBox.w + extSize);
        ys.push(rawBox.y - extSize, rawBox.y + rawBox.h + extSize);
        this.headBox.gatherCoordinates(xs, ys);
        this.leftShoeBox.gatherCoordinates(xs, ys);
        this.rightShoeBox.gatherCoordinates(xs, ys);
        for (const point of this.bodyBox) {
            xs.push(point.x);
            ys.push(point.y);
        }
        return Box.getBoundingBoxForCoordinates(xs, ys);
    }

    /**
     * Computes the pixel per inch values of the limbs, and return them in sorted order.
     * Among the returned arrays, the first element is the pixel per inch value, and the second element is the limb index.
     * @returns {number[][]}
     * @memberof Pose
     */
    _pixelPerInchValuesOfLimbs() {
        /** @type {number[][]} */
        const ret = [];
        for (let l = 0; l < kLimbRangeForPixelPerInchComputation; ++l) {
            const nodeIndexA = limbToNodeIndexes[l][0];
            const nodeIndexB = limbToNodeIndexes[l][1];
            const nodeA = this.nodes[nodeIndexA];
            const nodeB = this.nodes[nodeIndexB];
            if (nodeA.validLevel === kPoseNodeDetected && nodeB.validLevel === kPoseNodeDetected) {
                const ref = nodeA.distTo(nodeB) / (limbRefSize[l] + 1e-9);
                ret.push([ref, l]);
            }
        }
        ret.sort((a, b) => {
            return a[0] - b[0];
        });
        return ret;
    }

    /**
     * Computes the (sorted) set of invalid limb indices.
     * @returns {number[]}
     * @memberof Pose
     */
    _computeInvalidLimbs() {
        /** @type {number[]} */
        const ret = [];
        const pixelPerInchValuesOfLimbs = this._pixelPerInchValuesOfLimbs();
        if (pixelPerInchValuesOfLimbs.length > 0) {
            const keyPixelPerInchValue = pixelPerInchValuesOfLimbs[pixelPerInchValuesOfLimbs.length >> 2][0];
            const pixelPerInchValueThreshold = keyPixelPerInchValue * 2;

            for (const pair of pixelPerInchValuesOfLimbs) {
                if (pair[0] > pixelPerInchValueThreshold) {
                    ret.push(pair[1]);
                }
            }
        }
        ret.sort();
        return ret;
    }

    /**
     * Cleans up all invalid limbs, by check if the limbs are of the right proportions.
     *
     * @memberof Pose
     */
    cleanUpInvalidDetectedLimbs() {
        const invalidLimbIndexes = this._computeInvalidLimbs();
        for (const limbIndex of invalidLimbIndexes) {
            const nodeIndexA = limbToNodeIndexes[limbIndex][0];
            const nodeIndexB = limbToNodeIndexes[limbIndex][1];
            if (this.nodes[nodeIndexA].validLevel !== kPoseNodeDetected) {
                // invalid case
                if (this.nodes[nodeIndexB].validLevel === kPoseNodeDetected) {
                    this.nodes[nodeIndexB] = invalidPoseNode;
                }
            }
        }
    }

    /**
     * Counts the number of valid nodes in this pose.
     * @returns {number}
     * @memberof Pose
     */
    validNodeCount() {
        let count = 0;
        for (const node of this.nodes) {
            if (node.validLevel === kPoseNodeDetected) {
                ++count;
            }
        }
        return count;
    }

    /**
     * Whether this pose is valid or not.
     * @returns {Boolean}
     * @memberof Pose
     */
    isValid() {
        return this.validNodeCount() >= kMinimumValidNodeCount;
    }

    /**
     * Constructs a list of pose from raw poses.
     * @param {RawPose[]} rawPoses
     * @param {PoseNode[]} allNodes
     * @returns {Pose[]}
     */
    static createFromRawPosesAndNodes(rawPoses, allNodes) {
        /** @type {Pose[]} */
        const poses = [];
        for (const rawPose of rawPoses) {
            const nodes = [];
            for (const nodeId of rawPose.nodeIds) {
                if (nodeId >= 0) {
                    nodes.push(allNodes[nodeId]);
                } else {
                    nodes.push(invalidPoseNode);
                }
            }
            const pose = new Pose(nodes);
            pose.cleanUpInvalidDetectedLimbs();
            if (pose.isValid()) {
                poses.push(pose);
            }
        }
        return poses;
    }

    /**
     * Decodes a list of poses from the ML results (in the form of cv.Mat).
     *
     * @static
     * @param {cv.Mat} heatMapsMat
     * @param {cv.Mat} pafsMat
     * @param {number} scaleX  The scale in x from the matrix horizontal dimension to the actual input size.
     * @param {number} scaleY The scale in y from the matrix vertical dimension to the actual input size.
     * @param {HTMLCanvasElement} [debugCanvas]  If present, render the detected poses here.
     * @returns {Pose[]}
     * @memberof Pose
     */
    static decodeFromMLResults(heatMapsMat, pafsMat, scaleX, scaleY, debugCanvas) {
        // FIND all nodes.
        const [nodeLists, allNodes] = PoseNode.extractFromHeatMaps(heatMapsMat);
        // FIND all possible limbs.
        const limbCandidateLists = PoseLimbCandidate.extractFromPAFsAndNodes(pafsMat, nodeLists);
        const rawPoses = RawPose.createFromLimbCandidates(limbCandidateLists);
        // RECOVER nodes scales.
        // Scale the nodes to original image scale because we are processing in _processingSize.
        for (const node of allNodes) {
            node.x = Math.round((node.x + 0.5) * scaleX);
            node.y = Math.round((node.y + 0.5) * scaleY);
        }

        // Get FINAL poses.
        const poses = Pose.createFromRawPosesAndNodes(rawPoses, allNodes);

        for (const pose of poses) {
            pose.populate();
        }

        if (debugCanvas) {
            renderDebugFrame(nodeLists, poses, debugCanvas);
        }

        return poses;
    }
}

/**
 * Find a number between number1 and number2, which is of ratio away from number1 (and towards number2).
 *
 * @param {number} number1
 * @param {number} number2
 * @param {number} ratio
 * @returns
 */
function interpolateNumbers(number1, number2, ratio) {
    return number1 * (1 - ratio) + number2 * ratio;
}

const kPoseNodeColors = [
    '#0000FF',
    '#0055FF',
    '#00AAFF',
    '#00FFFF',
    '#00FFAA',
    '#00FF55',
    '#00FF00',
    '#55FF00',
    '#AAFF00',
    '#FFFF00',
    '#FFAA00',
    '#FF5500',
    '#FF0000',
    '#FF0055',
    '#FF00AA',
    '#FF00FF',
    '#AA00FF',
    '#5500FF',
];
/**
 * Draws a list of Pose nodes to the debug context.
 *
 * @param {PoseNode[][]} nodeLists
 * @param {number} nodeSize
 * @param {CanvasRenderingContext2D} debugContext
 */
function drawPoseNodes(nodeLists, nodeSize, debugContext) {
    for (let nodeIndex = 0; nodeIndex < numPoseNodes; ++nodeIndex) {
        debugContext.fillStyle = kPoseNodeColors[nodeIndex];
        for (const node of nodeLists[nodeIndex]) {
            debugContext.beginPath();
            debugContext.arc(node.x, node.y, nodeSize, 0, Math.PI * 2);
            debugContext.fill();
        }
    }
}

/**
 * Draws a pose to the debug context.
 *
 * @param {Pose} pose
 * @param {number} nodeSize
 * @param {number} thickness
 * @param {CanvasRenderingContext2D} debugContext
 */
function drawPose(pose, nodeSize, thickness, debugContext) {
    debugContext.lineWidth = thickness;
    for (let l = 0; l < numPoseLimbs; l++) {
        const nodeIndexA = limbToNodeIndexes[l][0];
        const nodeIndexB = limbToNodeIndexes[l][1];
        const nodeA = pose.nodes[nodeIndexA];
        const nodeB = pose.nodes[nodeIndexB];
        if (nodeA.validLevel === kPoseNodeDetected && nodeB.validLevel === kPoseNodeDetected) {
            const color = kPoseNodeColors[nodeIndexB];
            if (nodeSize > 0) {
                debugContext.fillStyle = kPoseNodeColors[nodeIndexA];
                debugContext.beginPath();
                debugContext.arc(nodeA.x, nodeA.y, nodeSize, 0, Math.PI * 2);
                debugContext.fill();

                debugContext.fillStyle = kPoseNodeColors[nodeIndexB];
                debugContext.beginPath();
                debugContext.arc(nodeB.x, nodeB.y, nodeSize, 0, Math.PI * 2);
                debugContext.fill();
            }
            debugContext.strokeStyle = color;
            debugContext.beginPath();
            debugContext.moveTo(nodeA.x, nodeA.y);
            debugContext.lineTo(nodeB.x, nodeB.y);
            debugContext.stroke();
        }
    }
    debugContext.strokeStyle = '#0C0';
    debugContext.strokeRect(pose.leftHandBox.x, pose.leftHandBox.y, pose.leftHandBox.w, pose.leftHandBox.h);
    debugContext.strokeRect(pose.rightHandBox.x, pose.rightHandBox.y, pose.rightHandBox.w, pose.rightHandBox.h);
    debugContext.strokeRect(pose.headBox.x, pose.headBox.y, pose.headBox.w, pose.headBox.h);
    // debugContext.fillStyle = '#0C0';
    // debugContext.fillRect(pose.leftHandBox.x, pose.leftHandBox.y, pose.leftHandBox.w, pose.leftHandBox.h);
}

/**
 * Draws a list of poses to the debug context.
 *
 * @param {Pose[]} poses
 * @param {number} nodeSize
 * @param {number} thickness
 * @param {CanvasRenderingContext2D} debugContext
 */
function drawPoses(poses, nodeSize, thickness, debugContext) {
    for (const pose of poses) {
        drawPose(pose, nodeSize, thickness, debugContext);
    }
}

/**
 * Renders all the poses on the debugCanvas.
 *
 * @param {PoseNode[]} nodeLists
 * @param {Pose[]} poses
 * @param {HTMLCanvasElement} debugCanvas
 */
function renderDebugFrame(nodeLists, poses, debugCanvas) {
    const debugContext = debugCanvas.getContext('2d');
    debugContext.clearRect(0, 0, debugCanvas.width, debugCanvas.height);

    drawPoseNodes(nodeLists, 2, debugContext);
    drawPoses(poses, 0, 1, debugContext);
}
