import classNames from 'classnames';
import { usePageVisibility } from 'react-page-visibility';
import 'firebase/analytics';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { useCallback, useEffect, useMemo, useReducer, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import * as uuid from 'uuid';
import Button from '../Button';
import GameDriver from '../game/GameDriver';
import Leaderboard from '../Leaderboard';
import MinAspectArea from '../MinAspectArea';
import analyticsManager from '../../analytics/AnalyticsManager';
import {
    getAllTimeStatsRef,
    getSessionRef,
    getThisMonthStatsRef,
    getThisWeekStatsRef,
    getTodayStatsRef,
    JogSession,
    JumpingJackSession,
    JumpRopeSession,
    saveUserStats,
    UserStats,
} from '../../services/session';
import { cloneDeep, isNil, difference, filter } from 'lodash';
// Styling
import './Activity.scss';
import AudioController from '../../audio/AudioController';
import { GoalSetting } from '../Goal';
import TimeRangeFilterType from '../TimeRangeFilterType';
import { PageControllerAction } from '../PageController';
import CelebrationController from '../CelebrationController';
import { getExistData } from '../../services/firebase';
import { GameMetrics } from '../game/GameMetrics';
import JoggingDashboard from '../JoggingDashboard';
import { ActivityId, getAnalyticsName, resolveActivityId } from '../../ActivityId';
import JumpingJackDashboard from '../JumpingJackDashboard';
import JumpRopeDashboard from '../JumpRopeDashboard';

const firestore = firebase.firestore;

const analyticsPropsWithStats = ({ activityId, sessionUserStats, goal, group, isPaused }) => {
    return {
        activity_id: getAnalyticsName({ activityId }),
        group_id: group?.id,
        group_name: group?.name,
        is_pause: isPaused,
        ...sessionUserStats.sessionAnalyticsProps(activityId),
        ...(goal?.analyticsProps() || {}),
    };
};
class SessionController {
    constructor({ activityId }) {
        this.activityId = activityId;
        this.sessionId = uuid.v4();

        this.sessionTime = firestore.Timestamp.now();

        switch (activityId) {
            case ActivityId.TreadMill:
                this.sessionData = new JogSession();
                break;
            case ActivityId.JumpRope:
                this.sessionData = new JumpRopeSession();
                break;
            case ActivityId.JumpingJack:
                this.sessionData = new JumpingJackSession();
                break;
            default:
                this.sessionData = {};
                break;
        }
        this.sessionDataSnapshot = cloneDeep(this.sessionData);

        this.lastUpdateTime = -1; // So that the first update will always go through.

        this.group = null;

        /** @type{firebase.firestore.DocumentReference} */
        this.sessionRef = null;

        this.setUserId(null);

        this.memberStatsListeners = {};
        this.memberStats = {};

        this.currentUserStats = null;
        this.fetchedCurrentUserStats = false;
        this.increasedSessionCount = false;
    }

    setUserId(userId) {
        this._userId = userId;
        if (isNil(userId)) {
            return;
        }
        // Database related.
        this.sessionRef = getSessionRef(userId, this.sessionId);

        // After updating the userId, set set the lastUpdateTime to -1 to force the next update() to save all these entries.
        this.lastUpdateTime = -1;
    }

    setGroup(group) {
        this.group = group;
    }

    update(stats) {
        // Make sure we don't do anything stupid if userId is undefined.
        if (this._userId === null) {
            return;
        }
        const currTime = Date.now();
        const sessionData = this.sessionData;
        if (this.lastUpdateTime < 0) {
            // Lazily create everything, if needed.
            this.sessionRef.set({ startTime: this.sessionTime, ...sessionData });
        }

        sessionData.updateWithEngineStats(stats);

        if (currTime >= this.lastUpdateTime + 5000) {
            // Save every 5s.
            this.sessionRef.update({ ...sessionData });
            this.lastUpdateTime = currTime;

            if (this.fetchedCurrentUserStats) {
                saveUserStats(
                    this._userId,
                    this.sessionDataSnapshot,
                    sessionData,
                    this.currentUserStats,
                    this.increasedSessionCount === false
                );
                this.increasedSessionCount = true;
                this.sessionDataSnapshot = cloneDeep(sessionData);
            } else {
                console.log(`Haven't fetch initial user stats, skip saving saveUserStats`);
            }
        }
    }

    finalize() {
        // Remove listeners
        Object.entries(this.memberStatsListeners).forEach((kv) => {
            const [userId, listener] = kv;
            console.log(`Removing listener for ${userId}`);
            listener();
        });
        if (this._userId === null) {
            return;
        }
        this.sessionRef.update({ ...this.sessionData });
        if (this.fetchedCurrentUserStats) {
            saveUserStats(
                this._userId,
                this.sessionDataSnapshot,
                this.sessionData,
                this.currentUserStats,
                this.increasedSessionCount === false
            );
            this.increasedSessionCount = true;
        }
    }

    updateStatsListenersIfNeeded(groupMemberIds, filterValue, prevFilterValue) {
        console.log(`Setup listeners`);
        const timeFilterChanged = prevFilterValue !== filterValue;
        console.log(
            `prevFilterValue: ${prevFilterValue}, filterValue: ${filterValue}, timeFilterChanged: ${timeFilterChanged}`
        );
        const previousMemberIds = Object.keys(this.memberStatsListeners);
        const memberIds = groupMemberIds;
        const removed = difference(previousMemberIds, timeFilterChanged ? [] : memberIds);
        const added = difference(memberIds, timeFilterChanged ? [] : previousMemberIds);

        console.log(`Removing listeners for ${JSON.stringify(removed, null, 4)}`);
        removed.forEach((memberId) => {
            const listener = this.memberStatsListeners[memberId];
            if (listener) {
                listener();
                delete this.memberStatsListeners[memberId];
            }
            delete this.memberStats[memberId];
        });

        console.log(`Setting up listeners for ${JSON.stringify(added, null, 4)}`);
        added.forEach((memberId) => {
            let statsRef;
            switch (filterValue) {
                case TimeRangeFilterType.TODAY:
                    statsRef = getTodayStatsRef(memberId);
                    break;
                case TimeRangeFilterType.THIS_WEEK:
                    statsRef = getThisWeekStatsRef(memberId);
                    break;
                case TimeRangeFilterType.THIS_MONTH:
                    statsRef = getThisMonthStatsRef(memberId);
                    break;
                default:
                    statsRef = getAllTimeStatsRef(memberId);
                    break;
            }
            this.memberStatsListeners[memberId] = statsRef.onSnapshot((snapshot) => {
                if (isNil(snapshot) || isNil(snapshot.data())) {
                    console.log(`Fail to get ${memberId}'s ${filterValue} stats`);
                    this.memberStats[memberId] = new UserStats();
                    return;
                }
                console.log(`Update ${memberId} ${filterValue} stats: ${JSON.stringify(snapshot.data(), null, 4)}`);
                this.memberStats[memberId] = UserStats.fromJSON(snapshot.data());
            });
        });
        console.log(`Setup listeners complete`);
    }

    getCurrentUserStatsIfNeeded() {
        const userId = this._userId;
        if (isNil(userId)) {
            console.log(`userId undefined, early exit`);
            return;
        }
        if (isNil(this.currentUserStats) === false) {
            console.log(`Started currentUserStats fetch, early exit`);
            return;
        }
        this.currentUserStats = {};
        const pairs = {
            [TimeRangeFilterType.TODAY]: getTodayStatsRef(userId),
            [TimeRangeFilterType.THIS_WEEK]: getThisWeekStatsRef(userId),
            [TimeRangeFilterType.THIS_MONTH]: getThisMonthStatsRef(userId),
            [TimeRangeFilterType.ALL_TIME]: getAllTimeStatsRef(userId),
        };

        const promises = Object.entries(pairs).map((kv) => {
            const [key, ref] = kv;
            console.log(`get ${key} stats for current user: ${userId}`);
            return ref
                .get()
                .then(getExistData)
                .then(
                    (data) => {
                        console.log(`currentUser's ${key} stats: ${JSON.stringify(data, null, 4)}`);
                        this.currentUserStats[key] = UserStats.fromJSON(data);
                    },
                    (error) => {
                        console.log(`Fail to get currentUser's ${key} stats. error: ${error}`);
                        this.currentUserStats[key] = new UserStats();
                    }
                );
        });

        Promise.all(promises).then(() => {
            this.fetchedCurrentUserStats = true;
        });
    }

    get sessionUserStats() {
        if (this.sessionData) {
            return UserStats.fromSession(this.sessionData);
        } else {
            return new UserStats();
        }
    }
}

function computeLeaderboardItems(userEntry, otherEntries, userDB) {
    const entries = otherEntries;

    // First of all, we have to split those that are above and below me.

    // Comparison is first by score, than by userId.
    const shouldGoAbove = (lhs, rhs) => {
        if (lhs.score !== rhs.score) {
            return lhs.score > rhs.score;
        }
        // Otherwise, the newer entries will be above older ones.
        const lhsTime = lhs.lastUpdateTime.getTime();
        const rhsTime = rhs.lastUpdateTime.getTime();
        if (lhsTime !== rhsTime) {
            return lhsTime > rhsTime;
        }
        // Finally, break tie with the uid.
        return lhs.uid < rhs.uid;
    };

    const above = []; // This is sorted in descending order of score.
    const below = []; // This is sorted in ascending order of score.
    for (const entry of entries) {
        if (shouldGoAbove(entry, userEntry)) {
            // Put this in the above list.
            let index = 0;
            while (index < above.length && shouldGoAbove(entry, above[index])) ++index;
            above.splice(index, 0, entry);
        } else {
            // Put this in the below list.
            let index = 0;
            while (index < below.length && shouldGoAbove(below[index], entry)) ++index;
            below.splice(index, 0, entry);
        }
    }

    let numAbove = above.length;
    let numBelow = below.length;

    const makeItem = (entry) => {
        const uid = entry.uid;
        const profile = userDB[uid] || {};
        const pic = profile.avatar || '';
        const name = profile.name || (uid === userEntry.uid ? 'You' : 'Anonymous');
        const score = entry.score;
        return { uid, pic, name, score, lastUpdateTime: entry.lastUpdateTime };
    };

    const items = [];
    for (let i = numAbove; i--; ) items.push(makeItem(above[i]));
    items.push(makeItem(userEntry));
    for (let i = 0; i < numBelow; ++i) {
        items.push(makeItem(below[i]));
    }
    // Now we have to figure out the rank.
    // We need to count the number of people above the first score, and the number of people equal to first score.
    let currScore = items[0].score;
    // Count the number of entries sharing the score as the first score.
    let sameScoreCount = userEntry.score === currScore ? 1 : 0;
    // The scoreRank is currently the rank of the entries with the first score.
    let currScoreRank = 0; // By definition, the user entry cannot be before the first score.
    for (const entry of entries) {
        if (entry.score === currScore) {
            ++sameScoreCount;
        } else if (entry.score > currScore) {
            ++currScoreRank;
        }
    }

    // We are going to assign rank and also keep count.
    // Since we will be counting when we iterate through the items, we want to remove their counts
    // from the loop above, to avoid double counting.
    for (const item of items) {
        if (item.score === currScore) {
            --sameScoreCount;
        }
    }
    for (const item of items) {
        if (item.score === currScore) {
            ++sameScoreCount;
        } else {
            // The score is lower. That means we have to update the rank.
            currScoreRank += sameScoreCount;
            sameScoreCount = 1;
        }
        // Now assign the rank.
        item.rank = currScoreRank;
    }

    return items;
}

var gameMetrics = null;

export default function Activity({
    userId,
    userUpdater,
    userDB,
    settings,
    dependencyReporter,
    group,
    pageController,
    pageControllerState,
}) {
    const params = useParams();
    const activityId = useMemo(() => {
        return resolveActivityId(params);
    }, [params]);

    const history = useHistory();
    const [filterValue, setFilterValue] = useState(TimeRangeFilterType.THIS_WEEK);
    const prevFilterValue = usePrevious(filterValue);

    const sessionController = useMemo(() => {
        if (activityId) {
            return new SessionController({ activityId });
        } else {
            return undefined;
        }
    }, [activityId]);
    const [goal, setGoal] = useState(pageControllerState.presetGoal || GoalSetting.generateFirstAutoGoal(activityId));

    useEffect(() => {
        gameMetrics = new GameMetrics({ name: getAnalyticsName({ activityId }), startTime: Date.now() });
        return () => {
            gameMetrics.gameCompleted();
            // collect game stats when game is completed
            analyticsManager().trackGameStats(gameMetrics.get());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        // Track begin activity once
        group &&
            analyticsManager().trackBeginActivity({
                activity_id: getAnalyticsName({ activityId }),
                group_id: group?.id,
                group_name: group?.name,
                ...(goal?.analyticsProps() || {}),
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        return () => {
            sessionController.finalize();
        };
    }, [sessionController]);
    useEffect(() => {
        sessionController.setUserId(userId);
    }, [userId, sessionController]);
    useEffect(() => {
        group && sessionController.setGroup(group);
    }, [group, sessionController]);

    // These are game states.
    const [cannotSeeYou, setCannotSeeYou] = useState(false);

    const secondaryVideoRef = useRef();

    const statsCompareSame = ({ oldStats, newStats }) => {
        const isEqualNum = (a, b) => {
            return Math.abs(a - b) < 1e-6;
        };
        for (const key of Object.keys(newStats)) {
            if (key === 'currentPace') {
                continue;
            }
            if (oldStats[key] === undefined) {
                // newStats has such a key, but not in oldStats
                return false;
            }
            if (!isEqualNum(oldStats[key], newStats[key])) {
                return false;
            }
        }
        return true;
    };

    const [stats, updateStats] = useReducer(
        (state, change) => {
            const newState = { ...state, ...change };
            return statsCompareSame({ oldStats: state, newStats: newState }) ? state : newState;
        },
        {
            totalTime: 0,
            totalEnergy: 0,
            totalOutput: 0,
            totalStepCount: 0,
            totalDistance: 0,
            // currentCadence: 0,
            currentOutput: 0,
            currentSpeed: 0,
            totalJumpCount: 0,
        }
    );

    // to guarantee that the app is paused in background
    const isVisible = usePageVisibility();
    useEffect(() => {
        setPauseState({ isVisible });
    }, [isVisible]);

    const [pauseState, setPauseState] = useReducer(
        (state, change) => {
            const { isVisible, manualPaused } = change;
            if (isVisible === false) {
                return { isPaused: true, auto: true };
            } else if (manualPaused !== undefined && manualPaused === true) {
                return { isPaused: true };
            } else if (manualPaused !== undefined && manualPaused === false) {
                // can only un-pause using manual button
                return { isPaused: false };
            } else {
                // no change
                return state;
            }
        },
        { isPaused: false }
    );

    // Leaderboard.
    useEffect(() => {
        const groupMemberIds = filter(Object.keys(group?.members || {}), (memberId) => memberId !== userId);
        sessionController?.updateStatsListenersIfNeeded(groupMemberIds, filterValue, prevFilterValue);
    }, [userId, group, filterValue, prevFilterValue, sessionController]);

    const currentUserScore = useMemo(() => {
        return sessionController.sessionData.leaderboardScore;
    }, [sessionController.sessionData.leaderboardScore]);

    // We need to figure out the leaderboardItems that are around the current user.
    const leaderboardItems = useMemo(() => {
        if (isNil(sessionController) || isNil(sessionController.currentUserStats)) {
            return [];
        }
        // Find out entries that are not mine.
        let otherEntries = [];
        for (const [memberId, memberStats] of Object.entries(sessionController.memberStats)) {
            if (memberId === userId) {
                // Skip myself.
                continue;
            }
            otherEntries.push({
                uid: memberId,
                score: memberStats.leaderboardScore(activityId) || 0,
                lastUpdateTime: memberStats.lastUpdateTime,
            });
        }
        const myStats = sessionController.currentUserStats[filterValue];
        return computeLeaderboardItems(
            {
                uid: userId,
                score: (myStats?.leaderboardScore(activityId) || 0) + currentUserScore,
                lastUpdateTime: new Date(),
            },
            otherEntries,
            userDB
        );
    }, [sessionController, userId, userDB, filterValue, currentUserScore, activityId]);

    useEffect(() => {
        sessionController?.getCurrentUserStatsIfNeeded();
    }, [sessionController]);

    const stateDispatch = useCallback(
        (change) => {
            if (change.renderingTime && change.renderingTime > 0) {
                gameMetrics?.append({ fps: 1000 / change.renderingTime });
            }
            if (change.stats !== undefined && settings.perfTuning.enableStatsUpdate) {
                updateStats(change.stats);
                sessionController.update(change.stats);

                if (change.stats.totalStepCount) {
                    gameMetrics?.append({ totalStepCount: change.stats.totalStepCount });
                }
            }
            if (change.avatar !== undefined) {
                userUpdater && userUpdater({ avatar: change.avatar });
            }
            if (change.noDetection !== undefined) {
                gameMetrics?.append({ noDetection: change.noDetection });
                setCannotSeeYou(change.noDetection);
            }
            if (change.dependencies !== undefined) {
                dependencyReporter(change.dependencies);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [updateStats, sessionController, userUpdater, dependencyReporter]
    );

    const goalProgressPercent = useMemo(() => {
        return parseInt(goal.progress(stats) * 100);
    }, [goal, stats]);

    useEffect(() => {
        if (goalProgressPercent >= 100) {
            if (!goal.progress100Tracked) {
                // analytics
                analyticsManager().trackGoalCompleted(
                    analyticsPropsWithStats({
                        activityId,
                        sessionUserStats: sessionController.sessionUserStats,
                        goal,
                        group,
                    })
                );
                goal.progress100Tracked = true;
            }
            // update goal; only auto-goal has the next goal
            goal.isAutoGoal && setGoal(goal.changeGoal(stats));
        }
        // eslint-disable-next-line
    }, [goalProgressPercent]);

    // to guarantee that group is present
    useEffect(() => {
        if (settings.activity.ensureGroupPresence && !group) {
            history.push('/');
        }
        // eslint-disable-next-line
    }, [group, settings.activity.ensureGroupPresence]);

    useEffect(() => {
        if (pauseState.isPaused) {
            setCannotSeeYou(false);
            console.log('[Activity] paused, stop bgm', pauseState);
            pageController(PageControllerAction.STOP_BACKGROUND_MUSIC);
            analyticsManager().trackPauseActivity({
                ...analyticsPropsWithStats({
                    activityId,
                    sessionUserStats: sessionController.sessionUserStats,
                    goal,
                    group,
                }),
                auto_paused: !!pauseState.auto,
            });
            // collect game stats when paused
            gameMetrics.pause();
            analyticsManager().trackGameStats(gameMetrics.get());
        } else {
            console.log('[Activity] un-paused, start bgm');
            pageController(PageControllerAction.START_BACKGROUND_MUSIC(true));
            analyticsManager().trackResumeActivity(
                analyticsPropsWithStats({
                    activityId,
                    sessionUserStats: sessionController.sessionUserStats,
                    goal,
                    group,
                })
            );
            gameMetrics.resume();
        }
        // eslint-disable-next-line
    }, [pauseState]);

    return (
        (!settings.activity.ensureGroupPresence || !!group) && (
            <div>
                <MinAspectArea className="Activity" fullScreen={true}>
                    {/* Full screen elements. */}
                    <div
                        className={classNames('GameLayer', {
                            showDebugFrame: settings.activity.showDebugFrame,
                            showPerformancePanel: settings.activity.showPerformancePanel,
                        })}
                    >
                        {/* The default game driver, which contains the camera preview. */}
                        <GameDriver
                            cannotSeeYou={cannotSeeYou}
                            isPaused={pauseState.isPaused}
                            stateDispatch={stateDispatch}
                            captureVideoRef={secondaryVideoRef}
                            settings={settings}
                            activityId={activityId}
                        />
                    </div>

                    {/* Rest of the UI. */}
                    <div className="UILayer">
                        <div className="UILayerLeftContainer">
                            {group && (
                                <div className="group-metadata">
                                    <div className="group-name row">{group?.name}</div>
                                    <div className="member-count row">Members: {group?.memberCount}</div>
                                </div>
                            )}
                            <div>
                                <div className={classNames('buttonPanel', pauseState.isPaused ? 'paused' : 'running')}>
                                    <Button
                                        className="Button endButton"
                                        onClick={() => {
                                            analyticsManager().trackEndActivity(
                                                analyticsPropsWithStats({
                                                    sessionUserStats: sessionController.sessionUserStats,
                                                    goal,
                                                    group,
                                                    isPaused: pauseState.isPaused,
                                                })
                                            );
                                            pageController(PageControllerAction.REMOVE_PRESET_GOAL);
                                            history.push('/summary');
                                        }}
                                    >
                                        STOP
                                    </Button>
                                    <Button
                                        className="Button resumeButton"
                                        onClick={() => {
                                            setPauseState({ manualPaused: false });
                                        }}
                                    >
                                        RESUME
                                    </Button>
                                    <Button
                                        className="pauseButton HideWhenInactive"
                                        onClick={() => {
                                            setPauseState({ manualPaused: true });
                                        }}
                                    />
                                    <Button
                                        className={classNames('soundButton', {
                                            enabled: pageControllerState.enabledSound,
                                            disabled: !pageControllerState.enabledSound,
                                        })}
                                        onClick={() => {
                                            pageController(PageControllerAction.TOGGLE_SOUND);
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="UILayerLeftBottomContainer">
                                {activityId === ActivityId.TreadMill && (
                                    <JoggingDashboard
                                        stats={stats}
                                        goal={goal}
                                        goalProgressPercent={goalProgressPercent}
                                    />
                                )}
                                {activityId === ActivityId.JumpingJack && (
                                    <JumpRopeDashboard
                                        stats={stats}
                                        goal={goal}
                                        goalProgressPercent={goalProgressPercent}
                                    />
                                )}
                                {activityId === ActivityId.JumpRope && (
                                    <JumpRopeDashboard
                                        stats={stats}
                                        goal={goal}
                                        goalProgressPercent={goalProgressPercent}
                                    />
                                )}
                            </div>
                        </div>
                        <Leaderboard
                            items={leaderboardItems}
                            uid={userId}
                            group={group}
                            filterValue={filterValue}
                            setFilterValue={setFilterValue}
                            videoRef={secondaryVideoRef}
                            cannotSeeYou={cannotSeeYou}
                            isPaused={pauseState.isPaused}
                            activityId={activityId}
                        />
                    </div>

                    <AudioController
                        enabled={pageControllerState.enabledSound}
                        goalProgressPercent={goalProgressPercent}
                        totalStepCount={stats.totalStepCount}
                        totalJumpCount={stats.totalJumpCount}
                        goal={goal}
                        pageController={pageController}
                    />
                    <CelebrationController enabled={true} goalProgressPercent={goalProgressPercent} goal={goal} />
                </MinAspectArea>
            </div>
        )
    );
}

function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}
