import { useEffect, useState, useMemo } from 'react';
import './GoalDashboard.scss';
import ProgressRing from './ProgressRing';

export default function GoalDashboard({ goalProgressPercent, goal }) {
    return (
        <div className="GoalDashboard">
            <div className="TopContainer">
                <div className="your-goal-label">YOUR GOAL</div>
                <div className="goal-value">
                    {goal.presentingAmountString()}&nbsp;<span className="unit-label">{goal.unitString()}</span>
                </div>
            </div>
            <div className="BottomContainer">
                <ProgressRing progress={goalProgressPercent / 100} />
                <div className="RingLabelsContainer">
                    <div className="percentage-label">{`${goalProgressPercent}%`}</div>
                    <div className="completed-label">COMPLETED</div>
                </div>
            </div>
        </div>
    );
}
