import { useEffect, useRef } from 'react';
import { usePerformanceTracker, PerformanceTracker } from './PerformanceTracker';
import './GameDriver.scss';
import classNames from 'classnames';

import CaptureConfig from '../../CaptureConfig';
import GameEngine from '../../engine/GameEngine';

/**
 *
 * @param {MediaStream} stream
 */
function closeVideoStream(stream) {
    for (const track of stream.getTracks()) {
        track.stop();
    }
}

// This is the driver class that actually does all the logic.
class Driver {
    /**
     *
     * @param {HTMLDivElement} container
     * @param {HTMLVideoElement} video
     * @param {HTMLVideoElement} primaryVideo null-able
     * @param {HTMLVideoElement} secondaryVideo null-able
     * @param {HTMLCanvasElement} debugCanvas
     * @param {function} performanceReporter
     */
    constructor(container, video, primaryVideo, secondaryVideo, debugCanvas, performanceReporter) {
        this._stopped = false; // Whether this engine has already stopped.
        this._container = container;
        this._video = video;
        this._primaryVideo = primaryVideo;
        this._secondaryVideo = secondaryVideo;
        this._debugCanvas = debugCanvas;
        this._performanceReporter = performanceReporter;
        /** @type {GameEngine} */
        this._engine = null;
        this._currOrientation = 'landscape'; // This is the orientation currently in the container.
        this._paused = false;

        /** @type {MediaStream} */
        this._stream = null;
    }

    async start(stateDispatch, gameConfig) {
        stateDispatch({ cameraReady: false });
        stateDispatch({ dependencies: { gameEngine: true } });
        const config = gameConfig;
        if (this._currOrientation == null || this._currOrientation !== config.orientation) {
            this._canvasContainer.classList.replace(this._currOrientation, config.orientation);
            this._currOrientation = config.orientation;
        }
        const stream = await navigator.mediaDevices.getUserMedia({
            audio: false,
            video: {
                width: config.width,
                height: config.height,
                facingMode: 'user',
            },
        });
        stateDispatch({ cameraReady: !!stream });
        if (this._stopped) {
            closeVideoStream(stream);
            return;
        }
        this._stream = stream;

        [this._video, this._primaryVideo, this._secondaryVideo].forEach(async (vdo) => {
            if (vdo) {
                vdo.srcObject = stream;
                vdo.width = config.width;
                vdo.height = config.height;
                const dataPromise = new Promise((resolve, _) => {
                    vdo.onloadeddata = () => {
                        vdo.onloadeddata = null;
                        resolve();
                    };
                });
                vdo.play();
                await dataPromise;
            }
        });

        this._debugCanvas.width = config.width;
        this._debugCanvas.height = config.height;
        const engine = await config.createEngine({ container: this._container, dispatcher: stateDispatch });
        this._currentConfig = config;

        if (this._stopped) {
            return;
        }
        this._engine = engine;
        stateDispatch({ dependencies: { gameEngine: false } });
        this._engine.start(this._video, this._debugCanvas, this._performanceReporter);
        if (this._paused) {
            this._engine.pause();
        }
    }

    pause() {
        if (!this._paused && this._engine) {
            this._paused = true;
            this._engine.pause();
            this._video.pause();
        }
    }

    resume() {
        if (this._paused && this._engine) {
            this._paused = false;
            this._engine.resume();
            this._video.play();
        }
    }

    pauseMainVideo() {
        this._primaryVideo?.pause();
    }

    resumeMainVideo() {
        this._primaryVideo?.play();
    }

    pauseSecondaryVideo() {
        this._secondaryVideo?.pause();
    }

    resumeSecondaryVideo() {
        this._secondaryVideo?.play();
    }

    async stop() {
        this._stopped = true;
        if (this._engine) {
            this._video.pause(); // Pause the video, and reset it.
            this._video.srcObject = null;
            const engine = this._engine;
            this._engine = null;
            await engine.stop();
        }
        if (this._stream) {
            closeVideoStream(this._stream);
            this._stream = null;
        }
    }
}

export default function GameDriver({
    cannotSeeYou,
    isPaused,
    stateDispatch,
    viewMode = 'landscape',
    settings,
    secondaryVideoRef = null,
    captureVideoRef = null,
    activityId,
}) {
    const gameConfig = CaptureConfig.allConfigs[activityId];
    const containerRef = useRef();
    const videoRef = useRef();
    const primaryVideoRef = useRef();
    const canvasRef = useRef();
    const [performanceTrackerPacket, performanceTrackerDispatch] = usePerformanceTracker();

    useEffect(() => {
        const { renderingTime } = performanceTrackerPacket;
        stateDispatch({ renderingTime });
    }, [performanceTrackerPacket, stateDispatch]);

    const driverRef = useRef(null);

    useEffect(() => {
        console.warn(`[GameDriver] first run`);
        return () => {
            console.warn(`[GameDriver] cleanup`);
            driverRef.current?.stop();
        };
    }, []);

    // controlling game driver
    useEffect(() => {
        if (isPaused) {
            driverRef.current?.pause();
        } else {
            driverRef.current?.resume();
        }
    }, [isPaused]);

    // controlling primaryVideo
    useEffect(() => {
        if (cannotSeeYou || isPaused) {
            driverRef.current?.pauseMainVideo();
        } else {
            driverRef.current?.resumeMainVideo();
        }
    }, [cannotSeeYou, isPaused]);

    useEffect(() => {
        if (isPaused) {
            driverRef.current?.pauseSecondaryVideo();
        } else {
            driverRef.current?.resumeSecondaryVideo();
        }
    }, [isPaused]);

    useEffect(() => {
        const driver = new Driver(
            containerRef.current,
            captureVideoRef?.current || videoRef?.current,
            primaryVideoRef?.current,
            secondaryVideoRef?.current,
            canvasRef.current,
            performanceTrackerDispatch
        );
        driver.start(stateDispatch, gameConfig);
        driverRef.current = driver;
        return () => {
            console.warn(`[GameDriver] calling stop`);
            driver.stop();
        };
        // eslint-disable-next-line
    }, [stateDispatch, performanceTrackerDispatch, secondaryVideoRef, captureVideoRef]);

    return (
        <div
            className={classNames('driver-container', `${viewMode}`, {
                blurBg: viewMode === 'landscape',
            })}
            ref={containerRef}
        >
            {viewMode === 'landscape' && !captureVideoRef && (
                <div>
                    <video ref={videoRef} playsInline={true}></video>
                </div>
            )}
            {viewMode === 'preview' && <video ref={videoRef} playsInline={true}></video>}
            <canvas ref={canvasRef} className="debug"></canvas>
            <PerformanceTracker packet={performanceTrackerPacket}></PerformanceTracker>
        </div>
    );
}
