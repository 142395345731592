import { useEffect, useState, useCallback, useMemo, useReducer } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import classNames from 'classnames';
import './SetupPage.scss';
import MinAspectArea from '../../components/MinAspectArea';
import GameDriver from '../game/GameDriver';
import useSound from 'use-sound';
import countdownSFX from '../../audio/Countdown.aac';
import stepSfx from '../../audio/jog-count-002.wav';
import { ActivityId, resolveActivityId } from '../../ActivityId';

const CANNOT_SEE_YOU_METRICS_MAX = 30;

function SetUpArea({ cameraReady, cannotSeeYou, countdownState, activityId }) {
    const message = () => {
        if (!cameraReady) {
            return 'Please allow us to use your camera, so we can track your run.';
        } else if (cannotSeeYou) {
            return 'Great, now keep your body in view of the camera.';
        } else {
            switch (activityId) {
                case ActivityId.JumpRope:
                case ActivityId.JumpingJack:
                    return 'Perfect, it’s time to jump now!';
                default:
                    return 'Perfect, it’s time to jog now!';
            }
        }
    };

    const camera = !cameraReady;
    const hidden = countdownState.status === 'START_COUNTING';
    const inProgress = !hidden && cameraReady && cannotSeeYou;
    const completed = !hidden && cameraReady && !cannotSeeYou;

    return (
        <div
            className={classNames('setup-area', {
                camera,
                inProgress,
                completed,
                hidden,
            })}
        >
            <div className={'icon ' + activityId} />
            <div className="text">
                <span>{message()}</span>
            </div>
        </div>
    );
}

function CameraPreview({ stateDispatch, cameraReady, cannotSeeYou, activityId }) {
    return (
        <div
            className={classNames('CameraPreview', {
                goodDetection: !cannotSeeYou,
                cameraNotReady: !cameraReady,
            })}
        >
            <div className="AspectFill">
                {/* Game driver which contains the camera preview. */}
                <GameDriver isPaused={false} stateDispatch={stateDispatch} viewMode="preview" activityId={activityId} />
            </div>
        </div>
    );
}

function CountdownArea({ countdownState, msg }) {
    return (
        <div className={classNames('countdown-area', { hidden: !msg || countdownState.status !== 'START_COUNTING' })}>
            <div className="text">{msg}</div>
        </div>
    );
}

export default function SetupPage({ group, pageControllerState, settings }) {
    const params = useParams();
    const activityId = useMemo(() => {
        return resolveActivityId(params);
    }, [params]);
    const history = useHistory();
    const [cameraReady, setCameraReady] = useState(null);
    const [playCountdownSFX, { stop: stopCountdownSFX, isPlaying: isPlayingCountdownSFX }] = useSound(countdownSFX);
    const [playStepSfx] = useSound(stepSfx, { html5: true });
    const useMotionCountdown = useMemo(() => {
        return settings.activity.useMotionCountdown;
    }, [settings.activity.useMotionCountdown]);

    const cleanup = () => {
        isPlayingCountdownSFX && stopCountdownSFX();
    };

    useEffect(() => {
        // cleanup
        return cleanup;
        // eslint-disable-next-line
    }, []);

    // "Cannot see you" determination
    const [cannotSeeYou, setCannotSeeYou] = useState(false);

    // collect camera stats
    const [stats, updateStats] = useReducer(
        (state, change) => {
            return { ...state, ...change };
        },
        {
            totalStepCount: 0,
            totalJumpCount: 0,
            totalTime: 0,
        }
    );

    const stateDispatch = useCallback(
        (change) => {
            if (change.stats !== undefined) {
                updateStats(change.stats);
            }
            if (change.noDetection !== undefined) {
                if (change.noDetection) {
                    setCannotSeeYou(true);
                } else {
                    setCannotSeeYou(false);
                }
            }
            if (change.cameraReady !== undefined) {
                setCameraReady(change.cameraReady);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [updateStats]
    );

    // countdown state
    const [countdownState, updateCountdown] = useReducer(
        (state, change) => {
            return { ...state, ...change };
        },
        {
            status: 'START_PENDING',
        }
    );

    useEffect(() => {
        if (cameraReady && !cannotSeeYou && countdownState.status === 'START_PENDING') {
            updateCountdown({ status: 'START_COUNTING_PENDING', startPendingAt: stats.totalTime });
        }
    }, [cameraReady, cannotSeeYou, countdownState, updateCountdown, stats.totalTime]);

    useEffect(() => {
        if (
            cameraReady &&
            !cannotSeeYou &&
            countdownState.status === 'START_COUNTING_PENDING' &&
            Math.abs(countdownState.startPendingAt || 0 - stats.totalTime) >= 1 // wait at most for 1 second
        ) {
            updateCountdown({
                status: 'START_COUNTING',
                startCountingTime: stats.totalTime,
                startSteps: stats.totalStepCount,
                startJumps: stats.totalJumpCount,
            });
        }
    }, [cameraReady, cannotSeeYou, countdownState, updateCountdown, stats]);

    // count down to start
    const [elapsedCountingTime, timeCountdownString] = useMemo(() => {
        const msgArray = ['3', '2', '1', 'GO!'];
        const val = stats.totalTime - (countdownState.startCountingTime || 0);
        return [val, msgArray[val]];
    }, [stats.totalTime, countdownState.startCountingTime]);

    // treadmill: jog to start, or
    // jumping jack: jump to start
    const [motionRecorded, motionCountdownString] = useMemo(() => {
        switch (activityId) {
            case ActivityId.TreadMill: {
                // dependency: stats.totalStepCount, countdownState.startSteps
                const msgArray = ['Jog to Start!', '5', '4', '3', '2', '1', 'GO!'];
                const val = stats.totalStepCount - (countdownState.startSteps || 0);
                return [val, msgArray[val]];
            }
            case ActivityId.JumpingJack:
            case ActivityId.JumpRope: {
                // dependency: stats.totalJumpCount, countdownState.startJumps
                const msgArray = ['Jump to Start!', '5', '4', '3', '2', '1', 'GO!'];
                const val = stats.totalJumpCount - (countdownState.startJumps || 0);
                return [val, msgArray[val]];
            }
            default:
                return [];
        }
    }, [stats.totalStepCount, countdownState.startSteps, stats.totalJumpCount, countdownState.startJumps, activityId]);

    useEffect(() => {
        if (countdownState.status === 'START_COUNTING') {
            const enoughMotion = useMotionCountdown && motionRecorded >= 7;
            const enoughTime = !useMotionCountdown && elapsedCountingTime >= 4;
            if (enoughMotion || enoughTime) {
                updateCountdown({ status: 'STOP_COUNTING' });
                history.push(`/activity/${activityId}`);
            }
        }
        // eslint-disable-next-line
    }, [elapsedCountingTime, motionRecorded, countdownState, updateCountdown]);

    useEffect(() => {
        pageControllerState.enabledSound && useMotionCountdown && playStepSfx();
        // eslint-disable-next-line
    }, [motionRecorded]);

    useEffect(() => {
        elapsedCountingTime === 0 && pageControllerState.enabledSound && !useMotionCountdown && playCountdownSFX();
        // eslint-disable-next-line
    }, [elapsedCountingTime]);

    // to guarantee that group is present
    useEffect(() => {
        group || history.push('/');
        // eslint-disable-next-line
    }, [group]);

    return (
        group && (
            <div className="SetupPage">
                <MinAspectArea fullScreen={true} defaultBg={true}>
                    <div className="background blurMask" />

                    {/* setup area */}
                    <SetUpArea
                        cameraReady={cameraReady}
                        cannotSeeYou={cannotSeeYou}
                        countdownState={countdownState}
                        activityId={activityId}
                    />

                    {/* camera-preview area */}
                    <CameraPreview
                        stateDispatch={stateDispatch}
                        cameraReady={cameraReady}
                        cannotSeeYou={cannotSeeYou}
                        countdownState={countdownState}
                        activityId={activityId}
                    />

                    {/* countdown area */}
                    <CountdownArea
                        countdownState={countdownState}
                        msg={useMotionCountdown ? motionCountdownString : timeCountdownString}
                    />
                </MinAspectArea>
            </div>
        )
    );
}
