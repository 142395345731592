import { db, getData, getExistData } from './firebase';
import { getUserRef } from './user';
import * as uuid from 'uuid';
import firebase from 'firebase/app';
import { isNil, compact, mapValues } from 'lodash';

export const GroupRole = {
    ADMIN: 'admin',
    MEMBER: 'member',
};

const GroupServiceError = {
    USER_JOINED: new Error('User joined the group'),
    USER_NOT_IN_GROUP: new Error('User is not in the group'),
};

export class Group {
    constructor(id, name, memberCount, members) {
        this.id = id;
        this.name = name;
        this.memberCount = memberCount;
        this.members = members;
    }

    static fromJSON(json) {
        return new Group(json.id, json.name, json.memberCount, json.members);
    }

    toJSON() {
        const { id, name, memberCount } = this;
        const members = mapValues(this.members, (x) => x.toJSON());
        return { id, name, memberCount, members };
    }
}

class GroupMemberInfo {
    constructor(userId, joinDate, role) {
        this.userId = userId;
        this.joinDate = joinDate;
        this.role = role;
    }

    toJSON() {
        const { userId, joinDate, role } = this;
        return { userId, joinDate, role };
    }
}

class UserGroupInfo {
    constructor(groupId, joinDate, role) {
        this.groupId = groupId;
        this.joinDate = joinDate;
        this.role = role;
    }

    toJSON() {
        const { groupId, joinDate, role } = this;
        return { groupId, joinDate, role };
    }
}

export function getGroupRef(groupId) {
    return db.collection('groups').doc(groupId);
}

export async function createGroupAsync(creatorId, groupName) {
    const batch = db.batch();

    const now = firebase.firestore.Timestamp.fromDate(new Date());
    const memberInfo = new GroupMemberInfo(creatorId, now, GroupRole.ADMIN);
    const group = new Group(uuid.v4(), groupName, 1, { [memberInfo.userId]: memberInfo });
    const groupRef = getGroupRef(group.id);
    batch.set(groupRef, group.toJSON());

    const userRef = getUserRef(creatorId);
    const groupInfo = new UserGroupInfo(group.id, memberInfo.joinDate, memberInfo.role);
    const userUpdate = {
        [`groups.${group.id}`]: groupInfo.toJSON(),
    };
    batch.update(userRef, userUpdate);
    await batch.commit();
    return group;
}

export async function joinGroupAsync(userId, groupId) {
    const user = await getUserRef(userId).get().then(getExistData);
    if (!isNil(user.groups) && !isNil(user.groups[groupId])) {
        throw GroupServiceError.USER_JOINED;
    }
    const batch = db.batch();
    const now = firebase.firestore.Timestamp.fromDate(new Date());
    const groupInfo = new UserGroupInfo(groupId, now, GroupRole.MEMBER);
    batch.update(getUserRef(userId), { [`groups.${groupId}`]: groupInfo.toJSON() });
    const memberInfo = new GroupMemberInfo(userId, groupInfo.joinDate, groupInfo.role);
    batch.update(getGroupRef(groupId), {
        [`members.${userId}`]: memberInfo.toJSON(),
        memberCount: firebase.firestore.FieldValue.increment(1),
    });
    await batch.commit();
}

export async function leaveGroupAsync(userId, groupId) {
    const user = await getUserRef(userId).get().then(getExistData);
    if (isNil(user.groups[groupId])) {
        throw GroupServiceError.USER_NOT_IN_GROUP;
    }
    const batch = db.batch();
    batch.update(getUserRef(userId), { [`groups.${groupId}`]: firebase.firestore.FieldValue.delete() });
    batch.update(getGroupRef(groupId), {
        [`members.${userId}`]: firebase.firestore.FieldValue.delete(),
        memberCount: firebase.firestore.FieldValue.increment(-1),
    });
    await batch.commit();
}

export async function getGroupsAsync(groupIds) {
    const promises = groupIds.map((groupId) => getGroupRef(groupId).get().then(getData));
    const groups = compact(await Promise.all(promises));
    return groups;
}
